import { useState, useEffect } from "react";

const SIZES = {
  desktop: 1161,
  tablets: 719,
  phone: 540,
};

export const debounce = (callback, ms = 100) => {
  let timer;

  return () => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      timer = undefined;
      callback();
    }, ms);
  };
};

export const useWindowSize = () => {
  const hasWindow = typeof window !== "undefined";
  const [windowSize, setWindowSize] = useState({
    width: hasWindow ? window.innerWidth : 0,
    height: hasWindow ? window.innerHeight : 0,
  });
  const isMobile = windowSize.width <= SIZES.phone;
  const isTablet = windowSize.width <= SIZES.tablets;
  const isDesktop = windowSize.width < SIZES.desktop;

  useEffect(() => {
    const type = "resize";
    const listener = debounce(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    });
    window.addEventListener(type, listener);
    return () => window.removeEventListener(type, listener);
  }, [hasWindow]);

  return { ...windowSize, isMobile, isTablet, isDesktop };
};
