import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { Box } from "@mui/material";
import { colors } from "../../../styles/colors";
import { ContinuousText, H3 } from "../../../styles/Text.styles";
import { deviceSizes } from "../../../styles/deviceSizes";
import CloseSvg from "../../../public/closeIcon.svg";

export const StyledBox = styledMui(Box)(({ theme }) => ({
  position: "absolute",
  display: "flex",

  justifyContent: "center",
  alignItems: "center",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  maxWidth: "1160px",
  background: colors.leistungenGrau,
  border: "none",
  padding: 0,
  [theme.breakpoints.between("719", "1161")]: {
    height: "calc(100% - 260px)",
    width: "calc(((100% - 270px) / 12) * 10 + 180px)",
  },
  [theme.breakpoints.up("1161")]: {
    width: "calc(((100% - 270px) / 12) * 10 + 180px)",
    height: "calc(100% - 180px)",
  },
}));

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  & > img {
    max-width: 80px;
    margin-bottom: 30px;
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    padding: 0px calc(((100% - 180px) / 10) + 20px);
    & > img {
      max-width: 100px;
      margin-bottom: 45px;
    }
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    padding: 0px calc(((100% - 180px) / 10) + 20px);
    & > img {
      max-width: 120px;
      margin-bottom: 65px;
    }
  }
`;

export const CloseIcon = styled(CloseSvg)`
  position: fixed;
  width: 15px;
  top: 16px;
  right: 17px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    width: 25px;
    cursor: pointer;
    top: 24px;
    right: 32px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    width: 39px;
    top: 53px;
    right: 53px;
  }
`;

export const HeadlineWrapper = styled(H3)`
  & > h1,
  h2,
  h3 {
    font-weight: 400;
  }
  & > h2 {
    font-size: 22px;
    line-height: 28px;
    margin-block: 0;
  }
  text-align: center;
  margin-bottom: 15px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    margin-bottom: 25px;
    & > h2 {
      font-size: 30px;
      line-height: 36px;
    }
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-bottom: 15px;
    & > h2 {
      font-size: 36px;
      line-height: 48px;
    }
  }
`;

export const TextWrapper = styled(ContinuousText)`
  text-align: center;
  margin-bottom: 30px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    margin-bottom: 35px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-bottom: 45px;
  }
`;

export const ButtonWrapper = styled.div`
  max-width: 250px;
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    max-width: 310px;
  }
`;
