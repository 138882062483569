import { IconWrapper } from "./BoxIcon.styles";
import { ContinuousText, H2 } from "../../../../../../styles/Text.styles";

export const BoxIcon = ({ color, bigHeight = false, link }) => {
  return (
    <IconWrapper bigHeight={bigHeight} color={color}>
      <img src={link} />
    </IconWrapper>
  );
};
