import {
  NumbersWrapper,
  DesktopWrapper,
  StyledSwiper,
  FlexGrid,
  StyledImage,
  MobileTextWrapper,
  StyledH3,
  StyledText,
  DesktopTextWrapper,
  NumbersItemWrapper,
  Number,
  VideoWrapper,
} from "./BeforeAndAfter.styles";

import { ContinuousText, H3 } from "../../../../styles/Text.styles";
import { SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
import { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { animateCounters, getYouTubeID } from "../../../../helpers";
import "swiper/css";
import "swiper/css/pagination";
import { Video } from "../../../common/Video/Video.component";

export const BeforeAndAfter = ({ image, media, headline, text, mirrored }) => {
  return (
    <>
      <DesktopWrapper>
        <DesktopTextWrapper mirrored={mirrored}>
          <H3>{headline}</H3>
          <ContinuousText>{text}</ContinuousText>
        </DesktopTextWrapper>
        {image && media.length === 0 && (
          <StyledImage
            mirrored={mirrored}
            src={`${process.env.REACT_APP_IMG_MEDIA_SRC}${image.url}`}
            alt={image.alt}
          />
        )}
        {!!media.length &&
          media.map((item, index) => {
            switch (item.__typename) {
              case "Case_study_Casestudiescf_Modules_BeforeAndAfter_MediaFlex_Image":
                return (
                  <StyledImage
                    key={index}
                    src={process.env.REACT_APP_IMG_MEDIA_SRC + item.url}
                    alt={item.alt}
                    mirrored={mirrored}
                  />
                );
              case "Case_study_Casestudiescf_Modules_BeforeAndAfter_MediaFlex_Video":
                return (
                  <VideoWrapper key={index} mirrored={mirrored}>
                    <Video
                      src={item.url}
                      loop={item.options.loop}
                      autoPlay={item.options.autoplay}
                      externalSource={item.options.externalSource}
                    />
                  </VideoWrapper>
                );
              case "Case_study_Casestudiescf_Modules_BeforeAndAfter_MediaFlex_YoutubeVideo":
                return (
                  <>
                    {item.url && getYouTubeID(item.url) && (
                      <VideoWrapper isEmbedded key={index} mirrored={mirrored}>
                        <Video isEmbedded src={item.url} />
                      </VideoWrapper>
                    )}
                  </>
                );
              default:
                return <></>;
            }
          })}
      </DesktopWrapper>
    </>
  );
};
