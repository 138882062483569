import { ContinuousText } from "../../../../styles/Text.styles";
import { useState } from "react";
import {
  SubHeadline,
  Headline,
  ContactSection,
  FlexibleGrid,
  KeyPointContainer,
  ShortDescription,
  MainWrapper,
  ButtonWrapper,
  TextContainer,
  LockedContent,
} from "./Strategy.styles";
import { KeyPoint } from "./KeyPoint/KeyPoint.component";
import { BasicButton } from "../../../common/BasicButton/BasicButton.component";
import { removeParagraphs } from "../../../../helpers";
import { BoxIcon } from "./Icons/BoxIcon/BoxIcon.component";
import { TextBox } from "./Icons/TextBox/TextBox.component";
import { colors } from "../../../../styles/colors";

export const Strategy = ({
  unlocked,
  keypointsFlex,
  subHeadline,
  headline,
  contactText,
  buttonText,
  descriptionPoints,
  lockedContent,
}) => {
  const [open, setOpen] = useState(null);
  return (
    <MainWrapper>
      <SubHeadline>{subHeadline}</SubHeadline>
      <Headline
        dangerouslySetInnerHTML={{
          __html: headline,
        }}
      />
      <ContactSection>
        <ContinuousText
          dangerouslySetInnerHTML={{
            __html: contactText,
          }}
        />
        <ButtonWrapper>
          <BasicButton
            label={buttonText}
            href={unlocked ? "/contact" : "#contact"}
            urlType="Internal"
          />
        </ButtonWrapper>
      </ContactSection>
      <FlexibleGrid>
        <ShortDescription>
          {descriptionPoints.map((item, index) => {
            switch (item.__typename) {
              case "Case_study_Casestudiescf_Modules_Strategy_DescriptionPointsFlex_Text":
                return (
                  <TextContainer
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html: item.content,
                    }}
                  />
                );
              case "Case_study_Casestudiescf_Modules_Strategy_DescriptionPointsFlex_TextBox":
                return (
                  <TextBox
                    key={index}
                    headline={item.headline}
                    text={item.text}
                    light={item.light}
                  />
                );
              case "Case_study_Casestudiescf_Modules_Strategy_DescriptionPointsFlex_Icon":
                switch (item.type) {
                  case "Easy":
                    return (
                      <BoxIcon
                        color={colors.textBoxBeige}
                        key={index}
                        link="/easy.svg"
                      />
                    );
                  case "Safe":
                    return (
                      <BoxIcon
                        color={colors.textBoxBeige}
                        key={index}
                        link="/safe.svg"
                      />
                    );
                  case "UserFlow":
                    return (
                      <BoxIcon
                        color={colors.textBoxBeige}
                        key={index}
                        link="/userFlow.svg"
                      />
                    );
                  case "Performance":
                    return (
                      <BoxIcon
                        color={colors.textBoxBeige}
                        key={index}
                        link="/performance.svg"
                      />
                    );
                  case "CrossPlatform":
                    return (
                      <BoxIcon
                        key={index}
                        color={colors.textBoxBeige}
                        link="/crossPlatform.svg"
                        bigHeight
                      />
                    );
                  case "NativeProgramming":
                    return (
                      <BoxIcon
                        color={colors.textBoxBeige}
                        key={index}
                        link="/nativeProgramming.svg"
                      />
                    );
                  case "Dsgvo":
                    return <BoxIcon key={index} link="/dsgvo.svg" />;
                  case "AppOnly":
                    return <BoxIcon key={index} link="/appOnly.svg" />;
                  case "Security":
                    return <BoxIcon key={index} link="/sicherheit.svg" />;
                  default:
                    return <></>;
                }
            }
          })}
        </ShortDescription>
        {unlocked && (
          <LockedContent>
            {lockedContent.map((item, index) => {
              switch (item.__typename) {
                case "Case_study_Casestudiescf_Modules_Strategy_LockedDescriptionFlex_Text":
                  return (
                    <TextContainer
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html: item.content,
                      }}
                    />
                  );
                case "Case_study_Casestudiescf_Modules_Strategy_LockedDescriptionFlex_TextBox":
                  return (
                    <TextBox
                      key={index}
                      headline={item.headline}
                      text={item.text}
                      light={item.light}
                    />
                  );
                case "Case_study_Casestudiescf_Modules_Strategy_LockedDescriptionFlex_Icon":
                  switch (item.type) {
                    case "Easy":
                      return (
                        <BoxIcon
                          color={colors.textBoxBeige}
                          key={index}
                          link="/easy.svg"
                        />
                      );
                    case "Safe":
                      return (
                        <BoxIcon
                          color={colors.textBoxBeige}
                          key={index}
                          link="/safe.svg"
                        />
                      );
                    case "UserFlow":
                      return (
                        <BoxIcon
                          color={colors.textBoxBeige}
                          key={index}
                          link="/userFlow.svg"
                        />
                      );
                    case "Performance":
                      return (
                        <BoxIcon
                          color={colors.textBoxBeige}
                          key={index}
                          link="/performance.svg"
                        />
                      );
                    case "CrossPlatform":
                      return (
                        <BoxIcon
                          key={index}
                          color={colors.textBoxBeige}
                          link="/crossPlatform.svg"
                          bigHeight
                        />
                      );
                    case "NativeProgramming":
                      return (
                        <BoxIcon
                          color={colors.textBoxBeige}
                          key={index}
                          link="/nativeProgramming.svg"
                        />
                      );
                    case "Dsgvo":
                      return <BoxIcon key={index} link="/dsgvo.svg" />;
                    case "AppOnly":
                      return (
                        <BoxIcon
                          color={colors.textBoxBeige}
                          key={index}
                          link="/appOnly.svg"
                        />
                      );
                    case "Security":
                      return <BoxIcon key={index} link="/sicherheit.svg" />;
                    default:
                      return <></>;
                  }
              }
            })}
          </LockedContent>
        )}
        {!unlocked && (
          <KeyPointContainer unlocked={false}>
            {keypointsFlex.map((keypoint, index) => (
              <KeyPoint
                key={index}
                index={index}
                open={open === index}
                setOpen={setOpen}
                unlocked={unlocked}
                title={keypoint.title}
                content={keypoint.content}
                media={keypoint.mediaFlex}
                images={keypoint.images}
              />
            ))}
          </KeyPointContainer>
        )}
      </FlexibleGrid>
      {unlocked && (
        <KeyPointContainer unlocked={true}>
          {keypointsFlex.map((keypoint, index) => (
            <KeyPoint
              key={index}
              index={index}
              open={open === index}
              setOpen={setOpen}
              unlocked={unlocked}
              title={keypoint.title}
              content={keypoint.content}
              media={keypoint.mediaFlex}
              images={keypoint.images}
            />
          ))}
        </KeyPointContainer>
      )}
    </MainWrapper>
  );
};
