import {
  Areas,
  Description,
  Title,
  Line,
  CloseButton,
  Number,
  OpenButton,
  Wrapper,
  TitleWrapper,
  TabWrapper,
  ContentWrapper,
  ImageContainer,
  StrategyImage,
  VideoWrapper,
} from "./KeyPoint.styles";
import { removeParagraphs } from "../../../../../helpers";
import { Video } from "../../../../common/Video/Video.component";

export const KeyPoint = ({
  open,
  unlocked,
  setOpen,
  index,
  title,
  content,
  media,
  images,
}) => {
  return (
    <Wrapper onClick={() => setOpen(open ? null : index)} unlocked={unlocked}>
      <TabWrapper>
        <TitleWrapper>
          <Number>{`Phase ${index + 1}`}</Number>
          <Title>{title}</Title>
        </TitleWrapper>
        {unlocked && (open ? <CloseButton /> : <OpenButton />)}
      </TabWrapper>
      <ContentWrapper open={open} unlocked={unlocked}>
        {!!images.length &&
          media.length === 0 &&
          images.map((image, index) => [
            <StrategyImage
              open={open}
              unlocked={unlocked}
              key={index}
              widescreen={image.widescreen}
              src={`${process.env.REACT_APP_IMG_MEDIA_SRC}${image.url}`}
            />,
          ])}
        {media.length > 0 && (
          <ImageContainer open={open} unlocked={unlocked}>
            {media.map((item, index) => {
              switch (item.__typename) {
                case "Case_study_Casestudiescf_Modules_Strategy_KeyPoints_KeyPoint_MediaFlex_Image":
                  return (
                    <StrategyImage
                      open={open}
                      unlocked={unlocked}
                      key={index}
                      widescreen={item.widescreen}
                      src={`${process.env.REACT_APP_IMG_MEDIA_SRC}${item.url}`}
                      alt={item.alt}
                    />
                  );
                case "Case_study_Casestudiescf_Modules_Strategy_KeyPoints_KeyPoint_MediaFlex_Video":
                  return (
                    <VideoWrapper key={index} widescreen={item.widescreen}>
                      <Video
                        src={item.url}
                        loop={item.options.loop}
                        autoPlay={item.options.autoplay}
                        externalSource={item.options.externalSource}
                      />
                    </VideoWrapper>
                  );
                case "Case_study_Casestudiescf_Modules_Strategy_KeyPoints_KeyPoint_MediaFlex_YoutubeVideo":
                  return (
                    <VideoWrapper isEmbedded key={index} widescreen={item.widescreen}>
                      <Video isEmbedded src={item.url} />
                    </VideoWrapper>
                  );
                default:
                  return <></>;
              }
            })}
          </ImageContainer>
        )}
        <Description
          open={open}
          unlocked={unlocked}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </ContentWrapper>
      <Line></Line>
    </Wrapper>
  );
};
