import {Footer} from "../../common/Footer/Footer.component";
import {ContactForm} from "../../CaseStudies/Subpage/ContactForm/ContactForm.component";
import {AppLinks} from "../../CaseStudies/Subpage/AppLinks/AppLinks.component";
import {Header} from "../../CaseStudies/Subpage/Header/Header.component";
import {SimilarProjects} from "../../CaseStudies/Subpage/SimilarProjects/SimilarProjects.component";
import {useState, useEffect} from "react";
import {Strategy} from "../../CaseStudies/Subpage/Strategy/Strategy.component";
import {CaseOutcome} from "../../CaseStudies/Subpage/CaseOutcome/CaseOutcome.component";
import {ProjectTask} from "../../CaseStudies/Subpage/ProjectTask/ProjectTask.component";
import {CaseOverview} from "../../CaseStudies/Subpage/CaseOverview/CaseOverview.component";
import {ImageGallery} from "../../CaseStudies/Subpage/ImageGallery/ImageGallery.component";
import {ModularImageCenter} from "../../CaseStudies/Subpage/ModularImageCenter/ModularImageCenter.component";
import {ResponsiveLayout} from "../../CaseStudies/Subpage/ResponsiveLayout/ResponsiveLayout.component";
import {FeatureDescriptions} from "../../CaseStudies/Subpage/FeatureDescriptions/FeatureDescriptions.component";
import {BeforeAndAfter} from "../../CaseStudies/Subpage/BeforeAndAfter/BeforeAndAfter.component";
import {ResponsiveNumbers} from "../../CaseStudies/Subpage/ResponsiveNumbers/ResponsiveNumbers.component";
import {BackgroundInformation} from "../../CaseStudies/Subpage/BackgroundInformation/BackgroundInformation.component";
import {StyledModal} from "../../common/StyledModal/StyledModal.component";
import {useRouter} from "next/router";
import {getLocalStorage} from "../../../common/localstorage";


function isUnlocked(longVersion, userRegistered){
  // longversion === true has highest priority, if true, we show always locked content as unlocked
  if(longVersion === true || userRegistered === true) return true
  else return false
}


export const CaseStudiesSubpage = ({
                                     content,
                                     similarCases,
                                     navbarSwitch,
                                     setNavbarSwitch,
                                     footerCF,
                                     popup,
                                   }) => {
  const [keyList, setKeyList] = useState([]);
  const router = useRouter();
  const [modalOpen, setModalOpen] = useState(false);

  // different states means different behaviour
  // for more info look at https://thaliamayersche.atlassian.net/wiki/spaces/BIO/pages/340820101/Case+Study+URL+Parameter
  const [isUserRegeistered, setUserRegistered] = useState(false);
  const [isLongVersion, setLongVersion] = useState(false);
  const [unlocked, setUnlocked] = useState(false);
  const [forceContactForm, setForceContactForm] = useState(false);

  useEffect(() => {
    const localState = getLocalStorage()
    setKeyList(localState);
    setLongVersion(Boolean(router.query.longversion));
    setUserRegistered(localState.includes(content[0].id));
    setUnlocked(
      isUnlocked(Boolean(router.query.longversion), localState.includes(content[0].id))
    );
    setModalOpen(router.query.success);
    setForceContactForm(router.query.registerBeforeDownload);
  }, [router]); 
  return (
    <>
      <StyledModal
        open={modalOpen}
        setOpen={setModalOpen}
        text={popup.popupContent}
        headline={popup.popupHeadline}
        icon={popup.popupIcon}
        button={popup.popupButton}
      />
      {content[0].caseStudiesCF.modules.map((item, index) => {
        switch (item.__typename) {
          case "Case_study_Casestudiescf_Modules_AppLinks":
            return !item.contentLocked || unlocked ? (
              <AppLinks
                key={index}
                headline={item.headline}
                link={item.appLink.url}
                linkText={item.appLink.text}
                icons={item.icons}
              />
            ) : (
              <></>
            );
          case "Case_study_Casestudiescf_Modules_Header":
             return  (
              <Header
                key={index}
                headline={item.headline}
                media={item.mediaFlex}
                facts={item.factsFlex}
                disciplineTitle={item.disciplineTitle}
                discipline={content[0].caseStudiesCF.discipline}
                unlocked={!item.contentLocked || unlocked}
                caseReport={item.caseReport}
                forceContactForm={forceContactForm}
                isUserRegistered={isUserRegeistered}
              />
            )
          case "Case_study_Casestudiescf_Modules_FeatureDescriptions":
            return !item.contentLocked || unlocked ? (
              <FeatureDescriptions
                key={index}
                image={item.image}
                media={item.mediaFlex}
                numbers={item.numbersInt}
                mirrored={item.mirrored}
              />
            ) : (
              <></>
            );
          case "Case_study_Casestudiescf_Modules_ImageGallery":
            return !item.contentLocked || unlocked ? (
              <ImageGallery key={index} media={item.imagesFlex}/>
            ) : (
              <></>
            );
          case "Case_study_Casestudiescf_Modules_ResponsiveNumbers":
            return !item.contentLocked || unlocked ? (
              <ResponsiveNumbers
                key={index}
                media={item.mediaFlex}
                headline={item.headline}
                text={item.text}
                numbers={item.numbersInt}
                mirrored={item.mirrored}
              />
            ) : (
              <></>
            );
          case "Case_study_Casestudiescf_Modules_BeforeAndAfter":
            return !item.contentLocked || unlocked ? (
              <BeforeAndAfter
                key={index}
                image={item.image}
                media={item.mediaFlex}
                headline={item.headline}
                text={item.text}
                mirrored={item.mirrored}
              />
            ) : (
              <></>
            );
          case "Case_study_Casestudiescf_Modules_SimilarProjects":
            return !item.contentLocked || unlocked ? (
              <SimilarProjects
                key={index}
                headline={item.headline}
                subHeadline={item.subHeadline}
                buttonText={item.gobackbutton}
                cases={similarCases}
                similarCases={item.similarCasesFlex}
              />
            ) : (
              <></>
            );
          case "Case_study_Casestudiescf_Modules_Strategy":
            return (
              <Strategy
                unlocked={unlocked}
                key={index}
                headline={item.headline}
                subHeadline={item.subHeadline}
                contactText={item.contactText}
                buttonText={item.buttonText}
                descriptionPoints={item.descriptionPointsFlex}
                lockedContent={item.lockedDescriptionFlex}
                keypointsFlex={item.keyPoints}
              />
            );
          case "Case_study_Casestudiescf_Modules_BackgroundInformation":
            return !item.contentLocked || unlocked ? (
              <BackgroundInformation
                key={index}
                headline={item.headline}
                subHeadline={item.subHeadline}
                contactText={item.contactText}
                shortDescription={item.shortDescription}
                buttonText={item.buttonText}
                keypointsFlex={item.keyPoints}
              />
            ) : (
              <></>
            );
          case "Case_study_Casestudiescf_Modules_ModularImageCenter":
            return !item.contentLocked || unlocked ? (
              <ModularImageCenter
                key={index}
                leftSide={item.leftSide}
                rightSide={item.rightSide}
                image={item.image}
                media={item.mediaFlex}
                bottomText={item.bottomText}
                mirrored={item.mirrored}
              />
            ) : (
              <></>
            );
          case "Case_study_Casestudiescf_Modules_ResponsiveLayout":
            return !item.contentLocked || unlocked ? (
              <ResponsiveLayout
                key={index}
                headline={item.headline}
                text={item.text}
                media={item.imagesFlex}
                mirrored={item.mirrored}
              />
            ) : (
              <></>
            );
          case "Case_study_Casestudiescf_Modules_CaseOutcome":
            return !item.contentLocked || unlocked ? (
              <CaseOutcome
                unlocked={unlocked}
                key={index}
                subHeadline={item.subHeadline}
                headline={item.headline}
                contactText={item.contactText}
                button={item.button}
                keypoints={item.keyPoints}
              />
            ) : (
              <></>
            );
          case "Case_study_Casestudiescf_Modules_CaseOverview":
            return !item.contentLocked || unlocked ? (
              <CaseOverview
                key={index}
                testimonial={item.testimonial}
                author={item.author}
                media={item.mediaFlex}
              />
            ) : (
              <></>
            );
          case "Case_study_Casestudiescf_Modules_ProjectTask":
            return !item.contentLocked || unlocked ? (
              <ProjectTask
                key={index}
                headline={item.headline}
                subHeadline={item.subHeadline}
                testimonial={item.testimonial}
                author={item.author}
                image1url={item.images.image1.url}
                image1alt={item.images.image1.alt}
                image1widescreen={item.images.image1.widescreen}
                image2url={item.images.image2.url}
                image2alt={item.images.image2.alt}
                image2widescreen={item.images.image2.widescreen}
                media={item.mediaFlex}
              />
            ) : (
              <></>
            );
          case "Case_study_Casestudiescf_Modules_ContactForm":
            return (isLongVersion && !isUserRegeistered && forceContactForm) || !unlocked ? (
              <ContactForm
                key={index}
                keyList={keyList}
                setKeyList={setKeyList}
                contactKey={content[0].id}
                headline={item.headline}
                subHeadline={item.subHeadline}
                description={item.description}
                smalltext={item.smalltext}
                checkboxlabel={item.checkboxlabel}
                buttonlabel={item.buttonlabel}
              />
            ) : (
              <></>
            );
          default:
            return <></>;
        }
      })}
      <Footer
        catchphrase={footerCF.catchphrase}
        navbarSwitch={navbarSwitch}
        setNavbarSwitch={setNavbarSwitch}
        logo={footerCF.logo}
        basicButtonUrl={footerCF.basicbutton.url}
        basicButtonText={footerCF.basicbutton.text}
        basicButtonUrlType={footerCF.basicbutton.linktype}
        basicButtonSubject={footerCF.basicbutton.topic}
        contactsectionTitle={footerCF.contactsection.title}
        contactsectionAddress1={footerCF.contactsection.address1}
        contactsectionAddress2={footerCF.contactsection.address2}
        contactsectionPhoneUrl={footerCF.contactsection.phone.url}
        contactsectionPhoneText={footerCF.contactsection.phone.text}
        contactsectionEmailUrl={footerCF.contactsection.email.url}
        contactsectionEmailText={footerCF.contactsection.email.text}
        socialmediaText={footerCF.socialmedia.text}
        socialmediaUrl={footerCF.socialmedia.url}
        socialmediaTarget={footerCF.socialmedia.target}
        socialmediaReferral={footerCF.socialmedia.referral}
        menuTitle={footerCF.menu.title}
        menuLinks={footerCF.menu.linksflex}
        disclaimer={footerCF.disclaimer}
        bottomLinkItem1Url={footerCF.bottomlink.item1.url}
        bottomLinkItem1Text={footerCF.bottomlink.item1.text}
        bottomLinkItem2Url={footerCF.bottomlink.item2.url}
        bottomLinkItem2Text={footerCF.bottomlink.item2.text}
        copyright1={footerCF.copyright.item1}
        copyright2={footerCF.copyright.item2}
      />
    </>
  );
};
