import styled from "styled-components";
import { MainGridWrapper } from "../../../../styles/Main.styles";
import { deviceSizes } from "../../../../styles/deviceSizes";

export const MainWrapper = styled(MainGridWrapper)`
  margin-top: 75px;
  margin-bottom: 75px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    margin-top: 120px;
    margin-bottom: 120px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-top: 155px;
    margin-bottom: 155px;
  }
`;

export const HeadlineWrapper = styled.div`
  grid-column-start: span 2;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 40px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 5 / span 8;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-bottom: 65px;
  }
`;

export const TestimonialWrapper = styled.div`
  grid-column-start: span 2;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column-start: span 10;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column-start: span 9;
    margin-bottom: 65px;
  }
`;

export const StyledImage = styled.img`
  &:nth-child(1) {
    grid-column: 1 / span 1;
    width: 120%;
  }
  &:nth-child(2) {
    grid-column: 2 / span 1;
    width: 80%;
    justify-self: end;
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    width: 100%;
    &:nth-child(1) {
      grid-column: 1 / span 6;
      width: 100%;
    }
    &:nth-child(2) {
      grid-column: 8 / span 4;
      width: 100%;
      justify-self: auto;
    }
  }
`;

export const VideoWrapper = styled.div`
  display: flex;
  &:nth-child(1) {
    grid-column: 1 / span 1;
    width: 120%;
    ${({ isEmbedded }) => isEmbedded && "min-height: 120px;"}
    ${({ isEmbedded }) => isEmbedded && "aspect-ratio: 3 / 2;"}
  }
  &:nth-child(2) {
    grid-column: 2 / span 1;
    width: 80%;
    justify-self: end;
    ${({ isEmbedded }) => isEmbedded && "min-height: 175px;"}
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    width: 100%;
    &:nth-child(1) {
      grid-column: 1 / span 6;
      width: 100%;
      ${({ isEmbedded }) => isEmbedded && "min-height: 255px;"}
    }
    &:nth-child(2) {
      grid-column: 8 / span 4;
      width: 100%;
      justify-self: auto;
      ${({ isEmbedded }) => isEmbedded && "min-height: 370px;"}
    }
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    &:nth-child(1) {
      ${({ isEmbedded }) => isEmbedded && "min-height: 425px;"}
    }
    &:nth-child(2) {
      ${({ isEmbedded }) => isEmbedded && "min-height: 630px;"}
    }
  }
`;
export const ImageWrapper = styled(MainGridWrapper)`
  grid-column-start: span 2;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column-start: span 12;
  }
`;
