import {
  Description,
  Title,
  Line,
  CloseButton,
  Number,
  OpenButton,
  Wrapper,
  TitleWrapper,
  TabWrapper,
  ContentWrapper,
  ImageContainer,
  StrategyImage,
} from "./KeyPoint.styles";
import { removeParagraphs } from "../../../../../helpers";

export const KeyPoint = ({ index, title }) => {
  return (
    <Wrapper onClick={() => setOpen(open ? null : index)}>
      <TabWrapper>
        <Number>{title}</Number>
      </TabWrapper>
      <Line></Line>
    </Wrapper>
  );
};
