import styled from "styled-components";
import { ContinuousText, H3 } from "../../../../../styles/Text.styles";
import CloseIcon from "../../../../../public/closeIcon.svg";
import PlusIcon from "../../../../../public/plusIcon.svg";
import { colors } from "../../../../../styles/colors";
import { deviceSizes } from "../../../../../styles/deviceSizes";

export const Wrapper = styled.div`
  grid-column: 1/3;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/7;
    grid-template-columns: repeat(6, 1fr);
  }
  @media only screen and (min-width: ${deviceSizes.max_desktop}) {
    grid-column: 1/7;
    grid-template-columns: repeat(6, 90px);
  }
`;

export const Number = styled(H3)`
  grid-column: 1/2;
  white-space: nowrap;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/7;
  }
`;

export const TabWrapper = styled.div`
  grid-column: 1/3;
  display: grid;
  gap: 5px;
  grid-column-gap: 20px;
  align-content: center;
  white-space: nowrap;
  margin-bottom: 15px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/7;
    grid-template-columns: repeat(6, 1fr);
  }
  @media only screen and (min-width: ${deviceSizes.max_desktop}) {
    grid-template-columns: repeat(6, 90px);
  }
`;

export const ContentWrapper = styled.div`
  grid-column: 1/3;
  display: grid;
  grid-column-gap: 20px;
  margin-bottom: ${({ open, unlocked }) =>
    open && unlocked ? "30px" : "0"};
  grid-template-columns: repeat(2, 1fr);
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 5/13;
    margin-bottom: ${({ open, unlocked }) =>
      open && unlocked ? "40px" : "0"};
    grid-template-columns: repeat(8, 1fr);
  }
  @media only screen and (min-width: ${deviceSizes.max_desktop}) {
    grid-template-columns: repeat(8, 90px);
    margin-bottom: ${({ open, unlocked }) =>
      open && unlocked ? "60px" : "0"};
  }
`;

export const Title = styled(H3)`
  grid-column: 1/2;
  white-space: nowrap;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/7;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: 1/7;
  }
`;

export const Description = styled(ContinuousText)`
  display: ${({ open, unlocked }) => (open && unlocked ? "inherit" : "none")};
  grid-column: 1/3;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/9;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: 1/9;
  }
`;

export const ImageContainer = styled.div`
  display: ${({ open, unlocked }) => (open && unlocked ? "grid" : "none")};
  grid-column: 1/3;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  margin-bottom: 15px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-template-columns: repeat(8, 1fr);
    grid-column: 1/9;
    margin-bottom: 20px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: 1/9;
    margin-bottom: 30px;
  }
  @media only screen and (min-width: ${deviceSizes.max_desktop}) {
    grid-template-columns: repeat(8, 90px);
  }
`;

export const StrategyImage = styled.img`
  grid-column-start: span 1;
  width: 100%;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column-start: ${({ widescreen }) =>
      widescreen ? "span 5" : "span 3"};
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;



export const Line = styled.div`
  grid-column: 1/3;
  border-bottom: 1px solid ${colors.anthrazit};
  margin-bottom: 15px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/7;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;

