import {
  MainWrapper,
  LeftSideWrapper,
  RightSideWrapper,
  StyledImage,
  BottomText,
  StyledText,
  VideoWrapper,
} from "./ModularImageCenter.styles";
import { TextBox } from "./TextBox/TextBox.component";
import { getYouTubeID, removeParagraphs } from "../../../../helpers";
import { ContinuousText } from "../../../../styles/Text.styles";
import { Video } from "../../../common/Video/Video.component";

export const ModularImageCenter = ({
  leftSide,
  image,
  media,
  rightSide,
  bottomText,
  mirrored,
}) => {
  return (
    <MainWrapper>
      <LeftSideWrapper>
        {leftSide.map((item, index) => {
          switch (item.__typename) {
            case "Case_study_Casestudiescf_Modules_ModularImageCenter_LeftSide_TextBox":
              return (
                <TextBox
                  key={index}
                  headline={item.headline}
                  text={item.content}
                  light={item.light}
                />
              );
            case "Case_study_Casestudiescf_Modules_ModularImageCenter_LeftSide_Text":
              return (
                <StyledText
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: item.content,
                  }}
                />
              );
            default:
              return <></>;
          }
        })}
      </LeftSideWrapper>
      {image && media.length === 0 && (
        <StyledImage
          src={`${process.env.REACT_APP_IMG_MEDIA_SRC}${image.url}`}
          alt={image.alt}
          mirrored={mirrored}
        />
      )}
      {!!media.length &&
        media.map((item, index) => {
          switch (item.__typename) {
            case "Case_study_Casestudiescf_Modules_ModularImageCenter_MediaFlex_Image":
              return (
                <StyledImage
                  key={index}
                  src={process.env.REACT_APP_IMG_MEDIA_SRC + item.url}
                  alt={item.alt}
                  mirrored={mirrored}
                />
              );
            case "Case_study_Casestudiescf_Modules_ModularImageCenter_MediaFlex_Video":
              return (
                <VideoWrapper key={index} mirrored={mirrored}>
                  <Video
                    src={item.url}
                    loop={item.options.loop}
                    autoPlay={item.options.autoplay}
                    externalSource={item.options.externalSource}
                  />
                </VideoWrapper>
              );
            case "Case_study_Casestudiescf_Modules_ModularImageCenter_MediaFlex_YoutubeVideo":
              return (
                <>
                  {getYouTubeID(item.url) && (
                    <VideoWrapper isEmbedded key={index} mirrored={mirrored}>
                      <Video isEmbedded src={item.url} />
                    </VideoWrapper>
                  )}
                </>
              );
            default:
              return <></>;
          }
        })}
      <RightSideWrapper mirrored={mirrored}>
        {rightSide.map((item, index) => {
          switch (item.__typename) {
            case "Case_study_Casestudiescf_Modules_ModularImageCenter_RightSide_TextBox":
              return (
                <TextBox
                  key={index}
                  headline={item.headline}
                  text={item.content}
                  light={item.light}
                />
              );
            case "Case_study_Casestudiescf_Modules_ModularImageCenter_RightSide_Text":
              return (
                <StyledText
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: item.content,
                  }}
                />
              );
            default:
              return <></>;
          }
        })}
      </RightSideWrapper>
      {bottomText && (
        <BottomText
          dangerouslySetInnerHTML={{
            __html: bottomText,
          }}
        />
      )}
    </MainWrapper>
  );
};
