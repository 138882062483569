import styled from "styled-components";
import { H2, ContinuousText, H3 } from "../../../../styles/Text.styles";
import { deviceSizes } from "../../../../styles/deviceSizes";
import { MainGridWrapper } from "../../../../styles/Main.styles";
import { colors } from "../../../../styles/colors";

export const MainWrapper = styled(MainGridWrapper)`
  margin-top: 75px;
  margin-bottom: 75px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    margin-top: 120px;
    margin-bottom: 120px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-top: 155px;
    margin-bottom: 155px;
  }
`;

export const SubHeadline = styled(ContinuousText)`
  grid-column: 1/3;
  margin-bottom: 10px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/9;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: 1/5;
  }
`;

export const Headline = styled(H2)`
  grid-column: 1/3;
  margin-bottom: 15px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/13;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;

export const PreviewWrapper = styled.div`
  margin: 35px 0px 40px 0px;
  grid-column: 1/3;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/13;
    margin: 40px 0px 40px 0px;
  }
  @media only screen and (min-width: 861px) {
    grid-row-gap: 120px;
    margin: 62px 0px 53px 0px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin: 75px 0px 75px 0px;
    grid-column: 1/13;
  }
  @media only screen and (min-width: ${deviceSizes.max_desktop}) {
  }
`;

export const GoBackButton = styled(H3)`
  grid-column: 1/3;
  cursor: pointer;
  width: max-content;
  border-bottom: 1px solid ${colors.anthrazit};
  padding-bottom: 5px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/6;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: 1/5;
  }
`;
