import { ContinuousText, H3 } from "../../../../styles/Text.styles";
import {
  MainWrapper,
  HeadlineWrapper,
  TestimonialWrapper,
  ImageWrapper,
  StyledImage,
  VideoWrapper,
} from "./ProjectTask.styles";
import { getYouTubeID, removeParagraphs } from "../../../../helpers";
import { Video } from "../../../common/Video/Video.component";

export const ProjectTask = ({
  headline,
  subHeadline,
  testimonial,
  author,
  media,
  image1url,
  image2url,
  image1alt,
  image2alt,
  image1widescreen,
  image2widescreen,
}) => {
  const images = [
    {
      url: `${process.env.REACT_APP_IMG_MEDIA_SRC}${image1url}`,
      alt: image1alt,
      widescreen: image1widescreen,
    },
    {
      url: `${process.env.REACT_APP_IMG_MEDIA_SRC}${image2url}`,
      alt: image2alt,
      widescreen: image2widescreen,
    },
  ];
  return (
    <MainWrapper>
      <HeadlineWrapper>
        <H3
          dangerouslySetInnerHTML={{
            __html: headline,
          }}
        />
        <ContinuousText
          dangerouslySetInnerHTML={{
            __html: subHeadline,
          }}
        />
      </HeadlineWrapper>
      <TestimonialWrapper>
        <H3>
          <i>{testimonial}</i>
        </H3>
        <ContinuousText>{author}</ContinuousText>
      </TestimonialWrapper>
      {[...images, ...media].length > 0 && (
        <ImageWrapper noPadding>
          {!!images.length &&
            media.length === 0 &&
            images.map((item, index) =>
              !item.url.endsWith("null") ? (
                <StyledImage
                  key={index}
                  src={item.url}
                  alt={item.alt}
                  widescreen={item.widescreen}
                />
              ) : (
                ""
              )
            )}
          {media.length > 0 &&
            media.map((item, index) => {
              switch (item.__typename) {
                case "Case_study_Casestudiescf_Modules_ProjectTask_MediaFlex_Image":
                  return (
                    <StyledImage
                      key={index}
                      src={process.env.REACT_APP_IMG_MEDIA_SRC + item.url}
                      alt={item.alt}
                      widescreen={item.widescreen}
                    />
                  );
                case "Case_study_Casestudiescf_Modules_ProjectTask_MediaFlex_Video":
                  return (
                    <VideoWrapper key={index} widescreen={item.widescreen}>
                      <Video
                        src={item.url}
                        loop={item.options.loop}
                        autoPlay={item.options.autoplay}
                        externalSource={item.options.externalSource}
                      />
                    </VideoWrapper>
                  );
                case "Case_study_Casestudiescf_Modules_ProjectTask_MediaFlex_YoutubeVideo":
                  return (
                    <>
                      {getYouTubeID(item.url) && (
                        <VideoWrapper isEmbedded key={index} widescreen={item.widescreen}>
                          <Video isEmbedded src={item.url} />
                        </VideoWrapper>
                      )}
                    </>
                  );
                default:
                  return <></>;
              }
            })}
        </ImageWrapper>
      )}
    </MainWrapper>
  );
};
