

function urlencodeFormData(fd){
  const params = new URLSearchParams(fd);
  return params.toString();
}

export const recaptcha = (e, onSuccess) => {
  window.grecaptcha
    .execute(process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY, {
      action: "submit",
    })
    .then(async (token) => {
      const body = {
        recaptchaResponse: token,
      };
      try {
        const response = await fetch("/api/botTest", {
          method: "POST",
          headers: {"Content-Type": "application/json;charset=utf-8"},
          body: JSON.stringify(body),
        });
        if (response.ok) {
          const response = await fetch('/api/formForwarder', {
            method: "POST",
            body: urlencodeFormData(new FormData(e.target)),
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            }
          })

          if (onSuccess) {
            onSuccess(response.ok)
          }
          return true;
        }
        return false;

      } catch (error) {
        console.error(error)
      }
    });
}
