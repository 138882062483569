import styled from "styled-components";
import { MainGridWrapper } from "../../../../styles/Main.styles";
import { deviceSizes } from "../../../../styles/deviceSizes";
import { ContinuousText } from "../../../../styles/Text.styles";

export const MainWrapper = styled(MainGridWrapper)`
  margin-top: 75px;
  margin-bottom: 75px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    margin-top: 120px;
    margin-bottom: 120px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-top: 155px;
    margin-bottom: 155px;
  }
`;

export const LeftSideWrapper = styled.div`
  grid-column: 1 / span 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 35px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1 / span 4;
    align-self: center;
    margin-bottom: 0;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;

export const StyledImage = styled.img`
  grid-column: ${({ mirrored }) => (mirrored ? "1 / span 2" : "1 / span 2")};
  width: 50%;
  justify-self: center;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 6 / span 2;
    align-self: center;
    width: 120%;
    justify-self: center;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;

export const VideoWrapper = styled.div`
  grid-column: ${({ mirrored }) => (mirrored ? "1 / span 2" : "1 / span 2")};
  width: 50%;
  height: 100%;
  justify-self: center;
  display: flex;
  ${({ isEmbedded }) => isEmbedded && "min-height: 335px;"}
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 6 / span 2;
    align-self: center;
    width: 120%;
    justify-self: center;
    ${({ isEmbedded }) => isEmbedded && "min-height: 300px;"}
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    ${({ isEmbedded }) => isEmbedded && "min-height: 510px;"}
  }
`;

export const RightSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: ${({ mirrored }) => (mirrored ? "1 / span 2" : "1 / span 2")};
  gap: 10px;
  margin-top: 15px;
  align-self: end;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    align-self: center;
    grid-row: 1;
    grid-column: 9 / span 4;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;

export const BottomText = styled(ContinuousText)`
  grid-column: 1 / span 2;
  margin-top: 15px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1 / span 12;
    margin-top: 30px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-top: 75px;
  }
`;

export const StyledText = styled(ContinuousText)`
  & > h3,
  h2,
  h1 {
    margin-block: 0;
    font-weight: 400;
  }
  & > h3 {
    font-size: 22px;
    line-height: 28px;
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    & > h3 {
      font-size: 28px;
      line-height: 38px;
    }
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    & > h3 {
      font-size: 36px;
      line-height: 48px;
    }
  }
`;
