import {
  SubHeadline,
  Headline,
  MainWrapper,
  StyledImage,
  VideoWrapper,
  MediaWrapper,
  FactsWrapper,
} from "./Header.styles";
import { Video } from "../../../common/Video/Video.component";
import { getYouTubeID } from "../../../../helpers";
import React from "react";
import { DownloadCaseReport } from "./DownloadCaseReport/DownloadCaseReport.component";

export const Header = ({
  headline,
  facts,
  media,
  disciplineTitle,
  discipline,
  caseReport,
  unlocked,
  isUserRegistered
}) => {
    return (
    <MainWrapper>
      <Headline
        dangerouslySetInnerHTML={{
          __html: headline,
        }}
      />
      { caseReport.file && (
        <DownloadCaseReport
          unlocked={unlocked}
          label={caseReport.label}
          fileSize={caseReport.file.fileSize}
          url={caseReport.file.mediaItemUrl}
          isUserRegistered={isUserRegistered}
        />
      )}
      <MediaWrapper>
        {media.map((item, index) => {
          switch (item.__typename) {
            case "Case_study_Casestudiescf_Modules_Header_MediaFlex_Image":
              return (
                <StyledImage
                  key={index}
                  src={process.env.REACT_APP_IMG_MEDIA_SRC + item.url}
                  alt={item.alt}
                />
              );
            case "Case_study_Casestudiescf_Modules_Header_MediaFlex_Video":
              return (
                <VideoWrapper key={index}>
                  <Video
                    src={item.url}
                    loop={item.options.loop}
                    autoPlay={item.options.autoplay}
                    externalSource={item.options.externalSource}
                  />
                </VideoWrapper>
              );
            case "Case_study_Casestudiescf_Modules_Header_MediaFlex_YoutubeVideo":
              return (
                <>
                  {getYouTubeID(item.url) && (
                    <VideoWrapper isEmbedded key={index}>
                      <Video isEmbedded src={item.url} />
                    </VideoWrapper>
                  )}
                </>
              );
            default:
              return <></>;
          }
        })}
      </MediaWrapper>
      <FactsWrapper>
        {facts.length > 0 &&
          facts.map((item, index) => (
            <SubHeadline key={index}>
              <strong>{item.title}</strong>
              <br />
              {item.content}
            </SubHeadline>
          ))}
        {!discipline.includes("None") && (
          <>
            <SubHeadline>
              <strong>{disciplineTitle}</strong>
              <br />
              {discipline.length > 0 &&
                discipline.map((item, index) => (
                  <React.Fragment key={index}>
                    {item}
                    <br />
                  </React.Fragment>
                ))}
            </SubHeadline>
          </>
        )}
      </FactsWrapper>
    </MainWrapper>
  );
};
