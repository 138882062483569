import React from "react";
import { StyledButton } from "./BasicButton.styles";
import { ButtonText } from "../../../styles/Text.styles";
import Link from "next/link";

export const BasicButton = ({
  label,
  secondary = false,
  onClick,
  disabled,
  type = "button",
  href = null,
  props,
  urlType = "Internal",
  mailSubject = null,
}) => {
  return href ? (
    urlType === "Internal" ? (
      <Link href={href} prefetch={false}>
        <StyledButton
          secondary={secondary ? 1 : 0}
          disabled={disabled}
          fullWidth
          type={type}
          onClick={onClick}
          {...props}
        >
          <ButtonText>{label}</ButtonText>
        </StyledButton>
      </Link>
    ) : (
      <a
        href={
          urlType === "Email"
            ? `mailto:${href}${
                mailSubject !== "false" ? `?subject=${mailSubject}` : ""
              }`
            : href
        }
        rel="noreferrer"
        target="_blank"
      >
        <StyledButton
          secondary={secondary ? 1 : 0}
          disabled={disabled}
          fullWidth
          type={type}
          onClick={onClick}
          {...props}
        >
          <ButtonText>{label}</ButtonText>
        </StyledButton>
      </a>
    )
  ) : (
    <StyledButton
      secondary={secondary ? 1 : 0}
      disabled={disabled}
      fullWidth
      type={type}
      onClick={onClick}
      {...props}
    >
      <ButtonText>{label}</ButtonText>
    </StyledButton>
  );
};
