import { MenuItem, InputLabel } from "@mui/material";
import { useState } from "react";
import { BasicButton } from "../../common/BasicButton/BasicButton.component";
import { Checkbox } from "../../common/Checkbox/Checkbox.component";
import { useRouter } from "next/router";
import { colors } from "../../../styles/colors";
import { Select } from "../../common/Select/Select.component";
import {
  FormWrapper,
  MainWrapper,
  SubHeadline,
  Headline,
  Description,
  SmallText,
  ButtonWrapper,
  CheckboxLabel,
  CheckboxWrapper,
  FirstRowWrapper,
  PronounWrapper,
  NameInput,
  SurnameInput,
  ReasonWrapper,
  MessageInput,
  StyledFormHelper,
} from "./ContactForm.styles";
import { StyledInput } from "./ContactForm.styles";
import {recaptcha} from "../../../common/recaptcha";
import {EMAIL_REGEX} from "../../../common/forms";
export const ContactForm = () => {
  const errorDict = [
    "",
    "Sie haben dieses Feld nicht ausgefüllt.",
    "Das Format der E-Mail-Adresse ist ungültig.",
  ];
  const [pronoun, setPronoun] = useState({ value: "", error: 0 });
  const router = useRouter();
  const [name, setName] = useState({ value: "", error: 0 });
  const [surname, setSurname] = useState({ value: "", error: 0 });
  const [company, setCompany] = useState({ value: "", error: 0 });
  const [email, setEmail] = useState({ value: "", error: 0 });
  const [phone, setPhone] = useState({ value: "", error: 0 });
  const [reason, setReason] = useState({ value: "", error: 0 });
  const [message, setMessage] = useState({ value: "", error: 0 });
  const handleSubmit = (e) => {
    let errorFlag = false;
    e.preventDefault();
    if (name.value === "") {
      errorFlag = true;
      setName({ ...name, error: 1 });
    }
    if (pronoun.value === "") {
      errorFlag = true;
      setPronoun({ ...pronoun, error: 1 });
    }
    if (surname.value === "") {
      errorFlag = true;
      setSurname({ ...surname, error: 1 });
    }
    if (phone.value === "") {
      errorFlag = true;
      setPhone({ ...phone, error: 1 });
    }
    if (reason.value === "") {
      errorFlag = true;
      setReason({ ...reason, error: 1 });
    }
    if (message.value === "") {
      errorFlag = true;
      setMessage({ ...message, error: 1 });
    }
    if (email.value === "") {
      errorFlag = true;
      setEmail({ ...email, error: 1 });
    } else {
      if (
        EMAIL_REGEX.test(email.value) ===
        false
      ) {
        errorFlag = true;
        setEmail({ ...email, error: 2 });
      }
    }
    if (errorFlag) {
      e.preventDefault();
      return false;
    } else {
      window.grecaptcha.ready(() => {
        recaptcha(e, (success) => {
          if(success){
            router.replace({
              pathname: router.pathname,
              query: { ...router.query, success: 'true' },
            })
          }
        })
      });
    }
  };

  return (
    <MainWrapper>
      <SubHeadline>Du hast Fragen?</SubHeadline>
      <Headline>
        Du möchtest gerne mehr <i>Informationen</i> über uns erhalten?
      </Headline>
      <Description>
        Wir erzählen gerne mehr darüber, wie wir mit unseren agilen, innovativen
        und crossfunktionalen Strategie- und Entwicklerteams dein Unternehmen
        dabei unterstützen bessere Ergebnisse im online- und offline Sektor zu
        erreichen.
      </Description>
      <FormWrapper
        size="small"
        noValidate
        onSubmit={(e) => handleSubmit(e)}
      >
        <input
          type="hidden"
          name="lead-quelle"
          id="leadsource"
          value="contactformgeneral"
        />
        <FirstRowWrapper>
          <PronounWrapper fullWidth size="small" error={pronoun.error > 0}>
            <InputLabel sx={{ "&.Mui-focused": { color: colors.anthrazit } }}>
              Anrede
            </InputLabel>
            <Select
              id="salutation"
              name="salutation"
              label="Anrede"
              onChange={(e) => setPronoun({ value: e.target.value, error: 0 })}
            >
              <MenuItem value="">– Ohne –</MenuItem>
              <MenuItem value="Mr.">Herr</MenuItem>
              <MenuItem value="Mrs.">Frau</MenuItem>
              <MenuItem value="diverse">Divers</MenuItem>
            </Select>
            <StyledFormHelper>{errorDict[pronoun.error]}</StyledFormHelper>
          </PronounWrapper>
          <NameInput
            size="small"
            id="first_name"
            error={name.error > 0}
            onChange={(e) => setName({ value: e.target.value, error: 0 })}
            maxlength="40"
            name="first_name"
            type="text"
            helperText={errorDict[name.error]}
            placeholder="Vorname"
            required
          />
        </FirstRowWrapper>
        <SurnameInput
          size="small"
          id="last_name"
          maxlength="80"
          error={surname.error > 0}
          onChange={(e) => setSurname({ value: e.target.value, error: 0 })}
          helperText={errorDict[surname.error]}
          name="last_name"
          type="text"
          placeholder="Nachname"
          required
        />
        <StyledInput
          size="small"
          id="company"
          maxlength="40"
          name="company"
          error={company.error > 0}
          onChange={(e) => setCompany({ value: e.target.value, error: 0 })}
          helperText={errorDict[company.error]}
          type="text"
          placeholder="Firma"
        />
        <StyledInput
          size="small"
          id="email"
          maxlength="80"
          error={email.error > 0}
          onChange={(e) => setEmail({ value: e.target.value, error: 0 })}
          helperText={errorDict[email.error]}
          name="email"
          type="text"
          placeholder="E-mail"
          required
        />
        <StyledInput
          size="small"
          id="phone"
          maxlength="80"
          error={phone.error > 0}
          onChange={(e) => setPhone({ value: e.target.value, error: 0 })}
          helperText={errorDict[phone.error]}
          name="phone"
          type="text"
          placeholder="Telefonnummer"
          required
        />
        <ReasonWrapper fullWidth size="small" error={reason.error > 0}>
          <InputLabel sx={{ "&.Mui-focused": { color: colors.anthrazit } }}>
            Anliegen
          </InputLabel>
          <Select
            id="request reason"
            name="request reason"
            label="Anliegen"
            onChange={(e) => setReason({ value: e.target.value, error: 0 })}
          >
            <MenuItem value="">– Ohne –</MenuItem>
            <MenuItem value="Project">Projekt</MenuItem>

            <MenuItem value="CaseStudy">Case Study</MenuItem>

            <MenuItem value="Other">Anderes</MenuItem>
          </Select>
          <StyledFormHelper>{errorDict[reason.error]}</StyledFormHelper>
        </ReasonWrapper>
        <MessageInput
          size="small"
          id="message"
          name="message"
          maxlength="80"
          multiline
          rows="3"
          error={message.error > 0}
          onChange={(e) => setMessage({ value: e.target.value, error: 0 })}
          helperText={errorDict[message.error]}
          type="text"
          placeholder="Ihre Nachricht"
          wrap="soft"
          required
        />
        <SmallText>
          Die Thalia Digital Retail Solutions benötigt die von Ihnen angegebenen
          Kontaktdaten, um Sie über unsere Produkte und Dienstleistungen zu
          informieren. Sie können Ihre Einwilligung jederzeit über{" "}
          <a
            href="mailto:contact-drs@thalia.de"
            target="_blank"
            rel="noreferrer"
          >
            contact-drs@thalia.de
          </a>{" "}
          widerrufen. Informationen zur Abmeldung sowie zu unseren
          Datenschutzpraktiken und unserer Verpflichtung zum Schutz Ihrer Daten
          finden Sie in unserer Datenschutzrichtlinie. Diese Website ist durch
          reCAPTCHA geschützt und es gelten die{" "}
          <a
            href="https://policies.google.com/privacy?hl=en-US"
            target="_blank"
            rel="noreferrer"
          >
            Datenschutzbestimmungen
          </a>{" "}
          und
          <a
            href="https://policies.google.com/terms?hl=en-US"
            target="_blank"
            rel="noreferrer"
          >
            Nutzungsbedingungen
          </a>{" "}
          von Google.
        </SmallText>
        <CheckboxWrapper>
          <Checkbox
            type="checkbox"
            id="newsletter"
            name="newsletter"
            value="1"
          />
          <CheckboxLabel>
            Ja, ich möchte außerdem, dass Thalia Digital Retail Solutions mir
            seinen E-Mail-Newsletter mit Informationen, Neuigkeiten und Umfragen
            zusendet. Meine Einwilligung dazu kann ich jederzeit über
            <a
              href="mailto:contact-drs@thalia.com"
              target="_blank"
              rel="noreferrer"
            >
              contact-drs@thalia.com
            </a>{" "}
            widerrufen. Weitere Informationen finde ich hier:
            <a href="/datenschutz" target="_blank" rel="noreferrer">
              Hinweise zum Datenschutz
            </a>
            .
          </CheckboxLabel>
        </CheckboxWrapper>
        <ButtonWrapper>
          <BasicButton label="Anfrage versenden" type="submit" />
        </ButtonWrapper>
      </FormWrapper>
    </MainWrapper>
  );
};
