import styled from 'styled-components';
import { deviceSizes } from '../../../../../styles/deviceSizes';
import DownloadPDFIcon from '../../../../../public/downloadPDFIcon.svg';

export const MainWrapper = styled.div`
  grid-column: 1/13;
  margin-bottom: 15px;

  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/7;
    margin-bottom: 40px;
  }

  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-bottom: 30px;
  }
`;

export const DownloadIcon = styled(DownloadPDFIcon)`
  width: max-content;
`;

export const ButtonContentWrapper = styled.a`
  display: flex;
  align-items: center;
  gap: 20px;
`;
