export const fileTypes = {
  videos: ["mp4", "webm", "ogg", "watch"],
  images: [
    "apng",
    "avif",
    "gif",
    "jpeg",
    "jpg",
    "jfif",
    "pjpeg",
    "pjp",
    "png",
    "svg",
    "webp",
  ],
  lottie: ["json"],
};

export const getFileExtensionFromUrl = (url) =>
  url
    .substr(1 + url.lastIndexOf("/"))
    .split("?")[0]
    .split("#")[0]
    .split(".")
    .pop();

export const animateCounters = (time, setCounterValues, numbers) => {
  const isFloat = numbers.map((item) => item.number % 1 !== 0);
  const lowestNumber = Math.min(...numbers.map((item) => item.number));
  numbers.map((item, index) => {
    const interval =
      lowestNumber === item.number ? time : time / (item.number / lowestNumber);
    const timer = setInterval(() => {
      setCounterValues((prevValues) => {
        item &&
          (item.number === prevValues[index] || item.number === null) &&
          clearInterval(timer);
        return prevValues.map((value, id) =>
          index === id
            ? numbers[index].number
              ? value === numbers[index].number
                ? value
                : isFloat[index]
                ? parseFloat((value + 0.1).toFixed(1))
                : value + 1
              : null
            : value
        );
      });
    }, interval);
  });
};

export const updateImageSize = (data, setData, index, newHeight, newWidth) => {
  const newState = data.map((obj, id) => {
    if (id === index) {
      return { height: newHeight, width: newWidth };
    }
    return obj;
  });
  setData(newState);
};

export const removeParagraphs = (paragraph) =>
  paragraph.replace(/<p[^>]*>/g, "").replace(/<\/p>/g, "");

export const getYouTubeID = (link) => {
  if (!link) {
    return false;
  }

  const youtubeIdRegex =
    /^(?:(?:https|http):\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be).*?(?:\/|v\/|u\/|embed\/|shorts\/|watch\?v=|(?<username>user\/))(?<id>[\w\-]{11})(?:\?|&|$)/;
  const match = link.match(youtubeIdRegex);

  if (match?.groups?.username || !match?.groups?.id) {
    return false;
  }

  return match.groups.id;
};
