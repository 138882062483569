import styled from "styled-components";
import { H1, ContinuousText } from "../../../../styles/Text.styles";
import { deviceSizes } from "../../../../styles/deviceSizes";
import { MainGridWrapper } from "../../../../styles/Main.styles";

export const MainWrapper = styled(MainGridWrapper)`
  margin-top: 15px;
  margin-bottom: 75px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    margin-top: 40px;
    margin-bottom: 120px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-top: 65px;
    margin-bottom: 155px;
  }
`;

export const Headline = styled(H1)`
  grid-column: 1/3;
  margin-bottom: 15px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/13;
    margin-bottom: 45px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-bottom: 40px;
  }
`;

export const StyledImage = styled.img`
  width: 100%;
  &:nth-child(1) {
    grid-column: 1 / span 1;
  }
  &:nth-child(2) {
    grid-column: 2 / span 1;
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    &:nth-child(1) {
      grid-column: 1 / span 6;
    }
    &:nth-child(2) {
      grid-column: 8 / span 4;
    }
  }
`;

export const MediaWrapper = styled(ContinuousText)`
  grid-column: 1/3;
  margin-bottom: 10px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/10;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;

export const FactsWrapper = styled(ContinuousText)`
  display: grid;
  grid-column: 1/3;
  grid-template-columns: 50% 50%;
  // margin-bottom: 10px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    display: block;
    grid-column: 10/13;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;

export const SubHeadline = styled(ContinuousText)`
  // grid-column: 1/3;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/9;
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: 1/5;
    margin-bottom: 35px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const VideoWrapper = styled.div`
  width: 100%;
  grid-column: 1/3;
  display: flex;
  grid-row-start: 1;
  position: relative;
  ${({ isEmbedded }) => isEmbedded && "min-height: 250px;"}
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 4/13;
    ${({ isEmbedded }) => isEmbedded && "min-height: 440px;"}
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    ${({ isEmbedded }) => isEmbedded && "min-height: 726px;"}
  }
`;
