import { Wrapper, ArrowBase, ArrowHead, ArrowHeadWrapper } from "./Arrow.styles";

export const Arrow = ({ slim = false, right = false, side = false, onClick, color = null }) => {
  return (
    <Wrapper right={right} side={side} onClick={onClick}>
      <ArrowBase side={side} color={color} slim={slim} />
      <ArrowHeadWrapper side={side} slim={slim}>
        <ArrowHead side={side} color={color} slim={slim} />
      </ArrowHeadWrapper>
    </Wrapper>
  );
};
