import { useRouter } from "next/router";
import { useState } from "react";
import Head from "next/head";
import Script from "next/script";
import dynamic from "next/dynamic";
import { CaseStudies } from "../components/pages/case-studies";
import { CaseStudiesSubpage } from "../components/pages/case-studies-subpage";
import { Contact } from "../components/pages/contact";
import { LinkedInInsightTag } from 'nextjs-linkedin-insight-tag';


const Navbar = dynamic(() =>
  import("../components/common/Navbar/Navbar.component").then(
    (mod) => mod.Navbar
  )
);
const Standard = dynamic(() =>
  import("../components/pages/standard/index").then((mod) => mod.Standard)
);
const UberUns = dynamic(() =>
  import("../components/pages/uber-uns/index").then((mod) => mod.UberUns)
);
const Jobs = dynamic(() =>
  import("../components/pages/jobs/index").then((mod) => mod.AllJobs)
);
const Privacy = dynamic(() =>
  import("../components/pages/privacy/index").then((mod) => mod.Privacy)
);
const Imprint = dynamic(() =>
  import("../components/pages/imprint/index").then((mod) => mod.Imprint)
);
const Home = dynamic(() =>
  import("../components/pages/services/index").then((mod) => mod.Home)
);

export default function Post({
  type,
  id,
  post,
  header,
  footer,
  globalSEO,
  popup,
}) {
  const router = useRouter();
  const [navbarColorSwitch, setNavbarColorSwitch] = useState(false);
  const componentSwitcher = () => {
    switch (type) {
      case "Services":
        return (
          <Home
            servicesCF={post.services}
            caseStudies={post.caseStudies}
            caseStudiesSlug={post.caseStudiesSlug}
            navbarSwitch={navbarColorSwitch}
            setNavbarSwitch={setNavbarColorSwitch}
            footerCF={footer}
            popup={popup}
          />
        );
      case "Uber uns":
        return (
          <UberUns
            uberUns={post.aboutUs}
            navbarSwitch={navbarColorSwitch}
            setNavbarSwitch={setNavbarColorSwitch}
            footerCF={footer}
            jobs={post.jobs}
            popup={popup}
          />
        );
      case "Jobs":
        return (
          <Jobs
            jobsContent={post.jobsContent}
            navbarSwitch={navbarColorSwitch}
            setNavbarSwitch={setNavbarColorSwitch}
            footerCF={footer}
            jobs={post.jobs}
            popup={popup}
          />
        );
      case "Privacy":
        return (
          <Privacy
            privacyContent={post.content}
            navbarSwitch={navbarColorSwitch}
            setNavbarSwitch={setNavbarColorSwitch}
            footerCF={footer}
            popup={popup}
          />
        );
      case "Imprint":
        return (
          <Imprint
            privacyContent={post.content}
            navbarSwitch={navbarColorSwitch}
            setNavbarSwitch={setNavbarColorSwitch}
            footerCF={footer}
            popup={popup}
          />
        );
      case "Contact":
        return (
          <Contact
            content={post.content}
            contactCF={post.contactCF}
            navbarSwitch={navbarColorSwitch}
            setNavbarSwitch={setNavbarColorSwitch}
            footerCF={footer}
            popup={popup}
          />
        );

      case "Case Studies":
        if (id) {
          return (
            <CaseStudiesSubpage
              content={post.content.cases}
              similarCases={post.content.similarCases}
              navbarSwitch={navbarColorSwitch}
              setNavbarSwitch={setNavbarColorSwitch}
              footerCF={footer}
              popup={popup}
            />
          );
        } else {
          return (
            <CaseStudies
              caseStudyContent={post.content}
              navbarSwitch={navbarColorSwitch}
              setNavbarSwitch={setNavbarColorSwitch}
              footerCF={footer}
              popup={popup}
            />
          );
        }
      default:
        return (
          <Standard
            standardContent={post.content}
            navbarSwitch={navbarColorSwitch}
            setNavbarSwitch={setNavbarColorSwitch}
            footerCF={footer}
            popup={popup}
          />
        );
    }
  };
  if (router.isFallback) {
    return <div>Loading...</div>;
  } else {
    return (
      <>
        <Head>
          <title>
            {post?.pageSeo?.metatitle
              ? post.pageSeo.metatitle
              : post?.pageTitle
              ? post.pageTitle
              : globalSEO?.metatitle
              ? globalSEO.metatitle
              : ""}
          </title>
          <meta
            name="description"
            content={post?.pageSeo?.metadescription
              ? post.pageSeo.metadescription
              : globalSEO?.metadescription
              ? globalSEO.metadescription
              : ""}
          />
          <meta
            name="keywords"
            content={post?.pageSeo?.metakeywords
              ? post.pageSeo.metakeywords
              : globalSEO?.metakeywords
              ? globalSEO.metakeywords
              : ""}
          />
          <meta
            property="og:url"
            content={post?.pageUrl
              ? post.pageUrl
              : globalSEO?.ogurl
              ? globalSEO.ogurl
              : ""}
          />
          <link
            rel="shortcut icon"
            href={
              globalSEO?.favicon
                ? `${process.env.REACT_APP_IMG_MEDIA_SRC}${globalSEO.favicon}`
                : "favicon.ico"
            }
          />
          <meta
            property="og:type"
            content={globalSEO?.ogtype
              ? globalSEO.ogtype
              : ""}
            />
          <meta
            property="og:title"
            content={post?.pageSeo?.metatitle
              ? post.pageSeo.metatitle
              : post?.pageTitle
              ? post.pageTitle
              : globalSEO?.metatitle
              ? globalSEO.metatitle
              : ""}
          />
          <meta
            property="og:description"
            content={post?.pageSeo?.metadescription
              ? post.pageSeo.metadescription
              : globalSEO?.metadescription
              ? globalSEO.metadescription
              : ""}
          />
          <meta
            property="og:image"
            content={post?.pageSeo?.ogimage
              ? `${process.env.REACT_APP_IMG_MEDIA_SRC}${post.pageSeo.ogimage}`
              : globalSEO?.ogimage
              ? `${process.env.REACT_APP_IMG_MEDIA_SRC}${globalSEO.ogimage}`
              : ""}
          />
          <meta
            name="twitter:card"
            content={globalSEO?.ogtype
              ? globalSEO.ogtype
              : ""}
          />
            <meta
            property="twitter:title"
            content={post?.pageSeo?.metatitle
              ? post.pageSeo.metatitle
              : post?.pageTitle
              ? post.pageTitle
              : globalSEO?.metatitle
              ? globalSEO.metatitle
              : ""}
          />
          <meta
            name="twitter:description"
            content={post?.pageSeo?.metadescription
              ? post.pageSeo.metadescription
              : globalSEO?.metadescription
              ? globalSEO.metadescription
              : ""}
          />
          <meta
            name="twitter:image"
            content={post?.pageSeo?.ogimage
              ? `${process.env.REACT_APP_IMG_MEDIA_SRC}${post.pageSeo.ogimage}`
              : globalSEO?.ogimage
              ? `${process.env.REACT_APP_IMG_MEDIA_SRC}${globalSEO.ogimage}`
              : ""}
          />
        </Head>
        <Script
          id="usercentrics-cmp"
          src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
          data-version="preview"
          data-settings-id="rgcsZ9ijJ"
          async
        ></Script>
        <Script
          src={`https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}`}
        />
        <Script
          id="gtm-link"
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${
            globalSEO.seocode ?? "G-0YKNFK7Z3G"
          }`}
        />
        <Script id="gtm-data">{`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments)}
          gtag('js', new Date());
          gtag('config', '${globalSEO.seocode ?? "G-0YKNFK7Z3G"}');`}</Script>

        <LinkedInInsightTag />
        
        <Navbar data={header} navbarSwitch={navbarColorSwitch} />
        {componentSwitcher()}
      </>
    );
  }
}

export async function getStaticPaths() {
  const pathsServices = await fetch(process.env.NEXT_PUBLIC_FETCH_SRC, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      query: `query pathsQuery {
          pages {
          edges {
          node {
          slug
        }
          }
        }
      }`,
    }),
  });
  const caseStudiesPaths = await fetch(process.env.NEXT_PUBLIC_FETCH_SRC, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      query: `
        query privacyQuery {
          caseStudies {
            nodes {
              title
              slug
            }
          }
      }
        `,
    }),
  });
  const caseStudiesPathJson = await caseStudiesPaths.json();
  const fetchedPaths = await pathsServices.json();
  return {
    paths: fetchedPaths.data.pages.edges
      .map((item) => {
        return { params: { page: [item.node.slug] } };
      })
      .concat(
        caseStudiesPathJson.data.caseStudies.nodes.map((item) => {
          return { params: { page: ["case-studies"], id: [item.title] } };
        })
      ),
    fallback: true,
  };
}
export async function getStaticProps(context) {
  const pathsServices = await fetch(process.env.NEXT_PUBLIC_FETCH_SRC, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      query: `query titleQuery {
          pageBy(uri: "${context.params.page ? context.params.page[0] : "/"}") {
          title
          id
        }
      }`,
    }),
  });
  const caseStudiesServices = await fetch(process.env.NEXT_PUBLIC_FETCH_SRC, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      query: `query privacyQuery {
        caseStudies {
          nodes {
            title
            slug
          }
        }
      }`,
    }),
  });
  const pathsAll = await fetch(process.env.NEXT_PUBLIC_FETCH_SRC, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      query: `query titleQuery {
          pages{nodes {
          title
          id
        }}
      }`,
    }),
  });
  const allPaths = await pathsAll.json();
  const caseStudyIds = await caseStudiesServices.json();
  const fetchedPaths = await pathsServices.json();
  const data = async () => {
    if (fetchedPaths.data.pageBy) {
      switch (fetchedPaths.data.pageBy.title) {
        case "Services":
          const resServices = await fetch(process.env.NEXT_PUBLIC_FETCH_SRC, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              query: `
        query servicesPageQuery {
          caseStudies(first:3) {
            nodes {
              title
              slug
              caseStudiesCF {
                previewImage {
                  url
                  alt
                }
                discipline
              }
            }
          }
          caseStudiesSlug: page(id:"${
            allPaths.data.pages.nodes.filter(
              (item) => item.title === "Case Studies"
            )[0].id
          }") { 
            slug 
        }
          page(id:"${fetchedPaths.data.pageBy.id}") {
          pageSeoCF {
            metatitle
            metadescription
            metakeywords
            ogimage
          }
          title
          link
          servicesCF {
          introheadline
              challengestext
        companydescription
        expertisewrapper
        fieldGroupName
        graphdescription
        h3tiempos
        descriptionpicture {
          url
          alt
        }
        challengespicture {
          url
          alt
        }
        companypicture1 {
          url
          alt
        }
        companypicture2 {
          url
          alt
        }
        solvingissues
        ourservicesheadline
        secondarytext
        basicbutton {
          text
                url
                linktype
                topic
              }
        casebasicbutton {
          text
                url
                linktype
              }
        keypointflex {
          __typename
          ... on Page_Servicescf_Keypointflex_Item {
            title
            description
            areas
          }
        }
        subtitle
        header
        projects {
          projectdescription01 {
          name
                  description
        features {
          feature01 {
          name
                      description
        picture
        alt
                    }
        feature02 {
          name
                      description
        picture
        alt
                    }
        feature03 {
          name
                      description
        picture
        alt
                    }
                  }
        numbers {
          number01 {
          number
                      sign
        description
                    }
        number02 {
          number
                      sign
        description
                    }
                  }
                }
        projectdescription02 {
          name
                  description
        features {
          feature01 {
          name
                      description
        picture
        alt
                    }
        feature02 {
          name
                      description
        picture
        alt
                    }
        feature03 {
          name
                      description
        picture
        alt
                    }
                  }
        numbers {
          number01 {
          number
                      sign
        description
                    }
        number02 {
          number
                      sign
        description
                    }
                  }
                }
        projectdescription03 {
          name
                  description
        features {
          feature01 {
          name
                      description
        picture
        alt
                    }
        feature02 {
          name
                      description
        picture
        alt
                    }
        feature03 {
          name
                      description
        picture
        alt
                    }
                  }
        numbers {
          number01 {
          number
                      sign
        description
                    }
        number02 {
          number
                      sign
        description
                    }
                  }
                }
              }
        testimonialsflex {
          __typename
          ... on Page_Servicescf_Testimonialsflex_Item {
            quote
            author
          }
        }
        weare
        aboutheadline
        aboutbasicbutton {
          url
                text
                linkType
                topic
              }
        aboutcompanypicture1 {
          url
                alt
              }
        aboutcompanypicture2 {
          url
                alt
              }
        positionbanner {
          title
                url
        label
              }
            }
          }
          
        }

        `,
            }),
          });
          const jsonServices = await resServices.json();

          if(jsonServices.errors){
            console.error('gql error', jsonServices.errors)
          }

          return {
            services: jsonServices.data.page.servicesCF,
            pageSeo: jsonServices.data.page.pageSeoCF,
            pageTitle: jsonServices.data.page.title,
            pageUrl: jsonServices.data.page.link,
            caseStudies: jsonServices.data.caseStudies.nodes,
            caseStudiesSlug: jsonServices.data.caseStudiesSlug.slug,
          };
        case "Uber uns":
          const aboutUsRes = await fetch(process.env.NEXT_PUBLIC_FETCH_SRC, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              query: `
        query aboutUsPageQuery {
          page(id:"${fetchedPaths.data.pageBy.id}") {
            title
            link
          pageSeoCF {
            metatitle
            metadescription
            metakeywords
            ogimage
          }
          aboutUsCF {
          hellotitle
          helloimage1 {
            url
            alt
          }
        helloimage2 {
          url
          alt
        }
        description
        fieldGroupName
        header
        helloheader
        subtitle
        teamtitle
        featuresflex {
          __typename
          ... on Page_Aboutuscf_Featuresflex_Item {
            title
            description
          }
        }
        galleryflex {
          __typename
          ... on Page_Aboutuscf_Galleryflex_Image {
            url
            alt
          }
        }
        teamdescriptionheadline
        teamdescription
        number1 {
          number
              text
              suffix
            }
        number2 {
          number
              text
              suffix
            }
        number3 {
          number
              text
              suffix
            }
        job1
        teamimage1 {
          url
          alt
        }
        teamimage2 {
          url
          alt
        }
        valuestitle
        valuessubtitle
        burotitle
        burosubtitle
        burotext
        videourl
        buronumbers {
          item01 {
          number
                text
              }
        item02 {
          number
                text
              }
        item03 {
          number
                text
              }
        item04 {
          number
                text
              }
            }
        blogsubtitle
        blogtitle
        blogbutton {
          label
          link
          linktype
          topic
        }
        jobssubtext
        jobsheader
        jobsbutton {
          label
          link
          linktype
          topic
            }
            icons {
              logo {
                url
                alt
              }
              heart {
                url
                alt
              }
              drs {
                url
                alt
              }
            }
          }
        }
      }
        `,
            }),
          });
          const resJobs = await fetch(process.env.NEXT_PUBLIC_FETCH_SRC, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              query: `
        query jobsPageQuery {
          jobs {
            nodes {
              title
              jobsCF {
                linktype
                location
                url
              }
            }
          }
        }
        `,
            }),
          });
          const aboutUs = await aboutUsRes.json();
          const jobsRes = await resJobs.json();
          return {
            aboutUs: aboutUs.data.page.aboutUsCF,
            pageSeo: aboutUs.data.page.pageSeoCF,
            pageTitle: aboutUs.data.page.title,
            pageUrl: aboutUs.data.page.link,
            jobs: jobsRes.data.jobs.nodes,
          };
          case "Jobs":
            const allJobsRes = await fetch(process.env.NEXT_PUBLIC_FETCH_SRC, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                query: `
                  query allJobsPageQuery {
                    page(id:"${fetchedPaths.data.pageBy.id}") {
                      title
                      link
                      pageSeoCF {
                        metatitle
                        metadescription
                        metakeywords
                        ogimage
                      }
                      allJobsCF {
                        benefitstitle
                        benefitssubtitle
                        benefitsdescription
                        comment {
                          text
                            subtext
                        }
                        score {
                          number
                            text
                        }
                        benefits {
                          header
                            list
                        }
                        jobssubtext
                        jobsheader
                        jobsmessage
                        jobsbutton {
                          label
                          link
                          linktype
                          topic
                        }
                        mainheader
                      }
                    }
                  }
                  `,
              }),
            });
            const resAllJobs = await fetch(process.env.NEXT_PUBLIC_FETCH_SRC, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                query: `
                  query jobsPageQuery {
                    jobs {
                      nodes {
                        title
                        jobsCF {
                          linktype
                          location
                          url
                        }
                      }
                    }
                  }
                  `,
              }),
            });
            const jobsPage = await allJobsRes.json();
            const allJobsContentRes = await resAllJobs.json();
            return {
              jobsContent: jobsPage.data.page.allJobsCF,
              pageSeo: jobsPage.data.page.pageSeoCF,
              pageTitle: jobsPage.data.page.title,
              pageUrl: jobsPage.data.page.link,
              jobs: allJobsContentRes.data.jobs.nodes,
            };
          
          case "Imprint":
          const resImprint = await fetch(process.env.NEXT_PUBLIC_FETCH_SRC, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              query: `
        query privacyQuery {
          page(id:"${fetchedPaths.data.pageBy.id}") {
            title
            link
            pageSeoCF {
              metatitle
              metadescription
              metakeywords
              ogimage
            }
            standardPageCF{
              content
            } 
            seoCF{
              metatitle
              ogurl
              ogtype
              ogimage
            } 
        }
      }
        `,
            }),
          });
          const jsonImprint = await resImprint.json();
          return {
            content: jsonImprint.data.page.standardPageCF.content,
            pageTitle: jsonImprint.data.page.title,
            pageUrl: jsonImprint.data.page.link,
            SEO: jsonImprint.data.page.seoCF,
            pageSeo: jsonImprint.data.page.pageSeoCF,
          };
        case "Privacy":
          const resPrivacy = await fetch(process.env.NEXT_PUBLIC_FETCH_SRC, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              query: `
        query privacyQuery {
          page(id:"${fetchedPaths.data.pageBy.id}") {
            title
            link
            pageSeoCF {
              metatitle
              metadescription
              metakeywords
              ogimage
            }
            standardPageCF{
              content
            } 
          seoCF{
              metatitle
              ogurl
              ogtype
              ogimage
            }  
        }
      }
        `,
            }),
          });
          const jsonPrivacy = await resPrivacy.json();
          return {
            content: jsonPrivacy.data.page.standardPageCF.content,
            pageTitle: jsonPrivacy.data.page.title,
            pageUrl: jsonPrivacy.data.page.link,
            SEO: jsonPrivacy.data.page.seoCF,
            pageSeo: jsonPrivacy.data.page.pageSeoCF,
          };
        case "Contact":
          const resContact = await fetch(process.env.NEXT_PUBLIC_FETCH_SRC, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              query: `
        query privacyQuery {
          page(id:"${fetchedPaths.data.pageBy.id}") {
            title
            link
            pageSeoCF {
              metatitle
              metadescription
              metakeywords
              ogimage
            }
            standardPageCF{
              content
            } 
          seoCF{
              metatitle
              ogurl
              ogtype
              ogimage
            }
          contactCF{
            header
            subheadline
            headline
            description
            smalltext
            checkboxlabel
            buttonlabel
          }  
        }
      }
        `,
            }),
          });
          const jsonContact = await resContact.json();
          return {
            content: jsonContact.data.page.standardPageCF.content,
            pageTitle: jsonContact.data.page.title,
            pageUrl: jsonContact.data.page.link,
            contactCF: jsonContact.data.page.contactCF,
            SEO: jsonContact.data.page.seoCF,
            pageSeo: jsonContact.data.page.pageSeoCF,
          };
        case "Case Studies":
          if (context.params.page[1]) {
            if (
              caseStudyIds.data.caseStudies.nodes.find(
                (item) => item.slug === context.params.page[1]
              )
            ) {
              const resCaseStudies = await fetch(process.env.NEXT_PUBLIC_FETCH_SRC, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  query: `
                  query caseStudiesQuery {
                    page(id:"${fetchedPaths.data.pageBy.id}") {
                      title
                      link
                        standardPageCF{
                          content
                        } 
                        seoCF{
                            metatitle
                            ogurl
                            ogtype
                            ogimage
                          }  
                      }
                      caseStudies(where:{title: "${
                        caseStudyIds.data.caseStudies.nodes.find(
                          (item) => item.slug === context.params.page[1]
                        ).title
                      }"}) {
                        nodes {
                          title
                          id
                          link
                          pageSeoCF {
                            metatitle
                            metadescription
                            metakeywords
                            ogimage
                          }
                          caseStudiesCF {
                            previewImage {
                              url
                              alt
                            }
                            discipline
                            modules {
                              __typename
                              ... on Case_study_Casestudiescf_Modules_Header {
                                headline
                                disciplineTitle
                                contentLocked
                                caseReport {
                                  label
                                  file {
                                    mediaItemUrl
                                    fileSize
                                  }
                                }
                                mediaFlex {
                                  __typename
                                  ... on Case_study_Casestudiescf_Modules_Header_MediaFlex_Image {
                                    url
                                    alt
                                  }
                                  ... on Case_study_Casestudiescf_Modules_Header_MediaFlex_Video {
                                    url
                                    options {
                                      loop
                                      autoplay
                                      externalSource
                                    }
                                  }
                                  ... on Case_study_Casestudiescf_Modules_Header_MediaFlex_YoutubeVideo {
                                    url
                                  }
                                }
                                factsFlex {
                                  ... on Case_study_Casestudiescf_Modules_Header_FactsFlex_Item {
                                    title
                                    content
                                  }
                                }
                              }
                              ... on Case_study_Casestudiescf_Modules_AppLinks {
                                headline
                                appLink {
                                  url
                                  text
                                }
                                contentLocked
                                icons {
                                  google {
                                    link
                                    icon
                                  }
                                  appStore {
                                    link
                                    icon
                                  }
                                }
                              }
                              ... on Case_study_Casestudiescf_Modules_SimilarProjects {
                                subHeadline
                                headline
                                contentLocked
                                gobackbutton
                                similarCasesFlex {
                                  ... on Case_study_Casestudiescf_Modules_SimilarProjects_SimilarCasesFlex_SimilarCase {
                                    title
                                  }
                                }
                              }
                              ... on Case_study_Casestudiescf_Modules_ContactForm {
                                subHeadline
                                headline
                                description
                                smalltext
                                checkboxlabel
                                buttonlabel
                              }
                              ... on Case_study_Casestudiescf_Modules_ImageGallery {
                                contentLocked
                                imagesFlex {
                                  __typename
                                  ... on Case_study_Casestudiescf_Modules_ImageGallery_ImagesFlex_Image {
                                    url
                                    alt
                                    widescreen
                                  }
                                  ... on Case_study_Casestudiescf_Modules_ImageGallery_ImagesFlex_Video {
                                    url
                                    widescreen
                                    options {
                                      loop
                                      autoplay
                                      externalSource
                                    }
                                  }
                                  ... on Case_study_Casestudiescf_Modules_ImageGallery_ImagesFlex_YoutubeVideo {
                                    url
                                    widescreen
                                  }
                                }
                              }
                              ... on Case_study_Casestudiescf_Modules_FeatureDescriptions {
                                contentLocked
                                image {
                                  url
                                  alt
                                }
                                mediaFlex {
                                  __typename
                                  ... on Case_study_Casestudiescf_Modules_FeatureDescriptions_MediaFlex_Image {
                                    url
                                    alt
                                  }
                                  ... on Case_study_Casestudiescf_Modules_FeatureDescriptions_MediaFlex_Video {
                                    url
                                    options {
                                      loop
                                      autoplay
                                      externalSource
                                    }
                                  }
                                  ... on Case_study_Casestudiescf_Modules_FeatureDescriptions_MediaFlex_YoutubeVideo {
                                    url
                                  }
                                }
                                numbersInt {
                                  ... on Case_study_Casestudiescf_Modules_FeatureDescriptions_NumbersInt_Numbers {
                                    number
                                    sign
                                    description
                                  }
                                }
                                mirrored
                              }
                              ... on Case_study_Casestudiescf_Modules_ResponsiveNumbers {
                                contentLocked
                                mediaFlex {
                                  __typename
                                  ... on Case_study_Casestudiescf_Modules_ResponsiveNumbers_MediaFlex_Image {
                                    url
                                    alt
                                  }
                                  ... on Case_study_Casestudiescf_Modules_ResponsiveNumbers_MediaFlex_Video {
                                    url
                                    options {
                                      loop
                                      autoplay
                                      externalSource
                                    }
                                  }
                                  ... on Case_study_Casestudiescf_Modules_ResponsiveNumbers_MediaFlex_YoutubeVideo {
                                    url
                                  }
                                }
                                image {
                                  url
                                  alt
                                }
                                headline
                                text
                                numbersInt {
                                  ... on Case_study_Casestudiescf_Modules_ResponsiveNumbers_NumbersInt_Numbers {
                                    number
                                    sign
                                    description
                                  }
                                }
                                mirrored
                              }
                              ... on Case_study_Casestudiescf_Modules_BeforeAndAfter {
                                contentLocked
                                mediaFlex {
                                  __typename
                                  ... on Case_study_Casestudiescf_Modules_BeforeAndAfter_MediaFlex_Image {
                                    url
                                    alt
                                  }
                                  ... on Case_study_Casestudiescf_Modules_BeforeAndAfter_MediaFlex_Video {
                                    url
                                    options {
                                      loop
                                      autoplay
                                      externalSource
                                    }
                                  }
                                  ... on Case_study_Casestudiescf_Modules_BeforeAndAfter_MediaFlex_YoutubeVideo {
                                    url
                                  }
                                }
                                image {
                                  url
                                  alt
                                }
                                headline
                                text
                                mirrored
                              }
                              ... on Case_study_Casestudiescf_Modules_ResponsiveLayout {
                                contentLocked
                                headline
                                text
                                imagesFlex {
                                  __typename
                                  ... on Case_study_Casestudiescf_Modules_ResponsiveLayout_ImagesFlex_Image {
                                    url
                                    alt
                                    widescreen
                                  }
                                  ... on Case_study_Casestudiescf_Modules_ResponsiveLayout_ImagesFlex_Video {
                                    url
                                    widescreen
                                    options {
                                      loop
                                      autoplay
                                      externalSource
                                    }
                                  }
                                  ... on Case_study_Casestudiescf_Modules_ResponsiveLayout_ImagesFlex_YoutubeVideo {
                                    url
                                    widescreen
                                  }
                                }
                                mirrored
                              }
                              ... on Case_study_Casestudiescf_Modules_Strategy {
                                subHeadline
                                headline
                                contactText
                                buttonText
                                descriptionPointsFlex {
                                  __typename
                                  ... on Case_study_Casestudiescf_Modules_Strategy_DescriptionPointsFlex_Text {
                                    content
                                  }
                                  ... on Case_study_Casestudiescf_Modules_Strategy_DescriptionPointsFlex_TextBox {
                                    headline
                                    text
                                    light
                                  }
                                  ... on Case_study_Casestudiescf_Modules_Strategy_DescriptionPointsFlex_Icon {
                                    type
                                  }
                                }
                                lockedDescriptionFlex {
                                  __typename
                                  ... on Case_study_Casestudiescf_Modules_Strategy_LockedDescriptionFlex_Text {
                                    content
                                  }
                                  ... on Case_study_Casestudiescf_Modules_Strategy_LockedDescriptionFlex_TextBox {
                                    headline
                                    text
                                    light
                                  }
                                  ... on Case_study_Casestudiescf_Modules_Strategy_LockedDescriptionFlex_Icon {
                                    type
                                  }
                                }
                                keyPoints {
                                  ... on Case_study_Casestudiescf_Modules_Strategy_KeyPoints_KeyPoint {
                                    title
                                    content
                                    mediaFlex {
                                      __typename
                                      ... on Case_study_Casestudiescf_Modules_Strategy_KeyPoints_KeyPoint_MediaFlex_Image {
                                        url
                                        alt
                                        widescreen
                                      }
                                      ... on Case_study_Casestudiescf_Modules_Strategy_KeyPoints_KeyPoint_MediaFlex_Video {
                                        url
                                        widescreen
                                        options {
                                          loop
                                          autoplay
                                          externalSource
                                        }
                                      }
                                      ... on Case_study_Casestudiescf_Modules_Strategy_KeyPoints_KeyPoint_MediaFlex_YoutubeVideo {
                                        url
                                        widescreen
                                      }
                                    }
                                    images {
                                      ... on Case_study_Casestudiescf_Modules_Strategy_KeyPoints_KeyPoint_Images_Image {
                                      url
                                      alt
                                      widescreen
                                      }
                                    }
                                  }
                                }
                              }
                              ... on Case_study_Casestudiescf_Modules_BackgroundInformation {
                                contentLocked
                                subHeadline
                                headline
                                keyPoints {
                                  ... on Case_study_Casestudiescf_Modules_BackgroundInformation_KeyPoints_KeyPoint {
                                    title
                                    content
                                    keywords
                                    numbersFlex {
                                      ... on Case_study_Casestudiescf_Modules_BackgroundInformation_KeyPoints_KeyPoint_NumbersFlex_Numbers {
                                        number
                                        sign
                                        description
                                      }
                                    }
                                    mediaFlex {
                                      __typename
                                      ... on Case_study_Casestudiescf_Modules_BackgroundInformation_KeyPoints_KeyPoint_MediaFlex_Image {
                                        url
                                        alt
                                        widescreen
                                      }
                                      ... on Case_study_Casestudiescf_Modules_BackgroundInformation_KeyPoints_KeyPoint_MediaFlex_Video {
                                        url
                                        widescreen
                                        options {
                                          loop
                                          autoplay
                                          externalSource
                                        }
                                      }
                                      ... on Case_study_Casestudiescf_Modules_BackgroundInformation_KeyPoints_KeyPoint_MediaFlex_YoutubeVideo {
                                        url
                                        widescreen
                                      }
                                    }
                                    images {
                                      ... on Case_study_Casestudiescf_Modules_BackgroundInformation_KeyPoints_KeyPoint_Images_Image {
                                      url
                                      alt
                                      widescreen
                                      }
                                    }
                                  }
                                }
                              }
                              ... on Case_study_Casestudiescf_Modules_ModularImageCenter {
                                contentLocked
                                image {
                                  url
                                  alt
                                }
                                mediaFlex {
                                  __typename
                                  ... on Case_study_Casestudiescf_Modules_ModularImageCenter_MediaFlex_Image {
                                    url
                                    alt
                                  }
                                  ... on Case_study_Casestudiescf_Modules_ModularImageCenter_MediaFlex_Video {
                                    url
                                    options {
                                      loop
                                      autoplay
                                      externalSource
                                    }
                                  }
                                  ... on Case_study_Casestudiescf_Modules_ModularImageCenter_MediaFlex_YoutubeVideo {
                                    url
                                  }
                                }
                                bottomText
                                leftSide {
                                  __typename
                                  ... on Case_study_Casestudiescf_Modules_ModularImageCenter_LeftSide_TextBox {
                                    headline
                                    content
                                    light
                                  }
                                  ... on Case_study_Casestudiescf_Modules_ModularImageCenter_LeftSide_Text {
                                    content
                                  }
                                }
                                rightSide {
                                  __typename
                                  ... on Case_study_Casestudiescf_Modules_ModularImageCenter_RightSide_TextBox {
                                    headline
                                    content
                                    light
                                  }
                                  ... on Case_study_Casestudiescf_Modules_ModularImageCenter_RightSide_Text {
                                    content
                                  }
                                }
                                mirrored
                              }
                              ... on Case_study_Casestudiescf_Modules_CaseOutcome {
                                contentLocked
                                subHeadline
                                headline
                                contactText
                                button {
                                  text
                                }
                                keyPoints {
                                  ... on Case_study_Casestudiescf_Modules_CaseOutcome_KeyPoints_KeyPoint {
                                    title
                                  }
                                }
                              }
                              ... on Case_study_Casestudiescf_Modules_CaseOverview {
                                contentLocked
                                testimonial
                                author
                                image {
                                  url
                                  alt
                                }
                                mediaFlex {
                                  __typename
                                  ... on Case_study_Casestudiescf_Modules_CaseOverview_MediaFlex_Image {
                                    url
                                    alt
                                  }
                                  ... on Case_study_Casestudiescf_Modules_CaseOverview_MediaFlex_Video {
                                    url
                                    options {
                                      loop
                                      autoplay
                                      externalSource
                                    }
                                  }
                                  ... on Case_study_Casestudiescf_Modules_CaseOverview_MediaFlex_YoutubeVideo {
                                    url
                                  }
                                }
                              }
                              ... on Case_study_Casestudiescf_Modules_ProjectTask {
                                contentLocked
                                headline
                                subHeadline
                                testimonial
                                author
                                mediaFlex {
                                  __typename
                                  ... on Case_study_Casestudiescf_Modules_ProjectTask_MediaFlex_Image {
                                    url
                                    alt
                                    widescreen
                                  }
                                  ... on Case_study_Casestudiescf_Modules_ProjectTask_MediaFlex_Video {
                                    url
                                    widescreen
                                    options {
                                      loop
                                      autoplay
                                      externalSource
                                    }
                                  }
                                  ... on Case_study_Casestudiescf_Modules_ProjectTask_MediaFlex_YoutubeVideo {
                                    url
                                    widescreen
                                  }
                                }
                                images {
                                  image1 {
                                    url
                                    alt
                                    widescreen
                                  }
                                  image2 {
                                    url
                                    alt
                                    widescreen
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    similarProjects:caseStudies(first: 2) {
                      nodes {
                        title
                        slug
                        caseStudiesCF {
                          previewImage {
                            url
                            alt
                          }
                          discipline
                        }
                      }
                    }
                  }
                 `,
                }),
              });
              const jsonCaseStudies = await resCaseStudies.json();
              return {
                content: {
                  cases: jsonCaseStudies.data.caseStudies.nodes,
                  similarCases: jsonCaseStudies.data.similarProjects.nodes,
                },
                SEO: jsonCaseStudies.data.page.seoCF,
                pageSeo: jsonCaseStudies.data.caseStudies.nodes[0].pageSeoCF,
                pageTitle: jsonCaseStudies.data.caseStudies.nodes[0].title,
                pageUrl: jsonCaseStudies.data.caseStudies.nodes[0].link,
              };
            } else {
              return null;
            }
          } else {
            const resCaseStudies = await fetch(process.env.NEXT_PUBLIC_FETCH_SRC, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                query: `
        query privacyQuery {
          caseStudies(first: 50) {
            nodes {
              title
              slug
              caseStudiesCF {
                previewImage {
                  url
                  alt
                }
                discipline
              }
            }
          }
          page(id:"${fetchedPaths.data.pageBy.id}") {
            title
            link
          standardPageCF{
            content
          } 
          seoCF{
              metatitle
              ogurl
              ogtype
              ogimage
            }  
        }
          testimonials: page(id:"${
            allPaths.data.pages.nodes.filter(
              (item) => item.title === "Services"
            )[0].id
          }") { 
            title
            link
            servicesCF{
            testimonialsflex {
              __typename
              ... on Page_Servicescf_Testimonialsflex_Item {
                quote
                author
              }
            }
            }  
        }
      }
        `,
              }),
            });
            const jsonCaseStudies = await resCaseStudies.json();
            return {
              content: {
                cases: jsonCaseStudies.data.caseStudies.nodes,
                testimonials:
                  jsonCaseStudies.data.testimonials.servicesCF.testimonialsflex,
                headline: jsonCaseStudies.data.page.standardPageCF.content,
              },
              SEO: jsonCaseStudies.data.page.seoCF,
              pageTitle: jsonCaseStudies.data.page.title,
              pageUrl: jsonCaseStudies.data.page.link,
            };
          }
        default:
          const resStandard = await fetch(process.env.NEXT_PUBLIC_FETCH_SRC, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              query: `
        query standardQuery {
          page(id:"${fetchedPaths.data.pageBy.id}") {
            title
            link
            pageSeoCF {
              metatitle
              metadescription
              metakeywords
              ogimage
            }
            standardPageCF{
              content
            } 
          seoCF{
              metatitle
              ogurl
              ogtype
              ogimage
            }  
        }
      }
        `,
            }),
          });
          const jsonStandard = await resStandard.json();
          return {
            content: jsonStandard.data.page.standardPageCF.content,
            pageTitle: jsonStandard.data.page.title,
            pageUrl: jsonStandard.data.page.link,
            SEO: jsonStandard.data.page.seoCF,
            pageSeo: jsonStandard.data.page.pageSeoCF,
          };
      }
    } else {
      return null;
    }
  };

  const resHeader = await fetch(process.env.NEXT_PUBLIC_FETCH_SRC, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      query: `
      query servicesUnsPageQuery {
        pages(where: {name: "Header"}) {
          nodes {
            headerCF {
              visibility
              button {
                label
                url
                linktype
                topic
              }
              linksflex {
                __typename
                ... on Page_Headercf_Linksflex_Item {
                  label
                  url
                  linktype
                }
              }
              linktext
              linkurl
              mobileemail {
                url
                text
                topic
              }
              logo {
                image
                alt
                url
              }
              message
            }
          }
        }
      }
        `,
    }),
  });

  const resFooter = await fetch(process.env.NEXT_PUBLIC_FETCH_SRC, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      query: `
        query servicesUnsPageQuery {
          pages(where: {name: "Footer"}) {
          nodes {
          footerCF {
          catchphrase
              basicbutton {
          url
                text
                linktype
                topic
              }
        contactsection {
          title
                address1
        address2
        phone {
          url
                  text
                }
        email {
          url
                  text
                }
              }
        socialmedia {
          text
                url
        target
        referrer
              }
        menu {
          title
          linksflex {
            __typename
            ... on Page_Footercf_Menu_Linksflex_Item {
              label
              url
              linktype
            }
          }
        }
        disclaimer
        bottomlink {
          item1 {
          url
                  text
                }
        item2 {
          url
                  text
                }
              }
        copyright {
          item1
                item2
              }
        logo {
                image
                alt
                url
                linktype
              }
            }
          }
        }
      }
        `,
    }),
  });
  const resSEO = await fetch(process.env.NEXT_PUBLIC_FETCH_SRC, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      query: `
        query servicesUnsPageQuery {
          pages(where: {name: "SEO"}) {
          nodes {
          seoCF {
              seocode
              favicon
              metatitle
              metadescription
              metakeywords
              ogurl
              ogtype
              ogimage
            }
          }
        }
      }
        `,
    }),
  });

  const uriAll = context.params.page
    ? `/${context.params.page.join("/")}`
    : "/services";
  const resPopup = await fetch(process.env.NEXT_PUBLIC_FETCH_SRC, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      query: `
      query Popup {
        contentNode(id:"${uriAll}", idType: URI) {
          ... on Page {
            uri
            pupupCF {
              popupHeadline
              popupContent
              popupIcon
              popupOptions {
                enabled
                delay
              }
          popupButton {
                ... on ContentNode_Pupupcf_PopupButton_Button {
                  url
                  text
                }
              }
            }
          }
          ... on Post {
            uri
            pupupCF {
              popupHeadline
              popupContent
              popupIcon
              popupOptions {
                enabled
                delay
              }
          popupButton {
                ... on ContentNode_Pupupcf_PopupButton_Button {
                  url
                  text
                }
              }
            }
          }
          ... on Case_study {
            uri
            pupupCF {
              popupHeadline
              popupContent
              popupIcon
              popupOptions {
                enabled
                delay
              }
          popupButton {
                ... on ContentNode_Pupupcf_PopupButton_Button {
                  url
                  text
                }
              }
            }
          }
        }
      }
      `,
    }),
  });
  const jsonPopup = await resPopup.json();
  const res = await data();
  if (res === null) {
    return { notFound: true, revalidate: 60 };
  }
  const jsonFooter = await resFooter.json();
  const jsonSEO = await resSEO.json();
  const jsonHeader = await resHeader.json();

  return {
    // Passed to the page component as props
    props: {
      type: fetchedPaths.data.pageBy ? fetchedPaths.data.pageBy.title : "404",
      id:
        context.params.page && context.params.page.length > 1
          ? context.params.page[1]
          : null,
      post: res,
      header: jsonHeader.data.pages.nodes[0].headerCF,
      footer: jsonFooter.data.pages.nodes[0].footerCF,
      globalSEO: jsonSEO.data.pages.nodes[0].seoCF,
      popup: jsonPopup.data.contentNode.pupupCF,
    },
    revalidate: 60,
  };
}
