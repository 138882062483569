import styled from "styled-components";
import { ContinuousText, H2 } from "../../../../styles/Text.styles";
import { MainGridWrapper } from "../../../../styles/Main.styles";
import { deviceSizes } from "../../../../styles/deviceSizes";
import { colors } from "../../../../styles/colors";

export const MainWrapper = styled(MainGridWrapper)`
  margin-top: 25px;
  margin-bottom: 25px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    margin-top: 55px;
    margin-bottom: 55px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-top: 55px;
    margin-bottom: 55px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 235px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    width: auto;
  }
`;

export const SubHeadline = styled(ContinuousText)`
  grid-column: 1/3;
  margin-bottom: 10px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/9;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: 1/5;
    margin-bottom: 12px;
  }
`;

export const ShortDescription = styled.div`
  grid-column-start: span 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  h3 {
    font-family: "Neue Haas Grotesk Display Pro";
    font-style: normal;
    font-weight: 450;
    font-size: 22px;
    line-height: 28px;
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column-start: span 8;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    h3 {
      font-size: 28px;
      line-height: 38px;
    }
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-template-columns: 3fr 2fr 3fr;
    grid-auto-flow: row;
    h3 {
      font-size: 36px;
      line-height: 48px;
    }
  }
  @media only screen and (min-width: ${deviceSizes.max_desktop}) {
  }
`;

export const LockedContent = styled(ShortDescription)`
  margin-top: 40px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    margin-top: 60px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-top: 75px;
  }
`;

export const TextContainer = styled(ContinuousText)`
  grid-column-start: span 2;
  & > h3 {
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    margin-block: 0;
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    & > h3 {
      font-size: 28px;
      line-height: 38px;
    }
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    & > h3 {
      font-size: 36px;
      line-height: 48px;
    }
    grid-column-start: span 3;
  }
`;

export const Headline = styled(H2)`
  grid-column: 1/3;
  margin-bottom: 15px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/13;
    margin-bottom: 40px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-bottom: 65px;
  }
`;

export const ContactSection = styled.div`
  grid-column: 1/3;
  margin-bottom: 40px;
  display: grid;
  align-content: start;
  grid-row-gap: 30px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/5;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: 1/4;
    grid-row-gap: 50px;
  }
`;

export const FlexibleGrid = styled.div`
  grid-column: 1/3;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 5/13;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-row-gap: 50px;
  }
`;

export const KeyPointContainer = styled.div`
  grid-column: 1/3;
  margin-top: 40px;
  margin-bottom: ${({ unlocked }) => (unlocked ? "15px" : "0")};
  padding-top: 15px;
  border-top: 1px solid ${colors.anthrazit};
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: ${({ unlocked }) => (unlocked ? "1/13" : "5/13")};
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-row-gap: 50px;
  }
`;
