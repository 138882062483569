import { styled as styledMui } from "@mui/material/styles";
import styled from "styled-components";
import { Button } from "@mui/material";
import { colors } from "../../../styles/colors";
import { deviceSizes } from "../../../styles/deviceSizes";

export const StyledButton = styledMui(Button)(({ theme, small, autoSize }) => ({
  padding: "0px",
  borderRadius: "120px",
  height: small ? "40px" : (autoSize ? '100%' : "50px"),
  width: small ? "40px" : (autoSize ? '100%' : "50px"),
  "&:hover": {
    backgroundColor: colors.anthrazit,
  },
  boxShadow: "none",
  [theme.breakpoints.up("1181")]: {
    height: small ? "45px" : (autoSize ? '100%' : "95px"),
    width: small ? "45px" : (autoSize ? '100%' : "95px"),
  },
}));

export const ArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    width: 60%;
  }
`;
