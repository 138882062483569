import { useState } from "react";
import { ArrowButton } from "../../../common/ArrowButton/ArrowButton.component";
import {
  Author,
  RightButton,
  MainWrapper,
  Testimonial,
  ButtonsWrapper,
} from "./Testimonials.styled";

export const Testimonials = ({ testimonials, caseStudyVersion = false }) => {
  const [index, setIndex] = useState(0);
  return (
    testimonials.length > 0 && (
      <MainWrapper caseStudyVariant={caseStudyVersion}>
        <Testimonial>
          <i>{testimonials[index].quote}</i>
        </Testimonial>
        <Author>{testimonials[index].author}</Author>
        {testimonials.length > 1 && (
          <ButtonsWrapper>
            <ArrowButton
              small
              onClick={() => index > 0 && setIndex(index - 1)}
            />
            <RightButton>
              <ArrowButton
                small
                onClick={() =>
                  index < testimonials.length - 1 && setIndex(index + 1)
                }
              />
            </RightButton>
          </ButtonsWrapper>
        )}
      </MainWrapper>
    )
  );
};
