import {
  ButtonWrapper,
  CatchphraseText,
  FooterWrapper,
  ContactInfo,
  ContactSection,
  ContactItem,
  FooterItemContainer,
  FooterMenu,
  FooterItem,
  StudioDisclaimer,
  LogoIcon,
  BottomLinksWrapper,
  BottomLink,
  Copyright,
  Contact,
  ArrowWrapper,
  SectionHeader,
  MenuWrapper,
} from "./Footer.styles";
import { BasicButton } from "../BasicButton/BasicButton.component";
import { Arrow } from "../Arrow/Arrow.component";
import { ContinuousText } from "../../../styles/Text.styles";
import { colors } from "../../../styles/colors";
import Link from "next/link";
import { useEffect } from "react";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { gsap } from "gsap";
import { useRef } from "react";

export const Footer = (props) => {
  gsap.registerPlugin(ScrollTrigger);
  const footerRef = useRef();
  const testFunc = () => {
    if (footerRef.current) {
      const value = ScrollTrigger.positionInViewport("#footer", "-80px");
      if (value <= 0 && props.navbarSwitch === false) {
        props.setNavbarSwitch(true)
        return;
      }
      else if (value > 0) {
        props.setNavbarSwitch(false)
        return;
      }
      return;
    }
  };

  useEffect(() => {
    ScrollTrigger.create({
      start: 0,
      end: "bottom",
      onUpdate: testFunc,
    });
  }, []);

  return (
    <FooterWrapper ref={footerRef} id="footer">
      <Contact>
        <CatchphraseText
          dangerouslySetInnerHTML={{
            __html: props.catchphrase,
          }}
        />
        <ButtonWrapper>
          <BasicButton
            href={props.basicButtonUrl}
            label={props.basicButtonText}
            urlType={props.basicButtonUrlType}
            mailSubject={props.basicButtonSubject}
          />
        </ButtonWrapper>
      </Contact>
      <MenuWrapper>
        <ContactSection>
          <SectionHeader>{props.contactsectionTitle}</SectionHeader>
          <ContactInfo>
            <ContactItem>
              <ContinuousText>{props.contactsectionAddress1}</ContinuousText>
              <ContinuousText>{props.contactsectionAddress2}</ContinuousText>
            </ContactItem>
            <ContactItem clickable>
              <Link href={props.contactsectionPhoneUrl} prefetch={false}>
                <ContinuousText>{props.contactsectionPhoneText}</ContinuousText>
              </Link>
              <Link href={props.contactsectionEmailUrl} prefetch={false}>
                <ContinuousText>{props.contactsectionEmailText}</ContinuousText>
              </Link>
            </ContactItem>
            <ContactItem clickable>
              <a
                href={props.socialmediaUrl}
                target={props.socialmediaTarget}
                rel={props.socialmediaReferral}
              >
                <ContinuousText>{props.socialmediaText}</ContinuousText>
              </a>
            </ContactItem>
          </ContactInfo>
        </ContactSection>
        <FooterMenu>
          <SectionHeader>{props.menuTitle}</SectionHeader>
          <FooterItemContainer>
            {props.menuLinks.map((link, index) => (<FooterItem key={index}>{link.linktype === "Internal" ? (<Link prefetch={false} href={link.url}>
              <ContinuousText>{link.label}</ContinuousText>
            </Link>) : (<a href={link.url} rel="noreferrer" target="_blank">
              <ContinuousText>{link.label}</ContinuousText>
            </a>)}</FooterItem>

            ))}
          </FooterItemContainer>
        </FooterMenu>
      </MenuWrapper>
      <StudioDisclaimer
        dangerouslySetInnerHTML={{
          __html: props.disclaimer,
        }}
      />
      <Link href={props.logo.url ? props.logo.url : "/"}>
        <LogoIcon src={process.env.REACT_APP_IMG_MEDIA_SRC + props.logo.image} loading={"lazy"} alt={props.logo.alt} />
      </Link>
      <BottomLinksWrapper>
        <BottomLink>
          <Link href={props.bottomLinkItem1Url} prefetch={false}>
            <ContinuousText>{props.bottomLinkItem1Text}</ContinuousText>
          </Link>
        </BottomLink>
        <BottomLink>
          <Link href={props.bottomLinkItem2Url} prefetch={false}>
            <ContinuousText>{props.bottomLinkItem2Text}</ContinuousText>
          </Link>
        </BottomLink>

      </BottomLinksWrapper>
      <Copyright>
        <ContinuousText>{props.copyright1}</ContinuousText>
        <ContinuousText>{props.copyright2} - v{process.env.DRS_VERSION}.{process.env.GITCOMMITCOUNT} ({process.env.DRS_ENV}-{process.env.GITHASH})</ContinuousText>
      </Copyright>
      <ArrowWrapper
        onClick={() => document.body.scrollIntoView({ behavior: "smooth" })}
      >
        <Arrow color={colors.weis} />
      </ArrowWrapper>
    </FooterWrapper>
  );
};
