import {
  SubHeadline,
  Headline,
  MainWrapper,
  PreviewWrapper,
  GoBackButton,
} from "./SimilarProjects.styles";
import { PreviewGrid } from "../../Overview/PreviewGrid/PreviewGrid.component";
import { H3 } from "../../../../styles/Text.styles";
import { removeParagraphs } from "../../../../helpers";
import Link from "next/link";
import { useEffect, useState } from "react";

export const SimilarProjects = ({
  cases,
  headline,
  subHeadline,
  buttonText,
  similarCases,
}) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (similarCases.length) {
      fetch(process.env.NEXT_PUBLIC_FETCH_SRC, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          query: `
        query standardQuery {
          ${similarCases.map(
            (item, index) => `case${index}:
            caseStudies(where:{title: "${item.title}"}) {
            nodes {
              title
              slug
              caseStudiesCF {
                previewImage {
                  url
                  alt
                }
                discipline
              }
            }
          }`
          )}
        }
        `,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) =>
          setData(
            [
              data.data.case0 && data.data.case0.nodes[0],
              data.data.case1 && data.data.case1.nodes[0],
            ].filter((el) => el !== undefined)
          )
        );
    }
  }, []);
  return (
    <MainWrapper>
      {data.length > 0 && (
        <>
          <SubHeadline>{subHeadline}</SubHeadline>
          <Headline
            dangerouslySetInnerHTML={{
              __html: headline,
            }}
          />
        </>
      )}
      <PreviewWrapper>
        <PreviewGrid cases={data} noPadding customSlug={"/case-studies"} />
      </PreviewWrapper>
      <Link href="/case-studies">
        <GoBackButton>{buttonText}</GoBackButton>
      </Link>
    </MainWrapper>
  );
};
