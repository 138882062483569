import { useWindowSize } from "../../../../../hooks/useWindowSize";
import { BasicButton } from "../../../../common/BasicButton/BasicButton.component";
import {
  MainWrapper,
  DownloadIcon,
  ButtonContentWrapper,
} from "./DownloadCaseReport.styles";

export const DownloadCaseReport = ({
  unlocked,
  label,
  fileSize,
  url,
  isUserRegistered
}) => {
  const { isMobile } = useWindowSize();
  const roundedFileSize = fileSize ? (fileSize / 1024 / 1024).toFixed(2) : null;
  const shouldLinkToContactForm = !unlocked || (!isUserRegistered )
  return (
    <>
      {url && (
        <MainWrapper>
            {shouldLinkToContactForm ? <BasicButton
                  type="downloadPDF"
                  label={
                    <ButtonContentWrapper href={'#contact'} target="_self">
                <span>{`${label} ${
                    fileSize && !isMobile ? `(${roundedFileSize} MB)` : ""
                }`}</span>
                      <DownloadIcon />
                    </ButtonContentWrapper>
                  }
              /> : (
              <BasicButton
              type="downloadPDF"
              label={
                <ButtonContentWrapper href={url} target="_blank" download>
                  <span>{`${label} ${
                    fileSize && !isMobile ? `(${roundedFileSize} MB)` : ""
                  }`}</span>
                  <DownloadIcon />
                </ButtonContentWrapper>
              }
            />
              
          )}
        </MainWrapper>
      )}
    </>
  );
};
