import { Select } from "@mui/material";
import { styled } from "@mui/material/styles";
import { colors } from "../../../styles/colors";

export const StyledSelect = styled(Select)({
  backgroundColor: colors.weis,
  "& fieldset": {
    borderColor: colors.anthrazit,
  },
  "&.Mui-focused fieldset": {
    borderColor: `${colors.anthrazit} !important`,
  },
  "& .MuiInputBase-root": {},
  "& .Mui-error": {
    color: colors.errorRot,
    "& fieldset": { borderColor: `${colors.errorRot} !important` },
  },
  "& .MuiOutlinedInput-root": {
    "&.MuiOutlinedInput-notchedOutline": {
      "&.Mui-focused fieldset": {
        borderColor: `${colors.anthrazit}!important`,
      },
    },
  },
});
