import { ArrowButton } from "../../../../common/ArrowButton/ArrowButton.component";
import {
  ButtonWrapper,
  CaseImageWrapper,
  CaseItemWrapper,
  ImageHover,
  StyledH2,
  TextContainer,
  DescriptionWrapper,
  Title,
  Professions,
  LoaderWrapper,
} from "./CaseGridItem.styles";
import { colors } from "../../../../../styles/colors";
import { useRouter } from "next/router";

export const CaseGridItem = ({ data, customSlug }) => {
  const router = useRouter();
  return (
    <CaseItemWrapper href={`${customSlug ? customSlug : router.asPath}/${data.slug}`}>
      <LoaderWrapper>
        <CaseImageWrapper
          img={process.env.REACT_APP_IMG_MEDIA_SRC + data.caseStudiesCF.previewImage.url}
        >
          <ImageHover>
            <TextContainer>
              <ButtonWrapper>
                <ArrowButton color={colors.akzentGrun} right={true} autosize={true} />
              </ButtonWrapper>
              <StyledH2>
                Jetzt <i>Case</i> ansehen
              </StyledH2>
            </TextContainer>
          </ImageHover>
        </CaseImageWrapper>
      </LoaderWrapper>
      <DescriptionWrapper>
        <Title>{data.title}</Title>
        {!data.caseStudiesCF.discipline.includes("None") && (
          <Professions>{data.caseStudiesCF.discipline.join(", ")}</Professions>
        )}
      </DescriptionWrapper>
    </CaseItemWrapper>
  );
};
