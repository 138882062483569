import { StyledTextField } from "./Input.styles";

export const Input = (props) => {
  return (
    <StyledTextField
      {...props}
      FormHelperTextProps={{
        sx: { fontFamily: [`"Neue Haas Grotesk Display Pro", sans-serif`] },
      }}
    />
  );
};
