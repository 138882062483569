import styled from "styled-components";
import { colors } from "../../../styles/colors";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${({ side }) => (side ? 'row-reverse' : "column")};
  justify-content: end;
  align-items: center;
  height: ${({ side }) => (side ? "7px" : "100%")};
  width: ${({ side }) => (side ? "100%" : "7px")};
  transform: ${({ right }) => (right ? 'rotate(180deg)' : 'none')};
`;

export const ArrowHead = styled.div`
  width: 7px;
  height: 7px;
  border: ${({ color }) =>
    color ? `solid ${color}` : `solid ${colors.anthrazit}`};
  border-width: ${({ slim }) =>
    slim ? `0 1px 1px 0;` : `0 1.5px 1.5px 0;`};
  transform: ${({ side }) => (side ? "rotate(135deg)" : "rotate(45deg) translateX(5px)")}; ;
`;
export const ArrowHeadWrapper = styled.div
  `
  transform: ${({ side }) => (side ? "translate(7px,0.5px)" : "translate(-3px,-10px)")};
  width: 8px;
  height: 8px;
`

export const ArrowBase = styled.div`
  height: ${({ side, slim }) => (side ? (slim ? "1px" : "1.5px") : "100%")};
  width: ${({ side, slim }) => (side ? "100%" : (slim ? "1px" : "1.5px"))};
  background-color: ${({ color }) =>
    color ? `${color}` : `${colors.anthrazit}`};
`;
