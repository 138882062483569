import styled from "styled-components";
import { ContinuousText, H2 } from "../../../../styles/Text.styles";
import { deviceSizes } from "../../../../styles/deviceSizes";

export const MainWrapper = styled.div`
  grid-column: 1/3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 75px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/13;
    min-height: ${({ caseStudyVariant }) => (caseStudyVariant ? "0" : "265px")};
    margin-bottom: 120px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    min-height: ${({ caseStudyVariant }) => (caseStudyVariant ? "0" : "354px")};
    margin-bottom: 155px;
  }
`;

export const Testimonial = styled(H2)`
  text-align: center;
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: 2/12;
  }
`;

export const Author = styled(ContinuousText)`
  margin-top: 20px;
  text-align: center;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    margin-top: 27px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-top: 30px
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  gap: 45px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    margin-top: 28px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-top: 45px;
    gap: 40px;
  }
`;

export const RightButton = styled.div`
  transform: rotate(180deg);
`;
