import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { colors } from "../../../styles/colors";

export const StyledTextField = styled(TextField)({
  width: "100%",
  minHeight: "66px",
  "& .MuiInputBase-root": {
    backgroundColor: colors.weis,
  },
  "& .Mui-error": {
    color: colors.errorRot,
    "& fieldset": { borderColor: `${colors.errorRot} !important` },
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: colors.anthrazit,
    },
    "&.Mui-focused fieldset": {
      borderColor: colors.anthrazit,
    },
  },
});
