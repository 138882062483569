import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { colors } from "../../../styles/colors";
export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "customPadding",
})(({ secondary, theme, type }) => ({
  color: secondary ? `${colors.anthrazit}` : `${colors.weis}`,
  border: secondary ? `1px solid ${colors.anthrazit}` : "none",
  borderRadius: "120px",
  padding: "10.5px 30px",
  minWidth: "180px",
  textTransform: "none",
  width: "100%",
  minWidth: "0px",
  backgroundColor: secondary ? `${colors.transparent}` : `${colors.akzentGrun}`,
  
  "&.Mui-disabled": {
    border: `1px solid ${colors.textInactive}`,
  },
  [theme.breakpoints.between("719", "1161")]: {
    padding: "12px 30px",
    minWidth: "225px",
  },
  [theme.breakpoints.up("0")]: {"&:hover": {
    backgroundColor: secondary
      ? `${colors.anthrazit}`
      : `${colors.buttonHoverGrun}`,
    color: `${colors.weis}`,
  },
   padding: "18px 30px", minWidth: "300px" },
  [theme.breakpoints.between("320", "719")]: {
    padding: type === "downloadPDF" ? "5px 15px" : "18px 30px",
  },
}));
