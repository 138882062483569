import {
  Areas,
  Description,
  Title,
  Line,
  CloseButton,
  Number,
  OpenButton,
  Wrapper,
  TitleWrapper,
  TabWrapper,
  ContentWrapper,
  ImageContainer,
  StrategyImage,
  NumbersWrapper,
  StyledSwiper,
  NumbersItemWrapper,
  NumberCounter,
  KeywordsWrapper,
  VideoWrapper,
} from "./KeyPoint.styles";
import { removeParagraphs } from "../../../../../helpers";
import { ContinuousText, H3 } from "../../../../../styles/Text.styles";
import { SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
import { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { animateCounters } from "../../../../../helpers";
import "swiper/css";
import "swiper/css/pagination";
import { Video } from "../../../../common/Video/Video.component";

export const KeyPoint = ({
  open,
  unlocked,
  setOpen,
  index,
  title,
  content,
  images,
  media,
  keywords,
  numbers,
}) => {
  const [numberPos, setNumberPos] = useState(1);
  const [counterFlag, setCounterFlag] = useState(false);
  const [counterValues, setCounterValues] = useState(new Array(numbers.length).fill(0));
  gsap.registerPlugin(ScrollTrigger);
  const sectionNumberRef = useRef();
  const updatePos = () => {
    if (sectionNumberRef?.current?.clientHeight) {
      setNumberPos(ScrollTrigger.positionInViewport(sectionNumberRef.current, "center"));
    }
  };
  useEffect(() => {
    ScrollTrigger.create({
      onUpdate: updatePos,
    });
  }, []);

  useEffect(() => {
    if (!counterFlag && numberPos < 0.8) {
      animateCounters(15, setCounterValues, numbers);
      setCounterFlag(true);
    }
  }, [numberPos]);
  return (
    <Wrapper onClick={() => setOpen(open ? null : index)}>
      <TabWrapper>
        <TitleWrapper>
          <Title>{title}</Title>
        </TitleWrapper>
        {unlocked && (open ? <CloseButton /> : <OpenButton />)}
      </TabWrapper>
      <ContentWrapper open={open} unlocked={unlocked}>
        {[...images, ...media].length > 0 && (
          <ImageContainer open={open} unlocked={unlocked}>
            {!!images.length &&
              media.length === 0 &&
              images.map((image, index) => [
                <StrategyImage
                  open={open}
                  unlocked={unlocked}
                  key={index}
                  widescreen={image.widescreen}
                  src={process.env.REACT_APP_IMG_MEDIA_SRC + image.url}
                  alt={image.alt}
                />,
              ])}
            {!!media.length &&
              media.map((item, index) => {
                switch (item.__typename) {
                  case "Case_study_Casestudiescf_Modules_BackgroundInformation_KeyPoints_KeyPoint_MediaFlex_Image":
                    return (
                      <StrategyImage
                        open={open}
                        unlocked={unlocked}
                        key={index}
                        widescreen={item.widescreen}
                        src={`${process.env.REACT_APP_IMG_MEDIA_SRC}${item.url}`}
                        alt={item.alt}
                      />
                    );
                  case "Case_study_Casestudiescf_Modules_BackgroundInformation_KeyPoints_KeyPoint_MediaFlex_Video":
                    return (
                      <VideoWrapper key={index} widescreen={item.widescreen}>
                        <Video
                          src={item.url}
                          loop={item.options.loop}
                          autoPlay={item.options.autoplay}
                          externalSource={item.options.externalSource}
                        />
                      </VideoWrapper>
                    );
                  case "Case_study_Casestudiescf_Modules_BackgroundInformation_KeyPoints_KeyPoint_MediaFlex_YoutubeVideo":
                    return (
                      <VideoWrapper isEmbedded key={index} widescreen={item.widescreen}>
                        <Video isEmbedded src={item.url} />
                      </VideoWrapper>
                    );
                  default:
                    return <></>;
                }
              })}
          </ImageContainer>
        )}
        <Description
          open={open}
          unlocked={unlocked}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
        <NumbersWrapper open={open} unlocked={unlocked} ref={sectionNumberRef}>
          {numbers.length > 0 && (
            <StyledSwiper
              slidesPerView={numbers.length > 3 ? 2.2 : numbers.length}
              allowTouchMove={true}
              modules={[FreeMode]}
              spaceBetween={10}
              breakpoints={{
                719: {
                  slidesPerView: numbers.length > 3 ? 2.4 : numbers.length,
                  spaceBetween: 20,
                },
                1160: {
                  slidesPerView: numbers.length > 3 ? 2.4 : numbers.length,
                  spaceBetween: 55,
                },
              }}
              observer={"true"}
              observeslidechildren={"true"}
              observeParents={"true"}
            >
              {numbers.map((item, index) => (
                <SwiperSlide key={index}>
                  {item.number !== null && (
                    <NumbersItemWrapper key={index}>
                      <NumberCounter>
                        {isNaN(item.number) ? item.number : counterValues[index]}
                        {!isNaN(item.number) && item.sign}
                      </NumberCounter>
                      <ContinuousText
                        dangerouslySetInnerHTML={{
                          __html: item.description,
                        }}
                      />
                    </NumbersItemWrapper>
                  )}
                </SwiperSlide>
              ))}
            </StyledSwiper>
          )}
        </NumbersWrapper>
        {keywords && keywords.length > 0 && (
          <KeywordsWrapper open={open} unlocked={unlocked}>
            {keywords}
          </KeywordsWrapper>
        )}
      </ContentWrapper>
      <Line></Line>
    </Wrapper>
  );
};
