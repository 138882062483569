import styled from "styled-components";
import { MainGridWrapper } from "../../../styles/Main.styles";
import { deviceSizes } from "../../../styles/deviceSizes";

export const TestimonialWrapper = styled(MainGridWrapper)`
  margin-top: 75px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    margin-top: 120px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-top: 155px;
  }
`;
