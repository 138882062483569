import { ContinuousText } from "../../../../styles/Text.styles";
import { useState } from "react";
import {
  SubHeadline,
  Headline,
  ContactSection,
  KeyPointContainer,
  MainWrapper,
  ButtonWrapper,
} from "./CaseOutcome.styles";
import { KeyPoint } from "./KeyPoint/KeyPoint.component";
import { BasicButton } from "../../../common/BasicButton/BasicButton.component";
import { removeParagraphs } from "../../../../helpers";

export const CaseOutcome = ({ unlocked, subHeadline, headline, contactText, button, keypoints }) => {
  return (
    <MainWrapper>
      <SubHeadline>{subHeadline}</SubHeadline>
      <Headline
        dangerouslySetInnerHTML={{
          __html: headline,
        }}
      />
      <ContactSection>
        <ContinuousText
          dangerouslySetInnerHTML={{
            __html: contactText,
          }}
        />
        <ButtonWrapper>
          <BasicButton
            label={button.text}
            href={unlocked ? "/contact" : "#contact"}
            urlType="Internal"
          />
        </ButtonWrapper>
      </ContactSection>
      <KeyPointContainer>
        {keypoints.map((keypoint, index) => (
          <KeyPoint key={index} title={keypoint.title} />
        ))}
      </KeyPointContainer>
    </MainWrapper>
  );
};
