import styled from "styled-components";
import { MainGridWrapper } from "../../../styles/Main.styles";
import { colors } from "../../../styles/colors";
import { deviceSizes } from "../../../styles/deviceSizes";
import { ContinuousText, H2 } from "../../../styles/Text.styles";
import Logo from "../../../public/smallLogo.svg";

export const FooterWrapper = styled(MainGridWrapper)`
  position: relative;
  background: ${colors.anthrazit};
  width: 100%;
`;

export const Contact = styled.div`
  grid-column: 1/3;
  margin-top: 95px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/8;
    margin-top: 100px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-top: 170px;
  }
`;

export const CatchphraseText = styled(H2)`
  grid-column: 1/3;
  color: ${colors.weis};
  margin-bottom: 30px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/8;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: 1/8;
    margin-bottom: 50px;
  }
`;

export const ButtonWrapper = styled.div`
  grid-column: 1/3;
  margin-bottom: 45px;
  width: fit-content;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-row-start: 2;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;

export const ContactSection = styled.div`
  color: ${colors.weis};
  justify-self: start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/5;
    margin-top: 100px;
    grid-row-start: 1;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-top: 175px;
  }
`;

export const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const ContactItem = styled.div`
  color: ${colors.weis};
  cursor: ${({ clickable }) => clickable && "pointer"};
`;

export const FooterMenu = styled.div`
  color: ${colors.weis};
  justify-self: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/5;
    margin-top: 40px;
    justify-self: start;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-top: 48px;
  }
`;

export const FooterItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    gap: 4px;
  }
`;

export const FooterItem = styled.div`
  color: ${colors.weis};
  cursor: pointer;
`;

export const StudioDisclaimer = styled(ContinuousText)`
  grid-column: 1/3;
  color: ${colors.weis};
  margin: 45px 0px 15px 0px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 9/13;
    margin: 47px 0px 15px 0px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin: 73px 0px 25px 0px;
  }
`;

export const LogoIcon = styled.img`
  color: ${colors.weis};
  margin-bottom: 45px;
  width: 134px;
  cursor: pointer;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    margin-bottom: 25px;
    align-self: end;
    grid-column: 9/13;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    width: 200px;
    margin-bottom: 60px;
  }
`;

export const BottomLinksWrapper = styled.div`
  grid-column: 1/3;
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/6;
    grid-row-start: 2;
    margin-top: 50px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    gap: 34px;
    align-self: end;
  }
`;

export const BottomLink = styled.div`
  color: ${colors.weis};
  cursor: pointer;
`;

export const Copyright = styled.div`
  grid-column: 1/3;
  color: ${colors.weis};
  margin-bottom: 15px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/6;
    grid-row-start: 3;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: 1/7;
    display: flex;
    align-self: end;
    margin-bottom: 59px;
  }
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  transform: rotate(180deg);
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 44px;
  height: 45px;
  bottom: 20px;
  right: 5px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    height: 85px;
    bottom: 55px;
    right: 28px;
  }
`;

export const SectionHeader = styled(H2)`
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    font-size: 28px;
    line-height: 38px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    font-size: 36px;
    line-height: 48px;
  }
`;

export const MenuWrapper = styled.div`
  display: grid;
  grid-column: 1/3;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 9/13;
    grid-template-columns: repeat(4, 1fr);
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    font-size: 36px;
    line-height: 48px;
  }
`;
