import styled from "styled-components";
import { deviceSizes } from "../../../../../styles/deviceSizes";
import { colors } from "../../../../../styles/colors";
import { ContinuousText, H2, H3 } from "../../../../../styles/Text.styles";
import Link from "next/link";

export const CaseItemWrapper = styled(Link)`
  grid-column-start: span 2;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column-start: span 6;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column-start: span 4;
    gap: 25px;
  }
`;

export const ImageHover = styled.div`
  background: rgba(26, 26, 26, 0.35);
  display: none;
  grid-row-start: 1;
  grid-column-start: 1;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  cursor: pointer;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    height: 461px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    height: 506px;
  }
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  width: 100%;
  grid-row-start: 1;
  justify-self: center;
  align-self: center;
  grid-column-start: 1;
  background: linear-gradient(
    90deg,
    #f2f2f2 1.35%,
    #f8f8f8 37.26%,
    #f8f8f8 66.49%,
    #f2f2f2 99.83%
  );
  background-position: center;
  background-size: 200% 100%;
  animation: gradient 1.5s ease-in-out infinite;
  @keyframes gradient {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: -100% 0;
    }
  }
      & > img {
        width: 100%;
      }
    }
  }
  display: grid;
  height: calc((100vw - 40px) / 0.83);  
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    height: 461px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    height: 506px;
    &:hover ${ImageHover} {
      display: flex;
    }
  }
`;

export const CaseImageWrapper = styled.div`
  width: 100%;
  grid-row-start: 1;
  justify-self: center;
  align-self: center;
  grid-column-start: 1;
  height: calc((100vw - 40px) / 0.83);
  background: ${({ img }) => `url(${img}) center center`};
  background-size: cover;
  display: grid;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    height: 461px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    height: 506px;
    &:hover ${ImageHover} {
      display: flex;
    }
  }
`;

export const CaseImage = styled.img`
  width: 100%;
  grid-row-start: 1;
  justify-self: center;
  align-self: center;
  transform: translateY(-50%);
  grid-column-start: 1;
`;

export const TextContainer = styled.div`
  color: ${colors.weis};
  max-width: calc(60%);
  display: grid;
  justify-items: center;
  text-align: center;
  @media only screen and (min-width: ${deviceSizes.max_desktop}) {
    max-width: 242px;
  }
`;
export const StyledH2 = styled(H2)`
  z-index: 1;
  grid-row-start: 1;
  grid-column-start: 1;
  font-size: 12vw;
  line-height: 12vw;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    font-size: 6vw;
    max-width: calc(((100vw - 70px) / 2) * 0.6);
    line-height: 6.5vw;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    font-size: 3.9vw;
    max-width: calc(((100vw - 80px) / 3) * 0.6);
    line-height: 5vw;
  }
  @media only screen and (min-width: ${deviceSizes.max_desktop}) {
    font-size: 50px;
    line-height: 65px;
  }
`;

export const ButtonWrapper = styled.div`
  z-index: 0;
  grid-row-start: 1 !important;
  grid-column-start: 1 !important;
  justify-self: end;
  align-self: start;
  transform: translate(43%, -9%);
  width: 36%;
  aspect-ratio: 1/1;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    transform: translate(45%, -9%);
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    width: 40%;
    transform: translate(45%, -5%);
  }
  @media only screen and (min-width: ${deviceSizes.max_desktop}) {
    transform: translate(45px, -5px);
    width: 96px;
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    gap: 10px;
  }
`;

export const Title = styled(H3)``;

export const Professions = styled(ContinuousText)`
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    font-size: 20px;
    line-height: 22px;
  }
`;
