import { Checkbox } from "@mui/material";
import { styled } from "@mui/material/styles";
import styledComponents from "styled-components";
import { colors } from "../../../styles/colors";

export const StyledCheckbox = styled(Checkbox)({
  padding: 0,
  "& .MuiSvgIcon-root": {
    color: colors.anthrazit,
  },
});

export const StyledIcon = styledComponents.div`
  width: 18px;
  height: 18px;
  margin: 3px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.weis};
  border: 1px solid ${colors.anthrazit};
`;

export const StyledCheckedIcon = styledComponents.div`
  width: 12px;
  height: 12px;
  border-radius: 2px;
  background: ${colors.anthrazit};
`;
