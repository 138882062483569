import styled from "styled-components";
import { H1 } from "../../../styles/Text.styles";
import { deviceSizes } from "../../../styles/deviceSizes";

export const Header = styled(H1)`
  grid-column-start: span 2;
  margin-top: 15px;
  margin-bottom: 40px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column-start: span 12;
    margin-top: 40px;
    margin-bottom: 50px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-top: 75px;
    margin-bottom: 70px;
  }
`;
