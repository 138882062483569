import React, { useState, useRef, useEffect } from "react";
import {
  VideoWrapper,
  PlayButton,
  StyledIframe,
  CookiePlaceholderWrapper,
  CookiePlaceholderText,
  AllowCookiesButton,
} from "./Video.styles";
import LoadingIcon from "../../../public/loadingIcon.svg";
import PlayIcon from "../../../public/playIcon.svg";
import { getYouTubeID } from "../../../helpers";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

const youtubeServiceId = "odxR6XPigJKGev";

export const Video = ({
  isEmbedded = false,
  loop = false,
  autoPlay = false,
  src,
  externalSource,
}) => {
  const [videoPlaying, setVideoPlaying] = useState(autoPlay);
  const [videoLoading, setVideoLoading] = useState(true);
  const videoRef = useRef();
  const [isCookieGranted, setIsCookieGranted] = useState(false);
  const [isYoutubeCookieGranted, setIsYoutubeCookieGranted] = useState(false);
  const [videoPos, setVideoPos] = useState(1);

  gsap.registerPlugin(ScrollTrigger);

  const checkLocalStorage = () => {
    if (typeof window !== "undefined") {
      const value = localStorage.getItem("uc_user_interaction");
      if (!isCookieGranted && JSON.parse(value) === true) {
        setIsCookieGranted(true);
        clearInterval(timer);
        videoRef && videoRef.current?.play();
        setVideoPlaying(true);
      }
    }
  };

  const checkYoutubeCookie = () => {
    if (typeof window !== "undefined") {
      const value = JSON.parse(localStorage.getItem("uc_settings"));
      if (value) {
        const [youtubeCookies] = value.services.filter(
          (item) => item.id === youtubeServiceId
        );
        if (youtubeCookies.status === true) {
          setIsYoutubeCookieGranted(true);
          clearInterval(youtubeCookieTimer);
        }
      }
    }
  };

  const timer = autoPlay && setInterval(checkLocalStorage, 1000);
  const youtubeCookieTimer = isEmbedded && setInterval(checkYoutubeCookie, 1000);

  const updatePos = () => {
    if (!isEmbedded && videoRef.current) {
      setVideoPos(ScrollTrigger.positionInViewport(videoRef.current, "center"));
    }
  };

  useEffect(() => {
    !isEmbedded &&
      ScrollTrigger.create({
        onUpdate: updatePos,
      });
  }, []);

  useEffect(() => {
    return () => {
      if (isCookieGranted) clearInterval(timer);
    };
  }, [isCookieGranted, timer]);

  useEffect(() => {
    return () => {
      if (isYoutubeCookieGranted) clearInterval(youtubeCookieTimer);
    };
  }, [isYoutubeCookieGranted, youtubeCookieTimer]);

  useEffect(() => {
    if (!isEmbedded && videoRef) {
      videoPlaying && isCookieGranted
        ? videoRef.current?.play()
        : videoRef.current?.pause();
    }
  }, [isCookieGranted]);

  useEffect(() => {
    if (autoPlay && !isEmbedded && videoRef && videoRef.current) {
      if (videoPos < 1.2 && videoPos > -0.2 && !videoPlaying) {
        videoRef.current
          .play()
          .then(() => setVideoPlaying(true))
          .catch((e) => {
            setVideoLoading(false);
            return;
          });
      }
      if ((videoPos > 1.2 || videoPos < -0.2) && videoPlaying) {
        videoRef.current?.pause();
        setVideoPlaying(false);
      }
    }
  }, [videoPos]);

  const togglePlay = () => {
    if (videoPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setVideoPlaying(!videoPlaying);
  };

  const openCookieSettings = () => {
    if (typeof window !== "undefined") {
      window.UC_UI.showSecondLayer();
    }
  };

  return (
    <VideoWrapper isEmbedded={isEmbedded}>
      {isEmbedded ? (
        <>
          {isYoutubeCookieGranted ? (
            <StyledIframe
              ref={videoRef}
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/${getYouTubeID(src)}`}
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          ) : (
            <CookiePlaceholderWrapper>
              <CookiePlaceholderText>
                Um die externen Inhalte von YouTube anzuzeigen, brauchen wir Deine
                Zustimmung zu unseren Cookies
              </CookiePlaceholderText>
              <AllowCookiesButton onClick={openCookieSettings}>
                Cookie Einstellungen öffnen
              </AllowCookiesButton>
            </CookiePlaceholderWrapper>
          )}
        </>
      ) : (
        <>
          <video
            ref={videoRef}
            src={externalSource ? src : `${process.env.REACT_APP_IMG_MEDIA_SRC}${src}`}
            loop={loop}
            autoPlay={isCookieGranted && autoPlay}
            width="100%"
            height="100%"
            onClick={(e) => {
              if (e.target.paused) {
                e.target.play();
              } else {
                e.target.pause();
              }
              setVideoPlaying(!videoPlaying);
            }}
            onLoadedData={(e) => {
              setVideoLoading(false);
              if (isCookieGranted && autoPlay) {
                e.target.play();
              }
            }}
            onCanPlay={() => setVideoLoading(false)}
            onWaiting={() => setVideoLoading(true)}
            muted={autoPlay}
          />
          {!videoPlaying && (
            <PlayButton onClick={togglePlay}>
              <PlayIcon />
            </PlayButton>
          )}
          {videoLoading && (
            <PlayButton loading>
              <LoadingIcon />
            </PlayButton>
          )}
        </>
      )}
    </VideoWrapper>
  );
};
