import {
  Headline,
  AppLink,
  IconContainer,
  AppIcon,
  MainWrapper,
} from "./AppLinks.styles";

export const AppLinks = ({ link, linkText, headline, icons }) => {
  return (
    <MainWrapper>
      <Headline>{headline}</Headline>
      {link && (
        <AppLink href={link} target="_blank">
          {linkText}
        </AppLink>
      )}
      <IconContainer>
        {icons.google.link && (
          <AppIcon href={icons.google.link} target="_blank">
            <img
              src={process.env.REACT_APP_IMG_MEDIA_SRC + icons.google.icon}
            />
          </AppIcon>
        )}
        {icons.appStore.link && (
          <AppIcon href={icons.appStore.link} target="_blank">
            <img
              src={process.env.REACT_APP_IMG_MEDIA_SRC + icons.appStore.icon}
            />
          </AppIcon>
        )}
      </IconContainer>
    </MainWrapper>
  );
};
