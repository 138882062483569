import { StyledCheckbox, StyledCheckedIcon } from "./Checkbox.styles";
import { StyledIcon } from "./Checkbox.styles";

export const Checkbox = (props) => {
  return (
    <StyledCheckbox
      {...props}
      icon={<StyledIcon />}
      checkedIcon={
        <StyledIcon>
          <StyledCheckedIcon />
        </StyledIcon>
      }
    />
  );
};
