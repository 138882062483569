import styled from "styled-components";
import { MainGridWrapper } from "../../../../styles/Main.styles";
import { deviceSizes } from "../../../../styles/deviceSizes";

export const MainWrapper = styled(MainGridWrapper)`
  margin-top: 75px;
  margin-bottom: 75px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    margin-top: 120px;
    margin-bottom: 120px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-top: 155px;
    margin-bottom: 155px;
  }
`;

export const TestimonialWrapper = styled.div`
  grid-column-start: span 2;
  display: flex;
  flex-direction: column;
  gap: 15px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column-start: span 10;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column-start: span 9;
  }
`;

export const StyledImage = styled.img`
  width: 100%;
  grid-column-start: span 2;
  margin-bottom: 15px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column-start: span 12;
    margin-bottom: 40px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-bottom: 75px;
  }
`;

export const VideoWrapper = styled.div`
  width: 100%;
  grid-column-start: span 2;
  margin-bottom: 15px;
  display: flex;
  ${({ isEmbedded }) => isEmbedded && "min-height: 245px;"}
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column-start: span 12;
    margin-bottom: 40px;
    ${({ isEmbedded }) => isEmbedded && "min-height: 575px;"}
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-bottom: 75px;
    ${({ isEmbedded }) => isEmbedded && "min-height: 960px;"}
  }
`;
