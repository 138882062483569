import { colors } from "../../../styles/colors";
import React from "react";
import { StyledButton, ArrowWrapper } from "./ArrowButton.styles";
import { Arrow } from "../Arrow/Arrow.component";

export const ArrowButton = ({ onClick, autoSize = false, small = false, props, color = colors.anthrazit, right = false }) => {
  return (
    <StyledButton
      small={(small).toString()}
      autosize={autoSize}
      sx={{
        color: colors.weis,
        backgroundColor: color,
        minWidth: "0px",
      }}
      aria-label="project-description-button"
      onClick={onClick}
      {...props}
    >
      <ArrowWrapper>
        <Arrow side right={right} slim color={colors.weis} />
      </ArrowWrapper>
    </StyledButton>
  );
};
