import {
  MobileWrapper,
  DesktopWrapper,
  StyledSwiper,
  StyledImage,
  DesktopTextWrapper,
  NumbersItemWrapper,
  Number,
  VideoWrapper,
} from "./FeatureDescriptions.styles";

import { ContinuousText } from "../../../../styles/Text.styles";
import { SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
import { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { animateCounters, getYouTubeID } from "../../../../helpers";
import "swiper/css";
import "swiper/css/pagination";
import { Video } from "../../../common/Video/Video.component";

export const FeatureDescriptions = ({ image, media, numbers, mirrored }) => {
  const [numberPos, setNumberPos] = useState(1);
  const [counterFlag, setCounterFlag] = useState(false);
  const [counterValues, setCounterValues] = useState(new Array(numbers.length).fill(0));
  gsap.registerPlugin(ScrollTrigger);
  const numberRef = useRef();
  const desktopNumberRef = useRef();
  const updatePos = () => {
    if (numberRef?.current?.clientHeight) {
      setNumberPos(ScrollTrigger.positionInViewport(numberRef.current, "center"));
    }
    if (desktopNumberRef?.current?.clientHeight) {
      setNumberPos(ScrollTrigger.positionInViewport(desktopNumberRef.current, "center"));
    }
  };
  useEffect(() => {
    ScrollTrigger.create({
      onUpdate: updatePos,
    });
  }, []);

  useEffect(() => {
    if (!counterFlag && numberPos < 0.8) {
      animateCounters(15, setCounterValues, numbers);
      setCounterFlag(true);
    }
  }, [numberPos]);
  return (
    <>
      <DesktopWrapper>
        <DesktopTextWrapper mirrored={mirrored} ref={desktopNumberRef}>
          {numbers.map(
            (item, index) =>
              (item.number || item.sign) && (
                <NumbersItemWrapper key={index}>
                  <Number>
                    {item.number !== null && counterValues[index]}
                    {item.sign}
                  </Number>
                  <ContinuousText>{item.description}</ContinuousText>
                </NumbersItemWrapper>
              )
          )}
        </DesktopTextWrapper>
        {image && image.url && media.length === 0 && (
          <StyledImage
            mirrored={mirrored}
            src={`${process.env.REACT_APP_IMG_MEDIA_SRC}${image.url}`}
            alt={image.alt}
          />
        )}
        {!!media.length &&
          media.map((item, index) => {
            switch (item.__typename) {
              case "Case_study_Casestudiescf_Modules_FeatureDescriptions_MediaFlex_Image":
                return (
                  <StyledImage
                    mirrored={mirrored}
                    src={`${process.env.REACT_APP_IMG_MEDIA_SRC}${item.url}`}
                    alt={item.alt}
                  />
                );
              case "Case_study_Casestudiescf_Modules_FeatureDescriptions_MediaFlex_Video":
                return (
                  <VideoWrapper mirrored={mirrored} key={index}>
                    <Video
                      src={item.url}
                      loop={item.options.loop}
                      autoPlay={item.options.autoplay}
                      externalSource={item.options.externalSource}
                    />
                  </VideoWrapper>
                );
              case "Case_study_Casestudiescf_Modules_FeatureDescriptions_MediaFlex_YoutubeVideo":
                return (
                  <>
                    {getYouTubeID(item.url) && (
                      <VideoWrapper isEmbedded mirrored={mirrored} key={index}>
                        <Video isEmbedded src={item.url} />
                      </VideoWrapper>
                    )}
                  </>
                );
              default:
                return <></>;
            }
          })}
      </DesktopWrapper>
      <MobileWrapper ref={numberRef}>
        {numbers.length && (
          <StyledSwiper
            slidesPerView={1}
            allowTouchMove={true}
            modules={[FreeMode]}
            spaceBetween={10}
            observer={"true"}
            observeslidechildren={"true"}
            observeParents={"true"}
          >
            {numbers.map((item, index) => (
              <SwiperSlide key={index}>
                {(item.number || item.sign) && (
                  <NumbersItemWrapper key={index}>
                    <Number>
                      {item.number !== null && counterValues[index]}
                      {item.sign}
                    </Number>
                    <ContinuousText>{item.description}</ContinuousText>
                  </NumbersItemWrapper>
                )}
              </SwiperSlide>
            ))}
          </StyledSwiper>
        )}
      </MobileWrapper>
    </>
  );
};
