import {
  MobileWrapper,
  DesktopWrapper,
  StyledSwiper,
  FlexGrid,
  StyledImage,
  MobileTextWrapper,
  StyledH3,
  StyledText,
  DesktopTextWrapper,
  VideoWrapper,
  EmbedVideoWrapper,
} from "./ResponsiveLayout.styles";
import { SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
import { useState } from "react";
import { MainGridWrapper } from "../../../../styles/Main.styles";
import "swiper/css";
import "swiper/css/pagination";
import Image from "next/image";
import { Video } from "../../../common/Video/Video.component";
import { getYouTubeID } from "../../../../helpers";

export const ResponsiveLayout = ({ headline, text, media, mirrored }) => {
  const [loading, setLoading] = useState(true);
  const [swiper, setSwiper] = useState(null);
  return (
    <>
      <DesktopWrapper mirrored={mirrored}>
        <DesktopTextWrapper mirrored={mirrored}>
          <StyledH3>{headline}</StyledH3>
          <StyledText>{text}</StyledText>
        </DesktopTextWrapper>
        {!!media.length &&
          media.map((item, index) => {
            switch (item.__typename) {
              case "Case_study_Casestudiescf_Modules_ResponsiveLayout_ImagesFlex_Image":
                return (
                  <img
                    key={index}
                    src={process.env.REACT_APP_IMG_MEDIA_SRC + item.url}
                    alt={item.alt}
                    onLoad={() => setLoading(false)}
                    onError={() => setLoading(false)}
                    loading="lazy"
                  />
                );
              case "Case_study_Casestudiescf_Modules_ResponsiveLayout_ImagesFlex_Video":
                return (
                  <Video
                    key={index}
                    src={item.url}
                    loop={item.options.loop}
                    autoPlay={item.options.autoplay}
                    externalSource={item.options.externalSource}
                  />
                );
              case "Case_study_Casestudiescf_Modules_ResponsiveLayout_ImagesFlex_YoutubeVideo":
                return (
                  <>
                    {getYouTubeID(item.url) && (
                      <EmbedVideoWrapper>
                        <Video key={index} isEmbedded src={item.url} />
                      </EmbedVideoWrapper>
                    )}
                  </>
                );
              default:
                return <></>;
            }
          })}
      </DesktopWrapper>
      <MobileTextWrapper>
        <StyledH3>{headline}</StyledH3>
        <StyledText>{text}</StyledText>
      </MobileTextWrapper>
      <MobileWrapper>
        {media.length > 1 ? (
          <StyledSwiper
            slidesPerView={"auto"}
            allowTouchMove={true}
            modules={[FreeMode]}
            freeMode={{ enabled: true }}
            spaceBetween={40}
            observer={"true"}
            observeslidechildren={"true"}
            observeParents={"true"}
            loading={(loading ? 1 : 0).toString()}
          >
            {media.map((item, index) => {
              switch (item.__typename) {
                case "Case_study_Casestudiescf_Modules_ResponsiveLayout_ImagesFlex_Image":
                  return (
                    <SwiperSlide key={index}>
                      <img
                        src={process.env.REACT_APP_IMG_MEDIA_SRC + item.url}
                        alt={item.alt}
                        onLoad={() => setLoading(false)}
                        onError={() => setLoading(false)}
                        loading="lazy"
                      />
                    </SwiperSlide>
                  );
                case "Case_study_Casestudiescf_Modules_ResponsiveLayout_ImagesFlex_Video":
                  return (
                    <SwiperSlide key={index}>
                      <Video
                        src={item.url}
                        loop={item.options.loop}
                        autoPlay={item.options.autoplay}
                        externalSource={item.options.externalSource}
                      />
                    </SwiperSlide>
                  );
                case "Case_study_Casestudiescf_Modules_ResponsiveLayout_ImagesFlex_YoutubeVideo":
                  return (
                    <>
                      {getYouTubeID(item.url) && (
                        <SwiperSlide key={index}>
                          <EmbedVideoWrapper>
                            <Video isEmbedded src={item.url} />
                          </EmbedVideoWrapper>
                        </SwiperSlide>
                      )}
                    </>
                  );
                default:
                  return <></>;
              }
            })}
          </StyledSwiper>
        ) : (
          <MainGridWrapper>
            <FlexGrid>
              {media.map((item, index) => {
                switch (item.__typename) {
                  case "Case_study_Casestudiescf_Modules_ResponsiveLayout_ImagesFlex_Image":
                    return (
                      <StyledImage
                        key={index}
                        widescreen={item.widescreen}
                        src={process.env.REACT_APP_IMG_MEDIA_SRC + item.url}
                        alt={item.alt}
                        onLoad={() => setLoading(false)}
                        onError={() => setLoading(false)}
                        loading="lazy"
                      />
                    );
                  case "Case_study_Casestudiescf_Modules_ResponsiveLayout_ImagesFlex_Video":
                    return (
                      <VideoWrapper key={index} widescreen={item.widescreen}>
                        <Video
                          src={item.url}
                          loop={item.options.loop}
                          autoPlay={item.options.autoplay}
                          externalSource={item.options.externalSource}
                        />
                      </VideoWrapper>
                    );
                  case "Case_study_Casestudiescf_Modules_ResponsiveLayout_ImagesFlex_YoutubeVideo":
                    return (
                      <>
                        {getYouTubeID(item.url) && (
                          <VideoWrapper
                            isEmbedded
                            key={index}
                            widescreen={item.widescreen}
                          >
                            <Video isEmbedded src={item.url} />
                          </VideoWrapper>
                        )}
                      </>
                    );
                  default:
                    return <></>;
                }
              })}
            </FlexGrid>
          </MainGridWrapper>
        )}
      </MobileWrapper>
    </>
  );
};
