import { useState } from "react";
import {
  SubHeadline,
  Headline,
  FlexibleGrid,
  KeyPointContainer,
  MainWrapper,
} from "./BackgroundInformation.styles";
import { KeyPoint } from "./KeyPoint/KeyPoint.component";
import { removeParagraphs } from "../../../../helpers";

export const BackgroundInformation = ({
  keypointsFlex,
  subHeadline,
  headline,
}) => {
  const [open, setOpen] = useState(0);
  const unlocked=true;

  return (
    <MainWrapper>
      <SubHeadline>{subHeadline}</SubHeadline>
      <Headline
        dangerouslySetInnerHTML={{
          __html: headline,
        }}
      />
      <FlexibleGrid>
        <KeyPointContainer unlocked={unlocked}>
          {keypointsFlex.map((keypoint, index) => (
            <KeyPoint
              key={index}
              index={index}
              open={open === index}
              setOpen={setOpen}
              unlocked={unlocked}
              title={keypoint.title}
              content={keypoint.content}
              keywords={keypoint.keywords}
              media={keypoint.mediaFlex}
              images={keypoint.images}
              numbers={keypoint.numbersFlex}
            />
          ))}
        </KeyPointContainer>
      </FlexibleGrid>
    </MainWrapper>
  );
};
