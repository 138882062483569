import styled from "styled-components";
import { H3 } from "../../../../styles/Text.styles";
import { deviceSizes } from "../../../../styles/deviceSizes";
import { MainGridWrapper } from "../../../../styles/Main.styles";
import { colors } from "../../../../styles/colors";

export const MainWrapper = styled(MainGridWrapper)`
  margin-top: 90px;
  margin-bottom: 75px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    margin-top: 40px;
    margin-bottom: 120px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-top: 75px;
    margin-bottom: 155px;
  }
`;

export const Headline = styled(H3)`
  grid-column: 1/3;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/9;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;
export const AppLink = styled.a`
  grid-column: 1/3;
  margin-top: 5px;
  font-family: "Neue Haas Grotesk Display Pro";
  font-style: normal;
  font-weight: 400;
  color: ${colors.anthrazit};
  font-size: 16px;
  line-height: 22px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/7;
    font-size: 18px;
    line-height: 26px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: 1/5;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.02em;
  }
`;

export const AppIcon = styled.a`
  grid-column: span 1;
  display: flex;
  & > img {
    width: 100%;
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: span 1;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: span 1;
  }
`;
export const IconContainer = styled.div`
  display: grid;
  grid-column: 1/3;
  grid-template-columns: repeat(2, 1fr);
  margin: 5px 0px 0px 0px;
  grid-column-gap: 20px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/6;
    grid-column-gap: 15px;
    margin: 5px 0px 0px 0px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: 1/5;
    grid-column-gap: 20px;
    margin: 20px 0px 0px 0px;
  }
`;
