import styled from "styled-components";
import { deviceSizes } from "../../../../styles/deviceSizes";
import { colors } from "../../../../styles/colors";
import { Swiper } from "swiper/react";
import { MainGridWrapper } from "../../../../styles/Main.styles";
import { ContinuousText, H3 } from "../../../../styles/Text.styles";

export const DesktopWrapper = styled(MainGridWrapper)`
  margin: ${({ bottomMargin }) =>
    bottomMargin ? "75px 0px 40px 0px" : "75px 0px"};
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    margin: ${({ bottomMargin }) =>
      bottomMargin ? "120px 0px 40px 0px" : "120px 0px"};
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin: ${({ bottomMargin }) =>
      bottomMargin ? "155px 0px 75px 0px" : "155px 0px"};
  }
`;

export const DesktopTextWrapper = styled.div`
  display: flex;
  grid-row-start: 2;
  grid-column: 1 / span 2;
  flex-direction: column;
  margin-top: 35px;
  gap: 15px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-row-start: 1;
    margin-top: 0px;
    align-self: end;
    grid-column: ${({ mirrored }) => (mirrored ? "1 / span 5" : "8 / span 5")};
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    gap: 20px;
  }
`;

export const MobileTextWrapper = styled(MainGridWrapper)`
  margin-top: 75px;
  margin-bottom: 35px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    display: none;
  }
`;
export const StyledH3 = styled(H3)`
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;

export const StyledText = styled(ContinuousText)`
  grid-column: 1 / span 3;
`;

export const NumbersWrapper = styled.div`
  width: 100%;
  margin-bottom: 75px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    margin-bottom: 120px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-bottom: 155px;
  }
`;

export const FlexGrid = styled.div`
  grid-column-start: span 2;
  display: flex;
  justify-content: space-evenly;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1 / span 12;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;

export const StyledSwiper = styled(Swiper)`
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  padding: 0px calc(50%) 0px 20px;
  &: active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
  & > .swiper-wrapper {
    align-items: end;
    & > .swiper-slide {
      height: unset;
    }
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    margin: ${({ swiperMode }) => (swiperMode ? "0" : "0 20px")};
    padding: ${({ swiperMode }) => (swiperMode ? "0 20px" : "0")};
    & > .swiper-wrapper {
      justify-content: ${({ swiperMode }) => (swiperMode ? "start" : "center")};
      align-items: center;
      & > .swiper-slide {
        min-height: ${({ loading }) =>
          loading ? "calc((((100vw - 270px) / 12) * 3 + 40px) * 4/3)" : "0"};
      }
    }
  }
  @media only screen and (min-width: ${deviceSizes.max_desktop}) {
    padding: ${({ swiperMode }) =>
      swiperMode ? "0px calc((100% - (12 * 85px) - (11 * 20px)) / 2)" : "0"};
    margin: ${({ swiperMode }) =>
      swiperMode ? "0" : "0px calc((100% - (12 * 85px) - (11 * 20px)) / 2)"};
    & > .swiper-wrapper {
      & > .swiper-slide {
        min-height: ${({ loading }) =>
          loading ? "calc(((3 * 70px) + (2 * 20px)) * 4/3)" : "0"};
      }
    }
  }
`;

export const NumbersItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const Number = styled.div`
  font-family: "Tiempos Headline";
  font-style: italic;
  font-weight: 400;
  font-size: 56px;
  line-height: 56px;
  color: ${colors.akzentGrun};
  @media only screen and (min-width: 861px) {
    font-size: 72px;
    line-height: 78px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    font-size: 52px;
    line-height: 68px;
  }
`;

export const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  height: auto;
  grid-column: 1 / span 2;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: ${({ mirrored }) => (mirrored ? "7/span 6" : "1 / span 6")};
    align-self: end;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;

export const VideoWrapper = styled.div`
  max-width: 100%;
  max-height: 100%;
  height: auto;
  grid-column: 1 / span 2;
  display: flex;
  ${({ isEmbedded }) => isEmbedded && "min-height: 315px;"}
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: ${({ mirrored }) => (mirrored ? "7/span 6" : "1 / span 6")};
    ${({ isEmbedded }) => isEmbedded && "min-height: 305px;"}
    align-self: end;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    ${({ isEmbedded }) => isEmbedded && "min-height: 510px;"}
  }
`;
