import { MainGrid } from "./PreviewGrid.styles";
import { CaseGridItem } from "./CaseGridItem/CaseGridItem.component";

export const PreviewGrid = ({
  cases,
  customSlug = null,
  noPadding = false,
}) => {
  return (
    <MainGrid
      noPadding={noPadding}
      tabletModulo={cases.length % 2}
      desktopModulo={cases.length % 3}
    >
      {cases.length > 0 &&
        cases.map((item, index) => (
          <CaseGridItem key={index} data={item} customSlug={customSlug} />
        ))}
    </MainGrid>
  );
};
