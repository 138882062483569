import styled from "styled-components";
import { deviceSizes } from "../../../../styles/deviceSizes";
import { colors } from "../../../../styles/colors";
import { Swiper } from "swiper/react";

export const MobileWrapper = styled.div`
  width: 100%;
  padding: 20px 0px;
  margin: 75px 0px;
  background: ${colors.leistungenGrau};
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    display: none;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;

export const DesktopWrapper = styled.div`
  display: none;
  background: ${colors.leistungenGrau};
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    display: inherit;
    padding: 30px 0px 30px 0px;
    margin: 120px 0px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin: 155px 0px;
    padding: 105px 0px;
  }
`;

export const FlexGrid = styled.div`
  grid-column-start: span 2;
  display: flex;
  justify-content: space-evenly;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1 / span 12;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;

export const StyledImage = styled.img`
  width: ${({ widescreen }) => (widescreen ? "100%" : "auto")};
  max-width: 100%;
  max-height: 455px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    width: auto;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    max-height: 510px;
  }
`;

export const VideoWrapper = styled.div`
  width: ${({ widescreen }) => (widescreen ? "100%" : "auto")};
  max-width: 100%;
  max-height: 455px;
  display: flex;
  ${({ isEmbedded }) => isEmbedded && "min-height: 315px;"}
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    width: auto;
    ${({ isEmbedded }) => isEmbedded && "min-height: 510px;"}
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    max-height: 510px;
    ${({ isEmbedded }) => isEmbedded && "min-height: 520px;"}
  }
`;

export const EmbedVideoWrapper = styled.div`
  display: flex;
  width: 100%;
  aspect-ratio: 3 / 1.7;
`;

export const StyledSwiper = styled(Swiper)`
  cursor: ${({ swiperMode }) => swiperMode && "grab"};
  cursor: ${({ swiperMode }) => swiperMode && "-moz-grab"};
  cursor: ${({ swiperMode }) => swiperMode && "-webkit-grab"};
  padding: 0px calc(33%) 0px 20px;
  &: active {
    cursor: ${({ swiperMode }) => swiperMode && "grabbing"};
    cursor: ${({ swiperMode }) => swiperMode && "-moz-grabbing"};
    cursor: ${({ swiperMode }) => swiperMode && "-webkit-grabbing"};
  }
  & > .swiper-wrapper {
    align-items: center;
    & > .swiper-slide {
      height: unset;
      min-height: ${({ loading }) =>
        loading ? "calc((100vw - 40px) * 0.37 * 4/3)" : "0"};
      & > img {
        max-height: 510px;
        height: 100%;
        width: fit-content;
        max-width: 100%;
      }
    }
    & > .swiper-pagination {
      bottom: 10px;
    }
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    margin: ${({ swiperMode }) => (swiperMode ? "0" : "0 20px")};
    padding: ${({ swiperMode }) => (swiperMode ? "0 100px 0 20px" : "0")};
    & > .swiper-wrapper {
      justify-content: ${({ swiperMode }) => (swiperMode ? "start" : "start")};
      align-items: center;
      & > .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: ${({ loading }) =>
          loading ? "calc((((100vw - 270px) / 12) * 3 + 40px) * 4/3)" : "0"};
      }
    }
  }
  @media only screen and (min-width: ${deviceSizes.max_desktop}) {
    padding: ${({ swiperMode }) =>
      swiperMode
        ? "0px calc(((100% - (12 * 90px) - (11 * 20px)) / 2) + 80px) 0 calc((100% - (12 * 90px) - (11 * 20px)) / 2) "
        : "0"};
    margin: ${({ swiperMode }) =>
      swiperMode ? "0" : "0px calc((100% - (12 * 90px) - (11 * 20px)) / 2)"};
    & > .swiper-wrapper {
      & > .swiper-slide {
        min-height: ${({ loading }) =>
          loading ? "calc(((3 * 90px) + (2 * 20px)) * 4/3)" : "0"};
      }
    }
  }
`;
