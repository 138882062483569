import styled from "styled-components";
import { deviceSizes } from "../../../../styles/deviceSizes";
import { colors } from "../../../../styles/colors";
import { Input } from "../../../common/Input/Input.component";
import { ContinuousText, H3 } from "../../../../styles/Text.styles";
import { MainGridWrapper } from "../../../../styles/Main.styles";

export const StyledMainGrid = styled(MainGridWrapper)`
  background-color: ${colors.leistungenGrau};
  margin-top: 75px;
  margin-bottom: 75px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    background-color: ${colors.weis};
    margin-top: 120px;
    margin-bottom: 120px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    background-color: ${colors.weis};
    margin-top: 155px;
    margin-bottom: 155px;
  }
`;

export const Anchor = styled.a`
  display: block;
  position: relative;
  top: -85px;
  visibility: hidden;
  grid-column-start: span 2;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    top: -99px;
    grid-column-start: span 12;
  }
`;

export const MainWrapper = styled.div`
  grid-column: 1/3;
  display: grid;
  padding: 60px 0px 50px 0px;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  background-color: ${colors.leistungenGrau};
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 2/12;
    padding: 50px 0px;
    grid-template-columns: repeat(10, 1fr);
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    padding: 55px 0px;
  }
  @media only screen and (min-width: ${deviceSizes.max_desktop}) {
    grid-template-columns: repeat(10, 90px);
  }
`;

export const SubHeadline = styled(ContinuousText)`
  grid-column: 1/3;
  margin-bottom: 10px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 2/10;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;

export const Headline = styled(H3)`
  grid-column: 1/3;
  margin-bottom: 15px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 2/10;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;

export const Description = styled(ContinuousText)`
  grid-column: 1/3;
  margin-bottom: 30px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 2/10;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-bottom: 15px;
  }
`;

export const FormWrapper = styled.form`
  display: grid;
  grid-column: 1/3;
  grid-column-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 2/10;
    grid-template-columns: repeat(8, 1fr);
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
  @media only screen and (min-width: ${deviceSizes.max_desktop}) {
    grid-template-columns: repeat(8, 90px);
  }
`;
export const SmallText = styled.div`
  grid-column-start: span 2;
  font-family: "Neue Haas Grotesk Display Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 20px;
  & > a {
    text-decoration: underline;
    margin: 0px 2px;
    cursor: pointer;
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column-start: span 8;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-top: 10px;
    margin-bottom: 15px;
  }
`;

export const StyledInput = styled(Input)`
  grid-column-start: span 2;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column-start: span 4;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;
export const ButtonWrapper = styled.div`
  grid-column-start: span 2;
  max-width: max-content;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    max-width: none;
    min-width: max-content;
    grid-column-start: span 4;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    max-width: none;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  grid-column-start: span 2;
  align-items: start;
  gap: 10px;
  margin-bottom: 40px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column-start: span 8;
    gap: 15px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-bottom: 15px;
  }
`;

export const CheckboxLabel = styled.div`
  font-family: "Neue Haas Grotesk Display Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  & > a {
    text-decoration: underline;
    margin: 0px 2px;
    cursor: pointer;
  }
  color: ${colors.anthrazit};
`;
