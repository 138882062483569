
export const ACCESS_LIST = 'accessList'

export const getLocalStorage = () => {
  const list = JSON.parse(localStorage.getItem(ACCESS_LIST))
  if(list === null){
    localStorage.setItem(ACCESS_LIST, JSON.stringify([]));
  }
  return JSON.parse(localStorage.getItem(ACCESS_LIST))
}


export const setLocalStorage = (data) => {
  const list = getLocalStorage()
  if (!list.includes(data)) {
    localStorage.setItem(
      ACCESS_LIST,
      JSON.stringify(list.concat([data]))
    );
  }
}