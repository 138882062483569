import styled from "styled-components";
import { MainGridWrapper } from "../../../../styles/Main.styles";
import { deviceSizes } from "../../../../styles/deviceSizes";

export const MainGrid = styled(MainGridWrapper)`
    grid-row-gap: 40px;
    padding: ${({ noPadding }) => (noPadding ? "0px" : "0px 20px")};
    @media only screen and (min-width: ${deviceSizes.tablet}) {
        grid-row-gap: 50px;
        ${({ tabletModulo }) => `${(tabletModulo > 0) && `& div:nth-child(-n + ${tabletModulo}){grid-column-start: span ${12 / tabletModulo}}`}`}
    }
    @media only screen and (min-width: ${deviceSizes.desktop}) {
        grid-row-gap: 75px;
        ${({ desktopModulo, tabletModulo }) => `${(desktopModulo > 0) ? `& div:nth-child(-n + ${desktopModulo}){grid-column-start: span ${12 / desktopModulo}}` : `& div:nth-child(-n + ${tabletModulo}){grid-column-start: span 4}`}`}
    }
`