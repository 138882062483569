import { MainGridWrapper } from "../../../styles/Main.styles";
import { HeadlineWrapper } from "./Headline.styles";

export const Headline = ({ content = "<p>TestHeadline</p>" }) => {
  return (
    <MainGridWrapper>
      <HeadlineWrapper
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </MainGridWrapper>
  );
};
