import {
  NumbersWrapper,
  DesktopWrapper,
  StyledSwiper,
  StyledImage,
  VideoWrapper,
  DesktopTextWrapper,
  NumbersItemWrapper,
  Number,
} from "./ResponsiveNumbers.styles";

import { ContinuousText, H3 } from "../../../../styles/Text.styles";
import { SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
import { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { animateCounters, getYouTubeID } from "../../../../helpers";
import "swiper/css";
import "swiper/css/pagination";
import { Video } from "../../../common/Video/Video.component";

export const ResponsiveNumbers = ({ media, headline, text, numbers, mirrored }) => {
  const [numberPos, setNumberPos] = useState(1);
  const [counterFlag, setCounterFlag] = useState(false);
  const [counterValues, setCounterValues] = useState(new Array(numbers.length).fill(0));
  gsap.registerPlugin(ScrollTrigger);
  const sectionNumberRef = useRef();
  const updatePos = () => {
    if (sectionNumberRef?.current?.clientHeight) {
      setNumberPos(ScrollTrigger.positionInViewport(sectionNumberRef.current, "center"));
    }
  };
  useEffect(() => {
    ScrollTrigger.create({
      onUpdate: updatePos,
    });
  }, []);

  useEffect(() => {
    if (!counterFlag && numberPos < 0.8) {
      animateCounters(15, setCounterValues, numbers);
      setCounterFlag(true);
    }
  }, [numberPos]);
  return (
    <>
      <DesktopWrapper bottomMargin={numbers.length > 0}>
        <DesktopTextWrapper mirrored={mirrored}>
          <H3>{headline}</H3>
          <ContinuousText>{text}</ContinuousText>
        </DesktopTextWrapper>
        {media.map((item, index) => {
          switch (item.__typename) {
            case "Case_study_Casestudiescf_Modules_ResponsiveNumbers_MediaFlex_Image":
              return (
                <StyledImage
                  key={index}
                  src={process.env.REACT_APP_IMG_MEDIA_SRC + item.url}
                  alt={item.alt}
                  mirrored={mirrored}
                />
              );
            case "Case_study_Casestudiescf_Modules_ResponsiveNumbers_MediaFlex_Video":
              return (
                <VideoWrapper key={index} mirrored={mirrored}>
                  <Video
                    src={item.url}
                    loop={item.options.loop}
                    autoPlay={item.options.autoplay}
                    externalSource={item.options.externalSource}
                  />
                </VideoWrapper>
              );
            case "Case_study_Casestudiescf_Modules_ResponsiveNumbers_MediaFlex_YoutubeVideo":
              return (
                <>
                  {getYouTubeID(item.url) && (
                    <VideoWrapper isEmbedded key={index} mirrored={mirrored}>
                      <Video isEmbedded src={item.url} />
                    </VideoWrapper>
                  )}
                </>
              );
            default:
              return <></>;
          }
        })}
      </DesktopWrapper>
      <NumbersWrapper ref={sectionNumberRef}>
        {numbers.length > 0 && (
          <StyledSwiper
            slidesPerView={1}
            allowTouchMove={true}
            breakpoints={{
              719: {
                slidesPerView: numbers.length > 3 ? "auto" : numbers.length,
              },
            }}
            modules={[FreeMode]}
            spaceBetween={10}
            observer={"true"}
            observeslidechildren={"true"}
            observeParents={"true"}
          >
            {numbers.map((item, index) => (
              <SwiperSlide key={index}>
                {(item.number || item.sign) && (
                  <NumbersItemWrapper key={index}>
                    <Number>
                      {item.number !== null && counterValues[index]}
                      {item.sign}
                    </Number>
                    <ContinuousText>{item.description}</ContinuousText>
                  </NumbersItemWrapper>
                )}
              </SwiperSlide>
            ))}
          </StyledSwiper>
        )}
      </NumbersWrapper>
    </>
  );
};
