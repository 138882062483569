import styled from "styled-components";
import { deviceSizes } from "../../../../../styles/deviceSizes";
import { colors } from "../../../../../styles/colors";
import { ContinuousText } from "../../../../../styles/Text.styles";

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 5px;
  background: ${({ light }) =>
    light ? colors.textBoxBeige : colors.anthrazit};
  color: ${({ light }) => (light ? colors.anthrazit : colors.weis)};
  border-radius: 5px;
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    gap: 10px;
  }
`;

export const StyledText = styled(ContinuousText)`
  & > h3,
  h2,
  h1 {
    margin-block: 0;
    font-weight: 400;
  }
  font-size: 16px;
  line-height: 22px;
  & > h2 {
    font-size: 30px;
    line-height: 36px;
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    & > h2 {
      font-size: 40px;
      line-height: 48px;
    }
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    font-size: 18px;
    line-height: 26px;
    & > h2 {
      font-size: 52px;
      line-height: 68px;
    }
  }
`;
