import { GlobalStyle, MainGridWrapper } from "../../../styles/Main.styles";
import { Footer } from "../../../components/common/Footer/Footer.component";
import { Headline } from "../../common/Headline/Headline.component";
import { PreviewGrid } from "../../CaseStudies/Overview/PreviewGrid/PreviewGrid.component";
import { Testimonials } from "../../Home/CaseStudies/Testimonials/Testimonials.component";
import { TestimonialWrapper } from "./CaseStudies.styles";

import { useState, useEffect } from "react";
import { StyledModal } from "../../common/StyledModal/StyledModal.component";
import { getLocalStorage } from '../../../common/localstorage'
/* import { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { BasicButton } from "../../common/BasicButton/BasicButton.component"; */

export const CaseStudies = ({
  caseStudyContent,
  navbarSwitch,
  setNavbarSwitch,
  footerCF,
  popup,
}) => {
  /*  
  Code meant for dynamically loading in cases, to use it uncomment then connect the data state
  to PreviewGrid
  
  const [data, setData] = useState(caseStudyContent.cases);
  const [allDataFetched, setAllDataFetched] = useState(false);
  const gridRef = useRef();
  const [gridPos, setGridPos] = useState(1);
  gsap.registerPlugin(ScrollTrigger);
  const fetchData = async () => {
    const resCaseStudies = await fetch("http://localhost/graphql", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        query: `
  query privacyQuery {
  caseStudies (first: ${data.length + 3}) {
    nodes {
      title
      case_studyId
      caseStudiesCF {
        previewImage {
          url
          alt
        }
        discipline
      }
        }
        }}`,
      }),
    });
    const jsonCaseStudies = !allDataFetched && (await resCaseStudies.json());
    if (
      !jsonCaseStudies ||
      jsonCaseStudies.data.caseStudies.nodes.length === data.length
    ) {
      setAllDataFetched(true);
    } else {
      setData(jsonCaseStudies.data.caseStudies.nodes);
    }
  };

  const updatePos = () => {
    if (gridRef?.current) {
      setGridPos(ScrollTrigger.positionInViewport(gridRef.current, "center"));
    }
  };

  useEffect(() => {
    ScrollTrigger.create({
      onUpdate: updatePos,
    });
  }, []);

  useEffect(() => {
    if (gridPos < 0.75) {
      !allDataFetched && fetchData();
    }
  }, [gridPos]);
 */
  useEffect(() => {
      getLocalStorage()
  }, [])

  useEffect(() => {
    setModalOpen(popup.popupOptions.enabled);
  }, [popup]);
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <GlobalStyle />
      <StyledModal
        open={modalOpen}
        setOpen={setModalOpen}
        text={popup.popupContent}
        headline={popup.popupHeadline}
        icon={popup.popupIcon}
        button={popup.popupButton}
      />
      <Headline content={caseStudyContent.headline} />
      <PreviewGrid cases={caseStudyContent.cases} />
      {/*      <div style={{ visibility: "hidden" }} ref={gridRef} />
       */}
      <TestimonialWrapper>
        <Testimonials
          testimonials={caseStudyContent.testimonials}
          caseStudyVersion={true}
        />
      </TestimonialWrapper>
      <Footer
        catchphrase={footerCF.catchphrase}
        navbarSwitch={navbarSwitch}
        setNavbarSwitch={setNavbarSwitch}
        logo={footerCF.logo}
        basicButtonUrl={footerCF.basicbutton.url}
        basicButtonText={footerCF.basicbutton.text}
        basicButtonUrlType={footerCF.basicbutton.linktype}
        basicButtonSubject={footerCF.basicbutton.topic}
        contactsectionTitle={footerCF.contactsection.title}
        contactsectionAddress1={footerCF.contactsection.address1}
        contactsectionAddress2={footerCF.contactsection.address2}
        contactsectionPhoneUrl={footerCF.contactsection.phone.url}
        contactsectionPhoneText={footerCF.contactsection.phone.text}
        contactsectionEmailUrl={footerCF.contactsection.email.url}
        contactsectionEmailText={footerCF.contactsection.email.text}
        socialmediaText={footerCF.socialmedia.text}
        socialmediaUrl={footerCF.socialmedia.url}
        socialmediaTarget={footerCF.socialmedia.target}
        socialmediaReferral={footerCF.socialmedia.referral}
        menuTitle={footerCF.menu.title}
        menuLinks={footerCF.menu.linksflex}
        disclaimer={footerCF.disclaimer}
        bottomLinkItem1Url={footerCF.bottomlink.item1.url}
        bottomLinkItem1Text={footerCF.bottomlink.item1.text}
        bottomLinkItem2Url={footerCF.bottomlink.item2.url}
        bottomLinkItem2Text={footerCF.bottomlink.item2.text}
        copyright1={footerCF.copyright.item1}
        copyright2={footerCF.copyright.item2}
      />
    </>
  );
};
