import styled from "styled-components";
import { ContinuousText } from "../../../styles/Text.styles";
import { deviceSizes } from "../../../styles/deviceSizes";

export const HeadlineWrapper = styled(ContinuousText)`
  h1 {
    font-family: "Neue Haas Grotesk Display Pro", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 44px;
    margin: 0px;

    & > i {
      font-family: "Tiempos Headline", sans-serif;
      font-style: normal;
    }
    & > b,
    h3,
    strong {
      font-weight: 500;
    }

    @media only screen and (min-width: ${deviceSizes.tablet}) {
      font-size: 72px;
      line-height: 78px;
      text-indent: calc(((100% - 50px) / 12) * -1);
      padding-left: calc((100% - 50px) / 12);
    }
    @media only screen and (min-width: ${deviceSizes.desktop}) {
      font-size: 96px;
      line-height: 110px;
      padding-left: 110px;
      text-indent: -110px;
    }
  }
  p {
    padding-top: 15px;
    @media only screen and (min-width: ${deviceSizes.tablet}) {
      padding-top: 40px;
      padding-left: calc((100% - 50px) / 12);
    }
    @media only screen and (min-width: ${deviceSizes.desktop}) {
      padding-top: 55px;
      padding-left: 110px;
    }
  }
  margin: 15px 0px 40px 0px;
  grid-column: 1/3;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/13;
    margin: 40px 0px 40px 0px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin: 66px 0px 75px 0px;
  }
`;
