import styled from "styled-components";
import { colors } from "../../../styles/colors";
import { deviceSizes } from "../../../styles/deviceSizes";
import { ContinuousText } from "../../../styles/Text.styles";

export const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const PlayButton = styled.div`
  display: flex;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  position: absolute;
  top: calc(50% - 27.5px);
  left: calc(50% - 27.5px);
  border-radius: 120px;
  background-color: ${colors.weis};
  z-index: 1;
  & > svg {
    width: 15px;
    height: 18px;
    margin-left: ${({ loading }) => !loading && "4px"};
    animation: ${({ loading }) => loading && "rotation 2s infinite linear"};
    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }
    color: ${colors.anthrazit};
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    width: 93px;
    height: 93px;
    top: calc(50% - 46.5px);
    left: calc(50% - 46.5px);
    & > svg {
      width: 25px;
      height: 31px;
      margin-left: ${({ loading }) => !loading && "8px"};
    }
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    width: 121px;
    height: 121px;
    top: calc(50% - 60.5px);
    left: calc(50% - 60.5px);
    & > svg {
      width: ${({ loading }) => (loading ? "24px" : "42px")};
      height: ${({ loading }) => (loading ? "24px" : "53px")};
      margin-left: ${({ loading }) => !loading && "12px"};
    }
  }
`;

export const StyledIframe = styled.iframe`
  border: 0;
`;

export const CookiePlaceholderWrapper = styled.div`
  background-color: ${colors.background};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
`;

export const CookiePlaceholderText = styled(ContinuousText)`
  text-align: center;
  width: 90%;
  margin-bottom: 15px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    width: 50%;
  }
`;

export const AllowCookiesButton = styled(ContinuousText)`
  cursor: pointer;
  width: max-content;
  border-bottom: 1px solid ${colors.anthrazit};
`;
