import { ContinuousText } from "../../../../../../styles/Text.styles";
import { MainWrapper, StyledText } from "./TextBox.styles";
import { removeParagraphs } from "../../../../../../helpers";

export const TextBox = ({ headline, text, light = false }) => {
  return (
    <MainWrapper light={light}>
      <ContinuousText>
        <b>{headline}</b>
      </ContinuousText>
      <StyledText
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </MainWrapper>
  );
};
