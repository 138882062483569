import styled from "styled-components";
import { deviceSizes } from "../../../../styles/deviceSizes";
import { colors } from "../../../../styles/colors";
import { Swiper } from "swiper/react";
import { MainGridWrapper } from "../../../../styles/Main.styles";
import { ContinuousText, H3 } from "../../../../styles/Text.styles";

export const DesktopWrapper = styled(MainGridWrapper)`
  display: none;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    display: grid;
    align-items: stretch;
    grid-row-gap: 60px;
    & :nth-child(2) {
      grid-column: ${({ mirrored }) => (mirrored ? "1 / span 7" : "6 / span 7")};
      grid-row: 1;
      align-self: end;
    }
    & :nth-child(3) {
      grid-column: 1 / span 9;
    }
    & :nth-child(4) {
      grid-column: 10 / span 3;
      justify-self: end;
      width: 85%;
    }
    & > img {
      width: 100%;
    }
    margin: 120px 0px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin: 155px 0px;
    grid-row-gap: 85px;
    & :nth-child(2) {
      grid-column: ${({ mirrored }) => (mirrored ? "1 / span 6" : "6 / span 6")};
    }
    & :nth-child(3) {
      grid-column: 2 / span 8;
    }
    & :nth-child(4) {
      grid-column: 11 / span 2;
      width: 100%;
    }
  }
`;

export const DesktopTextWrapper = styled.div`
  align-self: end;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: ${({ mirrored }) => (mirrored ? "8 / span 5" : "1 / span 5")};
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: 1 / span 4;
    grid-column: ${({ mirrored }) => (mirrored ? "8 / span 4" : "1 / span 4")};
  }
`;

export const MobileTextWrapper = styled(MainGridWrapper)`
  margin-top: 75px;
  margin-bottom: 35px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    display: none;
  }
`;
export const StyledH3 = styled(H3)`
  grid-column: 1 / span 3;
  margin-bottom: 15px;
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-bottom: 20px;
  }
`;

export const StyledText = styled(ContinuousText)`
  grid-column: 1 / span 3;
`;

export const MobileWrapper = styled.div`
  width: 100%;
  margin-bottom: 75px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    margin-bottom: 120px;
    display: none;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-bottom: 155px;
  }
`;

export const FlexGrid = styled.div`
  grid-column-start: span 2;
  display: flex;
  justify-content: space-evenly;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1 / span 12;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;

export const StyledImage = styled.img`
  width: ${({ widescreen }) => (widescreen ? "100%" : "69.7%")};
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    width: ${({ widescreen }) => (widescreen ? "100%" : "0")};
    flex: ${({ widescreen }) => (widescreen ? "none" : "1 1 0px")};
  }
`;

export const VideoWrapper = styled.div`
  width: ${({ widescreen }) => (widescreen ? "100%" : "69.7%")};
  ${({ isEmbedded }) => isEmbedded && "min-height: 315px;"}
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    width: ${({ widescreen }) => (widescreen ? "100%" : "0")};
    flex: ${({ widescreen }) => (widescreen ? "none" : "1 1 0px")};
    ${({ isEmbedded }) => isEmbedded && "min-height: 510px;"}
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    ${({ isEmbedded }) => isEmbedded && "min-height: 520px;"}
  }
`;

export const EmbedVideoWrapper = styled.div`
  display: flex;
  width: 100%;
  aspect-ratio: 3 / 1.7;
`;

export const StyledSwiper = styled(Swiper)`
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  padding: 0px 20px 0px 20px;
  &: active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
  & > .swiper-wrapper {
    align-items: end;
    & > .swiper-slide {
      min-height: ${({ loading }) =>
        loading ? "calc((100vw - 40px) * 0.37 * 4/3)" : "0"};
      background: ${({ loading }) =>
        loading
          ? "linear-gradient(90deg, #F2F2F2 1.35%, #f8f8f8 37.26%, #f8f8f8 66.49%, #F2F2F2 99.83%)"
          : "none"};
      background-size: 200% 100%;
      animation: gradient 1.5s ease-in-out infinite;
      width: auto;
      @keyframes gradient {
        0% {
          background-position: 100% 0;
        }
        100% {
          background-position: -100% 0;
        }
      }
      & > img {
        max-width: 100%;
      }
    }
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    margin: ${({ swiperMode }) => (swiperMode ? "0" : "0 20px")};
    padding: ${({ swiperMode }) => (swiperMode ? "0 20px" : "0")};
    & > .swiper-wrapper {
      justify-content: ${({ swiperMode }) => (swiperMode ? "start" : "center")};
      align-items: center;
      & > .swiper-slide {
        min-height: ${({ loading }) =>
          loading ? "calc((((100vw - 270px) / 12) * 3 + 40px) * 4/3)" : "0"};
      }
    }
  }
  @media only screen and (min-width: ${deviceSizes.max_desktop}) {
    padding: ${({ swiperMode }) =>
      swiperMode ? "0px calc((100% - (12 * 90px) - (11 * 20px)) / 2)" : "0"};
    margin: ${({ swiperMode }) =>
      swiperMode ? "0" : "0px calc((100% - (12 * 90px) - (11 * 20px)) / 2)"};
    & > .swiper-wrapper {
      & > .swiper-slide {
        min-height: ${({ loading }) =>
          loading ? "calc(((3 * 90px) + (2 * 20px)) * 4/3)" : "0"};
      }
    }
  }
`;
