import styled from "styled-components";
import { deviceSizes } from "../../../styles/deviceSizes";
import { colors } from "../../../styles/colors";
import { Input } from "../../common/Input/Input.component";
import { ContinuousText, H3 } from "../../../styles/Text.styles";
import { FormHelperText } from "@mui/material";
import { FormControl } from "@mui/material";

export const MainWrapper = styled.div`
  grid-column: 1/3;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/11;
    grid-template-columns: repeat(10, 1fr);
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
  @media only screen and (min-width: ${deviceSizes.max_desktop}) {
    grid-template-columns: repeat(10, 90px);
  }
`;

export const SubHeadline = styled(ContinuousText)`
  grid-column: 1/3;
  margin-bottom: 10px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/11;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;

export const Headline = styled(H3)`
  grid-column: 1/3;
  margin-bottom: 15px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/11;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;

export const Description = styled(ContinuousText)`
  grid-column: 1/3;
  margin-bottom: 30px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/11;
    margin-bottom: 25px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-bottom: 35px;
  }
`;

export const FormWrapper = styled.form`
  display: grid;
  grid-column: 1/3;
  grid-column-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/11;
    grid-template-columns: repeat(10, 1fr);
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
  @media only screen and (min-width: ${deviceSizes.max_desktop}) {
    grid-template-columns: repeat(10, 90px);
  }
`;
export const SmallText = styled.div`
  grid-column-start: span 2;
  font-family: "Neue Haas Grotesk Display Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 20px;
  & > a {
    text-decoration: underline;
    margin: 0px 2px;
    cursor: pointer;
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column-start: span 10;
    margin-bottom: 30px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-bottom: 20px;
  }
`;

export const StyledInput = styled(Input)`
  grid-column-start: span 2;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column-start: span 5;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column-start: span 3;
  }
`;
export const ButtonWrapper = styled.div`
  grid-column-start: span 2;
  max-width: max-content;
  margin-bottom: 120px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    max-width: none;
    grid-column-start: span 4;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-bottom: 155px;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  grid-column-start: span 2;
  align-items: start;
  gap: 10px;
  margin-bottom: 40px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column-start: span 10;
    gap: 15px;
    margin-bottom: 30px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-bottom: 40px;
  }
`;

export const CheckboxLabel = styled.div`
  font-family: "Neue Haas Grotesk Display Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  & > a {
    text-decoration: underline;
    margin: 0px 2px;
    cursor: pointer;
  }
  color: ${colors.anthrazit};
`;

export const FirstRowWrapper = styled.div`
  grid-column-start: span 2;
  display: flex;
  gap: 20px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column-start: span 6;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column-start: span 4;
  }
  @media only screen and (min-width: ${deviceSizes.max_desktop}) {
  }
`;

export const PronounWrapper = styled(FormControl)`
  grid-column-start: span 1;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column-start: span 2;
  }
  @media only screen and (min-width: ${deviceSizes.max_desktop}) {
    grid-template-columns: repeat(10, 90px);
  }
`;

export const NameInput = styled(StyledInput)`
  grid-column-start: span 1;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column-start: span 4;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;

export const SurnameInput = styled(StyledInput)`
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column-start: span 4;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column-start: span 3;
  }
`;

export const ReasonWrapper = styled(FormControl)`
  grid-column-start: span 2;
  min-height: 66px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column-start: span 5;
    margin-bottom: 0px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: 8 / span 3;
  }
`;

export const MessageInput = styled(StyledInput)`
  margin-bottom: 30px;
  min-height: 110px !important;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column-start: span 10;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    margin-bottom: 45px;
  }
`;

export const StyledFormHelper = styled(FormHelperText)`
  font-family: "Neue Haas Grotesk Display Pro", sans-serif;
`;
