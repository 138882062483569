import { Box, Modal } from "@mui/material";
import { colors } from "../../../styles/colors";
import { ContinuousText } from "../../../styles/Text.styles";
import { deviceSizes } from "../../../styles/deviceSizes";
import { BasicButton } from "../BasicButton/BasicButton.component";
import {
  TextWrapper,
  ContentWrapper,
  ButtonWrapper,
  HeadlineWrapper,
  StyledBox,
  CloseIcon,
} from "./StyledModal.styles";

export const StyledModal = ({
  open,
  setOpen,
  headline,
  text,
  button,
  icon,
}) => {
  return (
    <Modal open={open} disableAutoFocus={true}>
      <StyledBox>
        <CloseIcon onClick={() => setOpen(false)} />
        <ContentWrapper>
          {icon && <img src={process.env.REACT_APP_IMG_MEDIA_SRC + icon} />}
          {headline && (
            <HeadlineWrapper
              dangerouslySetInnerHTML={{
                __html: headline,
              }}
            />
          )}
          {text && <TextWrapper>{text}</TextWrapper>}
          <ButtonWrapper>
            {button.map((item, index) => (
              <BasicButton
                key={index}
                label={item.text}
                onClick={!item.url && (() => setOpen(false))}
                href={item.url}
              />
            ))}
          </ButtonWrapper>
        </ContentWrapper>
      </StyledBox>
    </Modal>
  );
};
