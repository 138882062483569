import styled from "styled-components";
import { colors } from "../../../../../../styles/colors";
import { deviceSizes } from "../../../../../../styles/deviceSizes";

export const IconWrapper = styled.div`
  grid-column-start: span 1;
  grid-row-start: ${({ bigHeight }) => (bigHeight ? "span 2" : "span 1")};
  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: ${({ color }) => (color ? color : "transparent")};
  & > img {
    width: 100%;
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column-start: span 1;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column-start: span 1;
  }
`;
