import styled from "styled-components";
import { ContinuousText, H3 } from "../../../../../styles/Text.styles";
import CloseIcon from "../../../../../public/closeIcon.svg";
import PlusIcon from "../../../../../public/plusIcon.svg";
import { colors } from "../../../../../styles/colors";
import { deviceSizes } from "../../../../../styles/deviceSizes";
import { Swiper } from "swiper/react";

export const Wrapper = styled.div`
  grid-column: 1/3;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  cursor: pointer;
  ul {
    padding-inline-start: 40px;
    margin-top: 15px;
  }
  li:not(:last-child) {
    margin-bottom: 1em;
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 6/13;
    grid-template-columns: repeat(12, 1fr);
  }
`;

export const Number = styled(H3)`
  grid-column: 1/2;
  white-space: nowrap;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/5;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: 1/3;
  }
`;
export const TitleWrapper = styled.div`
  grid-column: 1/2;
  display: flex;
  gap: 5px;
  grid-column-gap: 20px;
  white-space: nowrap;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/12;
    display: grid;
    grid-template-columns: repeat(11, 1fr);
  }
`;

export const TabWrapper = styled.div`
  grid-column: 1/3;
  display: grid;
  gap: 5px;
  grid-column-gap: 20px;
  align-content: center;
  white-space: nowrap;
  margin-bottom: 15px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/13;
    grid-template-columns: repeat(12, 1fr);
  }
`;

export const ContentWrapper = styled.div`
  grid-column: 1/3;
  display: grid;
  grid-column-gap: 20px;
  margin-bottom: ${({ open, unlocked }) => (open && unlocked ? "30px" : "0")};
  grid-template-columns: repeat(2, 1fr);
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/13;
    margin-bottom: ${({ open, unlocked }) => (open && unlocked ? "40px" : "0")};
    grid-template-columns: repeat(8, 1fr);
  }
  @media only screen and (min-width: ${deviceSizes.max_desktop}) {
    grid-template-columns: repeat(8, 90px);
    margin-bottom: ${({ open, unlocked }) => (open && unlocked ? "60px" : "0")};
  }
`;

export const Title = styled(H3)`
  grid-column: 1/2;
  white-space: nowrap;
`;

export const Description = styled(ContinuousText)`
  display: ${({ open, unlocked }) => (open && unlocked ? "inherit" : "none")};
  grid-column: 1/3;
  max-width: calc(100vw - 50px);
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/9;
    max-width: calc((((100vw - 270px) / 12) * 8) + 140px);
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: 1/9;
    display: ${({ open, unlocked }) => (open && unlocked ? "block" : "none")};
  }
  @media only screen and (min-width: ${deviceSizes.max_desktop}) {
    width: 860px;
  }
`;

export const ImageContainer = styled.div`
  display: ${({ open, unlocked }) => (open && unlocked ? "grid" : "none")};
  grid-column: 1/3;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  margin-bottom: 15px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-template-columns: repeat(8, 1fr);
    grid-column: 1/9;
    margin-bottom: 20px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: 1/9;
    margin-bottom: 30px;
  }
  @media only screen and (min-width: ${deviceSizes.max_desktop}) {
    grid-template-columns: repeat(8, 90px);
  }
`;

export const StrategyImage = styled.img`
  grid-column-start: span 1;
  width: 100%;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column-start: ${({ widescreen }) => (widescreen ? "span 5" : "span 3")};
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;

export const VideoWrapper = styled.div`
  grid-column-start: span 1;
  width: 100%;
  height: 100%;
  display: flex;
  ${({ isEmbedded }) => isEmbedded && "min-height: 135px;"}
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column-start: ${({ widescreen }) => (widescreen ? "span 5" : "span 3")};
    ${({ isEmbedded }) => isEmbedded && "min-height: 210px;"}
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    ${({ isEmbedded }) => isEmbedded && "min-height: 355px;"}
  }
`;

export const Areas = styled(ContinuousText)`
  font-weight: 500;
  grid-column: 1/3;
  display: ${({ open, unlocked }) => (open && unlocked ? "inherit" : "none")};
  padding-bottom: 10px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/9;
    padding-bottom: 15px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;

export const Line = styled.div`
  grid-column: 1/3;
  border-bottom: 1px solid ${colors.anthrazit};
  margin-bottom: 15px;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/13;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
  }
`;

export const CloseButton = styled(CloseIcon)`
  margin-top: 24px;
  justify-self: end;
  align-self: center;
  color: ${colors.anthrazit};
  width: 15px;
  margin-top: 0px;
  grid-column: 2/3;
  cursor: pointer;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 12/13;
    height: 80%;
    width: 50%;
    justify-self: end;
  }
`;

export const OpenButton = styled(PlusIcon)`
  margin-top: 24px;
  justify-self: end;
  align-self: center;
  color: ${colors.anthrazit};
  width: 20px;
  margin-top: 0px;
  grid-column: 2/3;
  cursor: pointer;
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 12/13;
    height: 80%;
    width: 50%;
    justify-self: end;
  }
`;

export const NumbersWrapper = styled.div`
  // margin-bottom: 75px;
  display: ${({ open, unlocked }) => (open && unlocked ? "flex" : "none")};
  max-width: calc(100vw - 50px);
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    // margin-bottom: 120px;
    max-width: calc((((100vw - 270px) / 12) * 8) + 140px);
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    // margin-bottom: 155px;
  }
  @media only screen and (min-width: ${deviceSizes.max_desktop}) {
    // margin-bottom: 155px;
    width: 860px;
  }
`;

export const KeywordsWrapper = styled(ContinuousText)`
  display: ${({ open, unlocked }) => (open && unlocked ? "inherit" : "none")};
  width: 100%;
  font-weight: 500;
  margin-top: 1em;
  grid-column: 1/3;
  max-width: calc(100vw - 50px);

  @media only screen and (min-width: ${deviceSizes.tablet}) {
    grid-column: 1/9;
    max-width: calc((((100vw - 270px) / 12) * 8) + 140px);
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    grid-column: 1/9;
  }
  @media only screen and (min-width: ${deviceSizes.max_desktop}) {
    width: 860px;
  }
`;

export const StyledSwiper = styled(Swiper)`
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  padding: 40px 0px 0px 0px;
  &: active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    padding-left: 0;
    padding-right: 0;
    & > .swiper-wrapper {
      max-width: calc(((100% - (7 * 20px)) / 8) * 3 + 40px);
      & > .swiper-slide {
        min-height: ${({ loading }) =>
          loading ? "calc((((100vw - 270px) / 12) * 3 + 40px) * 4/3)" : "0"};
      }
    }
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    padding-top: 45px;
  }
  @media only screen and (min-width: ${deviceSizes.max_desktop}) {
    & > .swiper-wrapper {
      & > .swiper-slide {
      }
    }
  }
`;

export const NumbersItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const NumberCounter = styled.div`
  font-family: "Tiempos Headline";
  font-style: italic;
  font-weight: 400;
  font-size: 56px;
  line-height: 56px;
  color: ${colors.akzentGrun};
  @media only screen and (min-width: ${deviceSizes.tablet}) {
    font-size: 72px;
    line-height: 78px;
  }
  @media only screen and (min-width: ${deviceSizes.desktop}) {
    font-size: 96px;
    line-height: 110px;
  }
`;

export const StyledText = styled(ContinuousText)`
  grid-column: 1 / span 3;
`;
