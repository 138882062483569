'use client'

// this function is used by next.js to request images with a custom image loader
// It is configured in next.config.js
//
//   images: {
//        loader: 'custom',
//        loaderFile: "./wp-content/image_loader/imgproxyLoader.js",
//    }
//
// for more information look here: 
// https://nextjs.org/docs/app/api-reference/next-config-js/images#example-loader-configuration
// and here: 
// https://networkteam.com/journal/2021/selfhosted-image-loader-for-nextjs-imgproxy
//
// especialy we are using here NGINX as cache before using imgproxy
// more information are here: https://github.com/shinsenter/docker-imgproxy
export default function ngixCacheImgproxyLoader({ src, width, quality }) {
        
    const encodeSourcedURL = encodeURIComponent(src) // e.g.:  http://wordpress/wp-content/uploads/Unsere-Produkte.jpg

    // example for 'local' file from wp-content/uploads folder which is mounted to NGIX cache container: 
    // http://localhost:9080/1.jpg?width=100
    var filename = src.substring(src.lastIndexOf('/')+1);

    var domain = process.env.NEXT_PUBLIC_IMGLOADER_DOMAIN
    
    return `${domain}/${filename}?width=${width}&quality=${quality || 100}`;
}
