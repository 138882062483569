import { Footer } from "../../common/Footer/Footer.component";
import { removeParagraphs } from "../../../helpers";
import { Header } from "./Contact.styles";
import { ContactForm } from "../../Contact/ContactForm/ContactForm.component";
import { MainGridWrapper } from "../../../styles/Main.styles";
import { StyledModal } from "../../common/StyledModal/StyledModal.component";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";

export const Contact = ({
  footerCF,
  navbarSwitch,
  setNavbarSwitch,
  contactCF,
  popup,
}) => {
  const router = useRouter();
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    setModalOpen(router.query.success);
  }, [router]);
  return (
    <>
      <StyledModal
        open={modalOpen}
        setOpen={setModalOpen}
        text={popup.popupContent}
        headline={popup.popupHeadline}
        icon={popup.popupIcon}
        button={popup.popupButton}
      />
      <MainGridWrapper>
        <Header
          dangerouslySetInnerHTML={{
            __html: contactCF.header,
          }}
        />
        <ContactForm />
      </MainGridWrapper>
      <Footer
        catchphrase={removeParagraphs(footerCF.catchphrase)}
        logo={footerCF.logo}
        navbarSwitch={navbarSwitch}
        setNavbarSwitch={setNavbarSwitch}
        basicButtonUrl={footerCF.basicbutton.url}
        basicButtonText={footerCF.basicbutton.text}
        basicButtonUrlType={footerCF.basicbutton.linktype}
        basicButtonSubject={footerCF.basicbutton.topic}
        contactsectionTitle={footerCF.contactsection.title}
        contactsectionAddress1={footerCF.contactsection.address1}
        contactsectionAddress2={footerCF.contactsection.address2}
        contactsectionPhoneUrl={footerCF.contactsection.phone.url}
        contactsectionPhoneText={footerCF.contactsection.phone.text}
        contactsectionEmailUrl={footerCF.contactsection.email.url}
        contactsectionEmailText={footerCF.contactsection.email.text}
        socialmediaText={footerCF.socialmedia.text}
        socialmediaUrl={footerCF.socialmedia.url}
        socialmediaTarget={footerCF.socialmedia.target}
        socialmediaReferral={footerCF.socialmedia.referral}
        menuTitle={footerCF.menu.title}
        menuLinks={footerCF.menu.linksflex}
        disclaimer={removeParagraphs(footerCF.disclaimer)}
        bottomLinkItem1Url={footerCF.bottomlink.item1.url}
        bottomLinkItem1Text={footerCF.bottomlink.item1.text}
        bottomLinkItem2Url={footerCF.bottomlink.item2.url}
        bottomLinkItem2Text={footerCF.bottomlink.item2.text}
        copyright1={footerCF.copyright.item1}
        copyright2={footerCF.copyright.item2}
      />
    </>
  );
};
