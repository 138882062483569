import { getYouTubeID } from "../../../../helpers";
import { ContinuousText, H3 } from "../../../../styles/Text.styles";
import { Video } from "../../../common/Video/Video.component";
import {
  MainWrapper,
  HeadlineWrapper,
  TestimonialWrapper,
  ImageWrapper,
  StyledImage,
  VideoWrapper,
} from "./CaseOverview.styles";

export const CaseOverview = ({ testimonial, author, media }) => {
  return (
    <MainWrapper>
      {media.map((item, index) => {
        switch (item.__typename) {
          case "Case_study_Casestudiescf_Modules_CaseOverview_MediaFlex_Image":
            return (
              <StyledImage
                key={index}
                src={process.env.REACT_APP_IMG_MEDIA_SRC + item.url}
                alt={item.alt}
              />
            );
          case "Case_study_Casestudiescf_Modules_CaseOverview_MediaFlex_Video":
            return (
              <VideoWrapper key={index}>
                <Video
                  src={item.url}
                  loop={item.options.loop}
                  autoPlay={item.options.autoplay}
                  externalSource={item.options.externalSource}
                />
              </VideoWrapper>
            );
          case "Case_study_Casestudiescf_Modules_CaseOverview_MediaFlex_YoutubeVideo":
            return (
              <>
                {getYouTubeID(item.url) && (
                  <VideoWrapper isEmbedded key={index}>
                    <Video isEmbedded src={item.url} />
                  </VideoWrapper>
                )}
              </>
            );
          default:
            return <></>;
        }
      })}
      <TestimonialWrapper>
        <H3>
          <i>{testimonial}</i>
        </H3>
        <ContinuousText>{author}</ContinuousText>
      </TestimonialWrapper>
    </MainWrapper>
  );
};
