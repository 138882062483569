import {
  MobileWrapper,
  DesktopWrapper,
  StyledSwiper,
  FlexGrid,
  StyledImage,
  VideoWrapper,
  EmbedVideoWrapper,
} from "./ImageGallery.styles";
import { SwiperSlide } from "swiper/react";
import { FreeMode, Pagination } from "swiper";
import { useState } from "react";
import { MainGridWrapper } from "../../../../styles/Main.styles";
import "swiper/css";
import "swiper/css/pagination";
import { Video } from "../../../common/Video/Video.component";

export const ImageGallery = ({ media }) => {
  const [loading, setLoading] = useState(true);
  const [swiper, setSwiper] = useState(null);
  return (
    media.length && (
      <>
        <DesktopWrapper>
          {media.length > 1 ? (
            <StyledSwiper
              slidesPerView={media.length > 3 ? 4 : media.length}
              allowTouchMove={true}
              modules={[FreeMode]}
              freeMode={true}
              onSwiper={setSwiper}
              spaceBetween={40}
              swiperMode={media.length > 4}
              observer={"true"}
              observeslidechildren={"true"}
              observeParents={"true"}
              loading={(loading ? 1 : 0).toString()}
            >
              {media.length > 0 &&
                media.map((item, index) => {
                  switch (item.__typename) {
                    case "Case_study_Casestudiescf_Modules_ImageGallery_ImagesFlex_Image":
                      return (
                        <SwiperSlide key={index}>
                          <img
                            src={process.env.REACT_APP_IMG_MEDIA_SRC + item.url}
                            alt={item.alt}
                            onLoad={() => setLoading(false)}
                            onError={() => setLoading(false)}
                            loading="lazy"
                          />
                        </SwiperSlide>
                      );
                    case "Case_study_Casestudiescf_Modules_ImageGallery_ImagesFlex_Video":
                      return (
                        <SwiperSlide key={index}>
                          <Video
                            src={item.url}
                            loop={item.options.loop}
                            autoPlay={item.options.autoplay}
                            externalSource={item.options.externalSource}
                          />
                        </SwiperSlide>
                      );
                    case "Case_study_Casestudiescf_Modules_ImageGallery_ImagesFlex_YoutubeVideo":
                      return (
                        <SwiperSlide key={index}>
                          <EmbedVideoWrapper>
                            <Video isEmbedded src={item.url} />
                          </EmbedVideoWrapper>
                        </SwiperSlide>
                      );
                    default:
                      return <></>;
                  }
                })}
            </StyledSwiper>
          ) : (
            <MainGridWrapper>
              <FlexGrid>
                {media.length > 0 &&
                  media.map((item, index) => {
                    switch (item.__typename) {
                      case "Case_study_Casestudiescf_Modules_ImageGallery_ImagesFlex_Image":
                        return (
                          <StyledImage
                            key={index}
                            widescreen={item.widescreen}
                            src={process.env.REACT_APP_IMG_MEDIA_SRC + item.url}
                            alt={item.alt}
                            onLoad={() => setLoading(false)}
                            onError={() => setLoading(false)}
                            loading="lazy"
                          />
                        );
                      case "Case_study_Casestudiescf_Modules_ImageGallery_ImagesFlex_Video":
                        return (
                          <VideoWrapper key={index} widescreen={item.widescreen}>
                            <Video
                              src={item.url}
                              loop={item.options.loop}
                              autoPlay={item.options.autoplay}
                              externalSource={item.options.externalSource}
                            />
                          </VideoWrapper>
                        );
                      case "Case_study_Casestudiescf_Modules_ImageGallery_ImagesFlex_YoutubeVideo":
                        return (
                          <VideoWrapper
                            isEmbedded
                            key={index}
                            widescreen={item.widescreen}
                          >
                            <Video isEmbedded src={item.url} />
                          </VideoWrapper>
                        );
                      default:
                        return <></>;
                    }
                  })}
              </FlexGrid>
            </MainGridWrapper>
          )}
        </DesktopWrapper>
        <MobileWrapper>
          {media.length > 1 ? (
            <StyledSwiper
              slidesPerView={1}
              allowTouchMove={true}
              modules={[FreeMode]}
              freeMode={true}
              spaceBetween={40}
              onSwiper={setSwiper}
              observer={"true"}
              observeslidechildren={"true"}
              observeParents={"true"}
              loading={(loading ? 1 : 0).toString()}
            >
              {media.length > 0 &&
                media.map((item, index) => {
                  switch (item.__typename) {
                    case "Case_study_Casestudiescf_Modules_ImageGallery_ImagesFlex_Image":
                      return (
                        <SwiperSlide key={index}>
                          <img
                            src={process.env.REACT_APP_IMG_MEDIA_SRC + item.url}
                            alt={item.alt}
                            onLoad={() => setLoading(false)}
                            onError={() => setLoading(false)}
                            loading="lazy"
                          />
                        </SwiperSlide>
                      );
                    case "Case_study_Casestudiescf_Modules_ImageGallery_ImagesFlex_Video":
                      return (
                        <SwiperSlide key={index}>
                          <Video
                            src={item.url}
                            loop={item.options.loop}
                            autoPlay={item.options.autoplay}
                            externalSource={item.options.externalSource}
                          />
                        </SwiperSlide>
                      );
                    case "Case_study_Casestudiescf_Modules_ImageGallery_ImagesFlex_YoutubeVideo":
                      return (
                        <SwiperSlide key={index}>
                          <EmbedVideoWrapper>
                            <Video isEmbedded src={item.url} />
                          </EmbedVideoWrapper>
                        </SwiperSlide>
                      );
                    default:
                      return <></>;
                  }
                })}
            </StyledSwiper>
          ) : (
            <MainGridWrapper>
              <FlexGrid>
                {media.length > 0 &&
                  media.map((item, index) => {
                    switch (item.__typename) {
                      case "Case_study_Casestudiescf_Modules_ImageGallery_ImagesFlex_Image":
                        return (
                          <StyledImage
                            key={index}
                            widescreen={item.widescreen}
                            src={process.env.REACT_APP_IMG_MEDIA_SRC + item.url}
                            alt={item.alt}
                            onLoad={() => setLoading(false)}
                            onError={() => setLoading(false)}
                            loading="lazy"
                          />
                        );
                      case "Case_study_Casestudiescf_Modules_ImageGallery_ImagesFlex_Video":
                        return (
                          <VideoWrapper key={index} widescreen={item.widescreen}>
                            <Video
                              src={item.url}
                              loop={item.options.loop}
                              autoPlay={item.options.autoplay}
                              externalSource={item.options.externalSource}
                            />
                          </VideoWrapper>
                        );
                      case "Case_study_Casestudiescf_Modules_ImageGallery_ImagesFlex_YoutubeVideo":
                        return (
                          <VideoWrapper key={index} widescreen={item.widescreen}>
                            <Video isEmbedded src={item.url} />
                          </VideoWrapper>
                        );
                      default:
                        return <></>;
                    }
                  })}
              </FlexGrid>
            </MainGridWrapper>
          )}
        </MobileWrapper>
      </>
    )
  );
};
